import PropTypes from "prop-types";
import clsx from "clsx";
import BootstrapTab from "react-bootstrap/Tab"; // Alias this import
import Nav from "react-bootstrap/Nav";
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import carInspections from "../../../src/assets/carInspectionbg.png";
import InspectionModal from "./InspectionModal";
import { isMobile } from "react-device-detect";
const ProductDescriptionTab = ({ spaceBottomClass, product }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [openModals, setOpenModals] = useState({});

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  useEffect(() => {
    if (product.car_inspections?.length) {
      const initialModalsState = {};
      product.car_inspections.forEach((inspection) => {
        inspection.sub_car_inspections.forEach((subInspection, subIndex) => {
          // Initialize modals state with unique keys based on inspection coordinates
          initialModalsState[`${inspection.name}-${subIndex}`] = false;
        });
      });
      setOpenModals(initialModalsState);
    }
  }, [product.car_inspections]);
  const handleOpenModal = (inspectionName,subIndex) => {
    setOpenModals((prevState) => ({
      ...prevState,
      [`${inspectionName}-${subIndex}`]: true,
    }));
  };

  const handleCloseModal = (inspectionName ,subIndex) => {
    setOpenModals((prevState) => ({
      ...prevState,
      [`${inspectionName}-${subIndex}`]: false,
    }));
  };

  return (
    <div className={clsx("description-review-area", spaceBottomClass)}>
      <div className="container">
        <div className="description-review-wrapper">
          <BootstrapTab.Container defaultActiveKey="productDescription">
            <Nav variant="pills" className="description-review-topbar">
              <Nav.Item>
                <Nav.Link eventKey="productDescription">Features</Nav.Link>
              </Nav.Item>
              {product.used === true ? (
                <Nav.Item>
                  <Nav.Link eventKey="productInspiction">
                    Car inspections
                  </Nav.Link>
                </Nav.Item>
              ) : (
                ""
              )}
            </Nav>
            <BootstrapTab.Content className="description-review-bottom">
              <BootstrapTab.Pane eventKey="additionalInfo">
                <div className="product-anotherinfo-wrapper">
                  <ul>
                    <li>
                      <span>Trim</span> {product.trim ? product.trim : ""}
                    </li>
                    <li>
                      <span>Body style</span>{" "}
                      {product.body_style ? product.body_style.name : ""}
                    </li>
                    <li>
                      <span>Kilometers</span>{" "}
                      {product.kilometers
                        ? product.kilometers.toLocaleString()
                        : ""}{" "}
                      KM
                    </li>
                    <li>
                      <span>Transmission</span>{" "}
                      {product.transmission ? product.transmission : ""}
                    </li>

                    <li>
                      <span>License valid to</span>{" "}
                      {product.license_valid_to ? product.license_valid_to : ""}
                    </li>
                    <li>
                      <span>Engine capacity</span>
                      {product.engine_capacity ? product.engine_capacity : ""}
                    </li>
                  </ul>
                </div>
              </BootstrapTab.Pane>
              <BootstrapTab.Pane eventKey="productDescription">
                <Tabs
                  value={selectedTab}
                  onChange={handleChange}
                  aria-label="Car Features Tabs"
                >
                  {product.car_Feature &&
                    product.car_Feature.map((feature, index) => (
                      <Tab
                        key={index}
                        label={
                          <div style={{display:'inline-block'}}>
                            <img
                              src={feature.image}
                              alt={feature.name}
                              style={{ height: "3vh", width: isMobile?"7vw":"2vw" }}
                            />
                            <span style={{marginLeft:'5px'}}>{feature.name}</span>
                          </div>
                        }
                      />
                    ))}
                </Tabs>
                {product.car_Feature &&
                  product.car_Feature.map((feature, index) => (
                    <div key={index} hidden={selectedTab !== index}>
                      <TableContainer component={Paper}>
                        <Table aria-label={`${feature.name} Features`}>
                          <TableHead></TableHead>
                          <TableBody>
                            {feature &&
                              feature.sub_car_features.map(
                                (subFeature, subIndex) => (
                                  <TableRow key={subIndex}>
                                    <TableCell component="th" scope="row">
                                      {subFeature.name}
                                    </TableCell>
                                    <TableCell style={{textAlign:'end',color:'red'}}>{subFeature.value}</TableCell>
                                  </TableRow>
                                )
                              )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  ))}
              </BootstrapTab.Pane>
              <BootstrapTab.Pane eventKey="productInspiction">
                <div className="row">
                  <Tabs
                    value={selectedTab}
                    onChange={handleChange}
                    aria-label="Car Inspections Tabs"
                  >
                    {product.car_inspections &&
                      product.car_inspections.map((inspection, index) => (
                        <Tab
                          // hidden={selectedTab !== index}
                          key={index}
                          label={inspection.name}
                          style={{ flexDirection: "row" }}
                          icon={
                            <img
                              src={inspection.image}
                              alt={inspection.name}
                              style={{ width: 24, height: 24, marginRight: 8 }}
                            />
                          }
                        />
                      ))}
                  </Tabs>
                  {product.car_inspections &&
                    product.car_inspections.map((inspection, index) => (
                      <div key={index} hidden={selectedTab !== index}>
                        <TableContainer component={Paper}>
                          <Table aria-label={`${inspection.name} Details`}>
                            <TableHead>
                              <TableRow></TableRow>
                            </TableHead>
                            <TableBody>
                              {inspection.sub_car_inspections.map(
                                (subInspection, subIndex) => (
                                  <TableRow key={subIndex}>
                                    <TableCell>
                                      {" "}
                                      {subInspection.name}
                                      <br /> <br />
                                      {subInspection.comments.map(
                                        (comment, commentIndex) => (
                                          <span
                                            key={commentIndex}
                                            style={{
                                              backgroundColor:
                                                "rgba(255,109,106,0.2)",
                                              borderRadius: "6px",
                                              padding: "4px",
                                              margin: "5px",
                                              color: "red",
                                            }}
                                          >
                                            {comment.title}
                                          </span>
                                        )
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      {subInspection.status &&
                                      subInspection.status === "fixed" ? (
                                        <i
                                          className="fa-solid fa-screwdriver-wrench"
                                          style={{ color: "green" }}
                                        ></i>
                                      ) : subInspection.status === "good" ? (
                                        <i
                                          className="fa-solid fa-circle-check"
                                          style={{ color: "green" }}
                                        ></i>
                                      ) : subInspection.status === "faulty" ? (
                                        <i
                                          className="fa-solid fa-circle-minus"
                                          style={{ color: "red" }}
                                        ></i>
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <Button
                                        type="submit"
                                        className="cart-shiping-update"
                                        onClick={() =>
                                          handleOpenModal(
                                            inspection.name,
                                            subIndex
                                          )
                                        }
                                      >
                                        <div className="slider-content-3">
                                          <div className={isMobile?"btn-hover": "slider-btn btn-hover"} >
                                            {" "}
                                            <a className="animated" style={{borderRadius:'10px'}}>
                                              Show Image
                                            </a>{" "}
                                          </div>
                                        </div>
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    ))}
                </div>
                {product.car_inspections &&
                  product.car_inspections.map((inspection, index) =>
                    inspection.sub_car_inspections.map(
                      (subInspection, subIndex) => (
                        <InspectionModal
                        key={`${inspection.name}-${subIndex}`}
                          open={openModals[`${inspection.name}-${subIndex}`]}
                          onClose={() => handleCloseModal(inspection.name, subIndex)}
                          image={carInspections}
                          subInspection={{
                            name: inspection.name,
                            x: subInspection.x,
                            y: subInspection.y,
                          }}
                        />
                      )
                    )
                  )}
              </BootstrapTab.Pane>
            </BootstrapTab.Content>
          </BootstrapTab.Container>
        </div>
      </div>
    </div>
  );
};

ProductDescriptionTab.propTypes = {
  productFullDesc: PropTypes.string,
  spaceBottomClass: PropTypes.string,
};

export default ProductDescriptionTab;
