import React, { useState, useEffect } from "react"

import PropTypes from "prop-types"

import clsx from "clsx"
import Tab from "react-bootstrap/Tab"
import Nav from "react-bootstrap/Nav"
import ProductGridThree from "./ProductGridThree"
import axios from "axios"
import ProductGridThreeAr from "./ProductGridThreeAr"
const TabProductThreeAr = ({ spaceBottomClass, category }) => {
  const [isFilterVisible, setFilterVisible] = useState(true)
  const [filteredData, setFilteredData] = useState({})
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://autohouse.onrender.com/car/show_all_cars_user_home",
        {
          headers: {
            lang: "AR",
          },
        }
      )
      setFilteredData(response.data)
      console.log(response.data)
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    fetchData() // Fetch data when the component mounts
  }, [])
  console.log(filteredData)

  const handleNavClick = async (category) => {
    // Filter data based on the selected category
    const response = await axios.get(
      "https://autohouse.onrender.com/car/show_all_cars_user_home",
      {
        headers: {
          lang: "AR",
        },
      }
    )
    const filteredCategory = response.data ? response.data[category] || [] : []

    // Update filteredData directly based on the category
    setFilteredData((prevData) => ({
      ...prevData,
      [category]: filteredCategory,
    }))
  }

  const toggleFilter = () => {
    setFilterVisible(!isFilterVisible)
  }
  return (
    <div className={clsx("product-area hm9-section-padding", spaceBottomClass)}>
      <div className="container-fluid">
        <Tab.Container defaultActiveKey="newArrival">
          <Nav variant="pills" className="product-tab-list pb-55 text-center">
            <Nav.Item>
              <Nav.Link
                eventKey="newArrival"
                onClick={() => handleNavClick("new")}
              >
                <h4>احدث الاصدارات</h4>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="bestSeller"
                onClick={() => handleNavClick("special")}
              >
                <h4>إصدار خاص</h4>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="saleItems"
                onClick={() => handleNavClick("used")}
              >
                <h4>السيارات المستعملة</h4>
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="newArrival">
              <div
                className="custom-row-4"
                style={{ justifyContent: "center" }}
              >
                <ProductGridThreeAr
                  category={filteredData ? filteredData["new"] : []}
                  type="newArrival"
                  limit={10}
                  spaceBottomClass="mb-35"
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="bestSeller">
              <div
                className="custom-row-4"
                style={{ justifyContent: "center" }}
              >
                <ProductGridThreeAr
                  category={filteredData ? filteredData["special"] : []}
                  type="bestSeller"
                  limit={10}
                  spaceBottomClass="mb-35"
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="saleItems">
              <div
                className="custom-row-4"
                style={{ justifyContent: "center" }}
              >
                <ProductGridThreeAr
                  category={filteredData ? filteredData["used"] : []}
                  type="saleItems"
                  limit={10}
                  spaceBottomClass="mb-35"
                />
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  )
}

TabProductThreeAr.propTypes = {
  category: PropTypes.string,
  spaceBottomClass: PropTypes.string,
}

export default TabProductThreeAr
