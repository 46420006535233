import React, { Fragment, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import axios from "axios"
const DealerPostsNoSidebarAr = () => {
  const [filteredData, setFilteredData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [total_pages, setTotalPages] = useState(1)
  const per_page = 6
  const displayPages = 5
  const halfDisplay = Math.floor(displayPages / 2)
  let startPage = Math.max(1, currentPage - halfDisplay)
  let endPage = Math.min(total_pages, startPage + displayPages - 1)
  if (endPage - startPage + 1 < displayPages) {
    startPage = Math.max(1, endPage - displayPages + 1)
  }
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://autohouse.onrender.com/client/show_all_clients_user?page=${currentPage}`,
        {
          headers: {
            lang: "AR",
          },
        }
      )
      setFilteredData(response.data.result)
      setTotalPages(response.data.total_pages)

      console.log(response.data.result)
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    fetchData() // Fetch data when the component mounts
  }, [currentPage])
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
    console.log(newPage)
  }
  return (
    <Fragment>
      {filteredData?.map((singlePost) => (
        <div
          className="col-lg-4 col-md-6 col-sm-12"
          key={singlePost.id}
          dir="rtl"
          lang="ar"
        >
          <div className="blog-wrap-2 mb-30">
            <div className="blog-img-2">
              <Link
                to={
                  process.env.PUBLIC_URL +
                  "/dealer-details-standard/" +
                  singlePost.id
                }
              >
                <img
                  src={process.env.PUBLIC_URL + singlePost.image}
                  alt=""
                  style={{ height: "27vh" }}
                />
              </Link>
            </div>
            <div className="blog-content-2">
              <h4>
                <Link
                  to={
                    process.env.PUBLIC_URL +
                    "/dealer-details-standard/" +
                    singlePost.id
                  }
                >
                  {singlePost.name}
                </Link>
              </h4>
              {/* <p
                style={{
                  lineHeight: "1.5",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  display: "-webkit-box",
                  webkitLineClamp: "1",
                  webkitBoxOsrient: "vertical",
                }}
              >
                {singlePost.about}
              </p> */}
              <div className="blog-share-comment">
                <div className="blog-btn-2">
                  <Link
                    to={
                      process.env.PUBLIC_URL +
                      "/dealer-details-standard/" +
                      singlePost.id
                    }
                  >
                    Branches: {singlePost.branch_number}
                  </Link>
                </div>
                <div className="blog-btn-2">
                  <a href={`tel:${singlePost.number}`}>Call Us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="pro-pagination-style text-center mt-20">
        <ul className="pagination" style={{ justifyContent: "center" }}>
          {currentPage !== 1 && total_pages > 1 && (
            <li className="page-item">
              <a
                className={`page-link ${currentPage === 1 ? "disabled" : ""}`}
                aria-label="Previous"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <i className="fa fa-angle-double-left" />
              </a>
            </li>
          )}
          {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
            <li className="page-item" key={startPage + index}>
              <a
                className={`page-link ${
                  currentPage === startPage + index ? "active" : ""
                }`}
                onClick={() => handlePageChange(startPage + index)}
                disabled={currentPage === startPage + index}
              >
                {startPage + index}
              </a>
            </li>
          ))}
          {currentPage !== total_pages && total_pages > 1 && (
            <li className="page-item">
              <a
                className={`page-link ${
                  currentPage === total_pages ? "disabled" : ""
                }`}
                aria-label="Next"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <i className="fa fa-angle-double-right" />
              </a>
            </li>
          )}
        </ul>
      </div>
    </Fragment>
  )
}

export default DealerPostsNoSidebarAr
