import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col } from "react-bootstrap"
// import { setActiveSort } from "../../helpers/product";
import * as Accordion from "@radix-ui/react-accordion"
import { ChevronDownIcon } from "@radix-ui/react-icons"
import classNames from "classnames"
import axios from "axios"
import { useParams } from "react-router-dom"
import "./style.css"
const AccordionTrigger = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Header className="AccordionHeader">
      <Accordion.Trigger
        className={classNames("AccordionTrigger", className)}
        {...props}
        ref={forwardedRef}
      >
        {children}
        <ChevronDownIcon className="AccordionChevron" aria-hidden />
      </Accordion.Trigger>
    </Accordion.Header>
  )
)

const AccordionContent = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Content
      className={classNames("AccordionContent", className)}
      {...props}
      ref={forwardedRef}
    >
      <div className="AccordionContentText">{children}</div>
    </Accordion.Content>
  )
)
const setActiveSort = (e) => {
  if (e.currentTarget) {
    const filterButtons = document.querySelectorAll(
      ".sidebar-widget-list-left button, .sidebar-widget-tag button, .product-filter button"
    )
    filterButtons.forEach((item) => {
      item.classList.remove("active")
    })
    e.currentTarget.classList.add("active")
  }
}
const ShopColor = ({
  colors,
  getSortParams,
  onFilterChange,
  brand,
  brandID,
}) => {
  const [filteredData, setFilteredData] = useState([])
  const [activeBrand, setActiveBrand] = useState(null)
  const [activeModel, setActiveModel] = useState(null)

  const [selectedModels, setSelectedModels] = useState([])
  const [activeButtons, setActiveButtons] = useState([])

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://autohouse.onrender.com/brand/show_all_brand_models"
      )
      setFilteredData(response.data.result)
      console.log(brandID)
      if (brandID) {
        setActiveSort(brandID)
      }
      console.log(response.data.result)
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    fetchData() // Fetch data when the component mounts
  }, [])

  const toggleModel = (brandId, modelId) => {
    setSelectedModels((prevModels) => {
      const brandIndex = prevModels.findIndex((brand) => brand.id === brandId)

      if (brandIndex === -1) {
        // Brand not found, add it to the selected models
        setActiveModel(modelId) // Update activeModel directly
        return [...prevModels, { id: brandId, models: [modelId] }]
      } else {
        // Brand found, toggle the model
        const brandModels = prevModels[brandIndex].models
        const modelIndex = brandModels.indexOf(modelId)

        if (modelIndex === -1) {
          // Model not found, add it
          brandModels.push(modelId)
          setActiveModel(modelId) // Update activeModel directly
        } else {
          // Model found, remove it
          brandModels.splice(modelIndex, 1)

          // Remove the brand from selected models if no models are selected
          if (brandModels.length === 0) {
            prevModels.splice(brandIndex, 1)
            setActiveModel(null) // No models selected, update activeModel to null
          }
        }

        return [...prevModels]
      }
    })
  }

  // console.log(activeBrand)
  // console.log(activeModel)

  const handleFilterChange = (brandId, modelId) => {
    setActiveBrand(activeBrand === brandId ? null : brandId)
    setActiveModel(activeModel === modelId ? null : modelId)
  }

  useEffect(() => {
    // Send the selected filters to the parent component
    onFilterChange("model", activeModel)
    onFilterChange("brand", activeBrand)
  }, [activeModel, activeBrand])

  const setActiveSort = (productId) => {
    console.log(productId)

    setActiveButtons((prevActiveButtons) => [
      ...prevActiveButtons,
      parseInt(productId),
    ])
  }
  const deActiveSort = (productId) => {
    let ind = -1
    activeButtons.forEach((activeButton, index) => {
      if (activeButton === productId) {
        ind = index
      }
    })

    if (ind !== -1) {
      setActiveButtons((prevActiveButtons) =>
        prevActiveButtons.filter((id) => id !== productId)
      )
    }
  }
  return (
    <div className="sidebar-widget mt-50">
      <Accordion.Root
        className="AccordionRoot"
        type="single"
        defaultValue="item-1"
        collapsible
      >
        <Accordion.Item className="AccordionItem" value="item-1">
          <AccordionTrigger className="pro-sidebar-title">
            Brands
          </AccordionTrigger>
          <AccordionContent className="sidebar-widget-list">
            <ul
              style={{
                overflowY: "scroll",
                maxHeight: "300px",
                scrollbarWidth: "none" /* Firefox */,
                msOverflowStyle: "none" /* Internet Explorer and Edge */,
                WebkitOverflowScrolling: "touch",
              }}
              className="accordion-list"
            >
              {filteredData.map((brand) => (
                <li key={brand.id}>
                  <div className="sidebar-widget-list-left">
                    <button
                      className={classNames({
                        active: activeButtons.includes(brand.id),
                      })}
                    >
                      <span
                        onClick={(e) => {
                          if (activeButtons.includes(brand.id)) {
                            deActiveSort(brand.id)
                          } else {
                            setActiveSort(brand.id)
                          }
                          setActiveModel(null)
                        }}
                        className={`checkmark ${
                          activeBrand === brand.id || brandID === brand.id
                            ? "active"
                            : ""
                        }`}
                      />{" "}
                      {brand.name}
                      <span style={{ marginRight: "35px" }}>
                        {activeBrand === brand.id ? (
                          <i
                            className="fas fa-minus"
                            onClick={() => {
                              setActiveBrand("")
                              handleFilterChange(brand.id)
                            }}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-plus"
                            onClick={() => {
                              setActiveBrand(brand.id)
                              handleFilterChange(brand.id)
                            }}
                          ></i>
                        )}
                      </span>
                    </button>
                  </div>
                  {activeBrand === brand.id && (
                    <Accordion.Root
                      className="AccordionRoot"
                      type="single"
                      defaultValue="model-1"
                      collapsible
                    >
                      <Accordion.Item className="AccordionItem" value="model-1">
                        <Accordion.Trigger className="pro-sidebar-title">
                          Models
                        </Accordion.Trigger>
                        <Accordion.Content className="sidebar-widget-list ">
                          <ul className="accordion-list">
                            {brand.model.map((model) => (
                              <li key={model.id}>
                                <div className="sidebar-widget-list-left">
                                  <button
                                    onClick={(e) => {
                                      if (activeButtons.includes(model.id)) {
                                        deActiveSort(model.id)
                                      } else {
                                        setActiveSort(model.id)
                                      }
                                      // handleFilterChange(model.id)
                                      setActiveModel(model.id)
                                      toggleModel(brand.id, model.id)
                                    }}
                                    className={classNames({
                                      active: activeButtons.includes(model.id),
                                    })}
                                  >
                                    <span
                                      className={`checkmark ${
                                        selectedModels.some(
                                          (brand) =>
                                            brand.id === activeBrand &&
                                            brand.models.includes(model.id)
                                        )
                                          ? "active"
                                          : ""
                                      }`}
                                    />
                                    {model.name}
                                  </button>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </Accordion.Content>
                      </Accordion.Item>
                    </Accordion.Root>
                  )}
                </li>
              ))}
            </ul>
          </AccordionContent>
        </Accordion.Item>
      </Accordion.Root>
    </div>
  )
}

ShopColor.propTypes = {
  colors: PropTypes.array,
  getSortParams: PropTypes.func,
  brand: PropTypes.string,
  brandID: PropTypes.any,
}

export default ShopColor
