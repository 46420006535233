import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { EffectFade, Thumbs } from 'swiper';
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Swiper, { SwiperSlide } from "../../components/swiper";
import { getProductCartQuantity } from "../../helpers/product";
import { addToCart } from "../../store/slices/cart-slice";
import { addToWishlist } from "../../store/slices/wishlist-slice";
import { addToCompare } from "../../store/slices/compare-slice";
import { Link } from "react-router-dom";

function CustomerModal({ product, currency, discountedPrice, finalProductPrice, finalDiscountedPrice, show, onHide, wishlistItem, compareItem }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  console.log(product)
  const dispatch = useDispatch();

 


  

  const onCloseModal = () => {
    setThumbsSwiper(null)
    onHide()
  }
 

  return (
    <Modal show={show} onHide={onCloseModal} className="product-quickview-modal-wrapper">
    <Modal.Header closeButton></Modal.Header>

    <div className="modal-body">
        <div className="row">
         
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="product-details-content quickview-content">
              <h2>{product.name } - {product.phone }</h2>
              <div className="product-details-price" style={{ justifyContent: 'space-between' }}>
                <Fragment>
                  <span>
                    {product.email }
                  </span>{" "}

                </Fragment>
               
              </div>
              <h2>{product.address +" ,"+product.state +","+product.city}</h2>
           
            
       
     
            </div>
          </div>
        </div>
      </div>
  </Modal>
  );
}

CustomerModal.propTypes = {
  currency: PropTypes.shape({}),
  discountedprice: PropTypes.number,
  finaldiscountedprice: PropTypes.number,
  finalproductprice: PropTypes.number,
  onHide: PropTypes.func,
  product: PropTypes.shape({}),
  show: PropTypes.bool,
  wishlistItem: PropTypes.shape({}),
  compareItem: PropTypes.shape({})
};

export default CustomerModal;
