import * as Yup from "yup";

const carSchema = Yup.object().shape({
  make: Yup.object().shape({
    label: Yup.string().required("Brand is required"),
    value: Yup.string().required("Brand is required"),
  }),
  model: Yup.object().shape({
    label: Yup.string().required("Model is required"),
    value: Yup.string().required("Model is required"),
  }),
  year: Yup.object().shape({
    label: Yup.string().required("Year is required"),
    value: Yup.string().required("Year is required"),
  }),
  // trim: Yup.object().shape({
  //   label: Yup.string().required("Trim is required"),
  //   value: Yup.string().required("Trim is required"),
  // }),
  transmission: Yup.object().shape({
    label: Yup.string().required("Transmission is required"),
    value: Yup.string().required("Transmission is required"),
  }),
  bodyStyle: Yup.object().shape({
    label: Yup.string().required("Body Style is required"),
    value: Yup.string().required("Body Style is required"),
  }),
  color: Yup.object().shape({
    label: Yup.string().required("Color is required"),
    value: Yup.string().required("Color is required"),
  }),
  engineCapacity: Yup.object().shape({
    label: Yup.string().required("Engine Capacity is required"),
    value: Yup.string().required("Engine Capacity is required"),
  }),
  end: Yup.object().shape({
    label: Yup.string().required("Duration Of Ad is required"),
    value: Yup.string().required("Duration Of Ad is required"),
  }),
  license_valid_to: Yup.string().required("License Expiry Date is required"),
  kilometers: Yup.number().required("Kilometers is required"),
  // price: Yup.number().required("Price is required"),
  // description: Yup.string().required("Description is required"),
});

export default carSchema;
