import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect"

const BrandLogoOneSingle = ({ data, spaceBottomClass }) => {
  return (
    <div className={clsx("single-brand-logo", spaceBottomClass)} style={{ marginRight: "20px"}}>
      <Link to={process.env.PUBLIC_URL + "/shop-grid-full/" + data.id}>
      <div style={{    border: "1px solid #d0d5dd",
            padding: "16px",
            borderRadius: "16px"}}>
        <img
          src={process.env.PUBLIC_URL + data.image}
          alt=""
          style={{
            height: isMobile?"8vh": "12vh",
            width:isMobile?"20vw": "7vw",
        
          }}
        />
        <p style={{fontSize:'16px',color:'black',fontWeight:'bold'}}>{data.name}</p></div>
      </Link>
    </div>
  );
};

BrandLogoOneSingle.propTypes = {
  data: PropTypes.shape({}),
  spaceBottomClass: PropTypes.string,
};

export default BrandLogoOneSingle;
