import PropTypes from "prop-types"
import clsx from "clsx"
import ProductgridList from "./ProductgridList"

const ShopProducts = ({ products, layout, reload, setReload, setProducts }) => {
  // console.log(products)
  return (
    <div className="shop-bottom-area mt-35">
      <div className={clsx("row", layout)}>
        <ProductgridList
          reload={reload}
          setReload={setReload}
          products={products}
          setProducts={setProducts}
          spaceBottomClass="mb-25"
        />
      </div>
    </div>
  )
}

ShopProducts.propTypes = {
  layout: PropTypes.string,
  products: PropTypes.array,
  setReload: PropTypes.bool,
  reload: PropTypes.bool,
  setProducts: PropTypes.array,
}

export default ShopProducts
