import PropTypes from "prop-types"
import clsx from "clsx"
import React, { useState, useEffect } from "react"
import Swiper, { SwiperSlide } from "../../components/swiper"

import blogFeaturedData from "../../data/blog-featured/blog-featured.json"
import BlogFeaturedSingle from "../../components/blog-featured/BlogFeaturedSingle"
import SectionTitleTwo from "../../components/section-title/SectionTitleTwo"
import axios from "axios"

const BlogFeaturedTwoAr = ({ spaceBottomClass }) => {
  const [filteredData, setFilteredData] = useState([])
  const settings = {
    loop: false,
    spaceBetween: 30,
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  }
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://autohouse.onrender.com/blog/show_all_blogs",
        {
          headers: {
            lang: "AR",
          },
        }
      )
      setFilteredData(response.data.result)
      console.log(response.data.result)
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    fetchData() // Fetch data when the component mounts
  }, [])
  return (
    <div className={clsx("blog-area", spaceBottomClass)} dir="rtl" lang="ar">
      <div className="container">
        <SectionTitleTwo
          titleText="مدوناتنا"
          positionClass="text-center"
          spaceClass="mb-55"
        />
        <div className="row">
          <Swiper options={settings}>
            {filteredData &&
              filteredData.map((singlePost, key) => (
                <SwiperSlide key={key}>
                  <BlogFeaturedSingle singlePost={singlePost} />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

BlogFeaturedTwoAr.propTypes = {
  spaceBottomClass: PropTypes.string,
}

export default BlogFeaturedTwoAr
