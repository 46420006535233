import PropTypes from "prop-types"
import { Fragment, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { getProductCartQuantity } from "../../helpers/product"
import Rating from "./sub-components/ProductRating"
import { addToCart } from "../../store/slices/cart-slice"
import { addToWishlist } from "../../store/slices/wishlist-slice"
import { addToCompare } from "../../store/slices/compare-slice"
import { useLocation } from "react-router-dom"
import axios from "axios"
import Swal from "sweetalert2"
import dayjs from "dayjs"
import withReactContent from "sweetalert2-react-content"
import Select from "react-select"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo"
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { isMobile } from "react-device-detect"
import { Alert } from "react-bootstrap"

const ProductDescriptionInfoSliderAr = ({
  product,
  discountedPrice,
  currency,
  finalDiscountedPrice,
  finalProductPrice,
  cartItems,
  wishlistItem,
  compareItem,
}) => {
  const MySwal = withReactContent(Swal)
  const TOKEN = sessionStorage.getItem("token")

  const dispatch = useDispatch()
  const [selectedProductColor, setSelectedProductColor] = useState(
    product.variation ? product.variation[0].color : ""
  )
  const [selectedProductSize, setSelectedProductSize] = useState(
    product.variation ? product.variation[0].size[0].name : ""
  )
  const [productStock, setProductStock] = useState(
    product.variation ? product.variation[0].size[0].stock : product.stock
  )
  const [quantityCount, setQuantityCount] = useState(1)

  const productCartQty = getProductCartQuantity(
    cartItems,
    product,
    selectedProductColor,
    selectedProductSize
  )
  let { pathname } = useLocation()
  const match = pathname.match(/^\/product-slider\/(\d+)$/)
  const ServiceId = match[1]
  const [service, setService] = useState({})

  var numericCarId = parseInt(ServiceId)
  const [selectedDate, setSelectedDate] = useState(dayjs("2024-04-17"))
  const [selectedTime, setSelectedTime] = useState(dayjs("15:30"))
  const [selectOptions, setSelectOptions] = useState([])
  const [color, setColor] = useState("")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  useEffect(() => {
    // Make an Axios request to fetch subjects from the API
    axios
      .get(
        `https://autohouse.onrender.com/service/show_one_service/${numericCarId}`,
        {
          headers: {
            lang: `AR`,
          },
        }
      )
      .then((response) => {
        console.log("vrvr")

        setService(response.data)
        setSelectOptions(
          response.data.branchs.map((branch) => ({
            value: branch.id,
            label:
              branch.name +
              " " +
              branch.city +
              " " +
              branch.test +
              " " +
              branch.address,
          }))
        )
      })
      .catch((error) => {
        console.error("Error fetching subjects:", error)
      })
  }, [numericCarId])

  const addBook = async () => {
    try {
      console.log(selectedDate)
      const requestData = {
        attend_time: selectedTime,
        attend_date: dayjs(selectedDate).format("YYYY-MM-DD"),
        service_id: numericCarId,
        branch_id: color,
      }

      const headers = {
        token: ` ${TOKEN}`,
        // 'Content-Type': 'application/json', // You can add this line if needed
      }

      const response = await axios.post(
        "https://autohouse.onrender.com/book/add_service_book",
        requestData,
        {
          headers: {
            token: `${TOKEN}`,
          },
        }
      )
      setSuccess("Appointment booked successfully.")

      // Handle success, you can access response.data for the server's response
      console.log("Success:", response.data)
    } catch (error) {
      // MySwal.fire("Oops!", error.response.data.comment, "error");
      setError(error.response.data.comment)

      // Handle error, you can access error.response.data for the server's error response
      console.error("Error:", error.response)
    }
  }
  return (
    <div className="product-details-content pro-details-slider-content">
      <h2>{product.name}</h2>
      <div className="product-details-price justify-content-center">
        {discountedPrice !== null ? (
          <Fragment>
            <span> {finalDiscountedPrice + "EGP"}</span>{" "}
            <span className="old">{"EGP" + finalProductPrice}</span>
          </Fragment>
        ) : (
          <span>{finalProductPrice.toLocaleString() + " " + "EGP"} </span>
        )}
      </div>
      {product.rating && product.rating > 0 ? (
        <div className="pro-details-rating-wrap justify-content-center">
          <div className="pro-details-rating mr-0">
            <Rating ratingValue={product.rating} />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="pro-details-list" dir="rtl" lang="ar">
        <p style={{ textAlign: "justify" }}>
          {product && product.description
            ? product.description.split(/\r?\n/).map((part, index) => (
                <span key={index}>
                  {part}
                  <br />
                </span>
              ))
            : ""}
        </p>
      </div>

      <div
        className="pro-details-quality justify-content-center"
        style={{
          position: "sticky",
          bottom: "0",
          backgroundColor: "#fff",
          padding: "10px",
        }}
      >
        <div className="pro-details " style={{ width: "100%" }}>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {sessionStorage.getItem("loggedIn") === "true" ? (
              <div>
                <div className="row">
                  <div className="col"></div>
                  <div
                    style={{ marginTop: "18px" }}
                    className={isMobile ? "col-12" : "col"}
                  >
                    <label>اختر فرع</label>
                    <Select
                      style={{ zIndex: "9999999" }}
                      options={selectOptions}
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          setColor(selectedOption.value)
                        }
                      }}
                      className="form-control"
                    />
                  </div>
                  <div
                    className={isMobile ? "col-12" : "col"}
                    xs={isMobile ? 12 : 0}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DemoItem label="اختر تاريخ">
                          <DatePicker
                            style={{ width: "50%" }}
                            minDate={dayjs(new Date())}
                            value={selectedDate && selectedDate}
                            onChange={(newDate) => {
                              setSelectedDate(newDate)
                            }}
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  <div
                    className={isMobile ? "col-12" : "col"}
                    xs={isMobile ? 12 : 0}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={[
                          "TimePicker",
                          "MobileTimePicker",
                          "DesktopTimePicker",
                          "StaticTimePicker",
                        ]}
                      >
                        <DemoItem label="اختر ساعة">
                          <MobileTimePicker
                            value={selectedTime}
                            onChange={(newTime) => {
                              console.log(newTime.$H + ":" + newTime.$m)
                              setSelectedTime(newTime.$H + ":" + newTime.$m)
                            }}
                            ampm={false}
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  <div className="col"></div>
                </div>
                {error && <Alert className="alert-danger">{error}</Alert>}
                {success && <Alert className="alert-success">{success}</Alert>}

                <button
                  onClick={addBook}
                  className="btn-hover"
                  style={{
                    backgroundColor: "red",
                    color: "#fff",
                    padding: "1%",

                    marginTop: "2%",
                    borderRadius: "10px",
                  }}
                >
                  احجز معاد
                </button>
              </div>
            ) : (
              <Link
                to={process.env.PUBLIC_URL + "/login-register"}
                className="btn-hover"
                style={{
                  backgroundColor: "red",
                  color: "#fff",
                  padding: "1%",

                  borderRadius: "10px",
                  width: "auto",
                }}
              >
                احجز معاد
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

ProductDescriptionInfoSliderAr.propTypes = {
  cartItems: PropTypes.array,
  compareItem: PropTypes.shape({}),
  currency: PropTypes.shape({}),
  discountedPrice: PropTypes.number,
  finalDiscountedPrice: PropTypes.number,
  finalProductPrice: PropTypes.number,
  product: PropTypes.shape({}),
  wishlistItem: PropTypes.shape({}),
}

export default ProductDescriptionInfoSliderAr
