import { Fragment } from "react"
import { useLocation } from "react-router-dom"
import SEO from "../../components/seo"
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb"
import axios from "axios"
import React, { useState, useEffect } from "react"

const BlogDetailsStandardAr = () => {
  let { pathname } = useLocation()
  const [filteredData, setFilteredData] = useState({})
  const match = pathname.match(/^\/blog-details-standard\/(\d+)$/)

  const blogId = match[1]
  console.log("Extracted ID:", blogId)

  console.log(match)
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://autohouse.onrender.com/blog/show_one_blog/${blogId}`
      )
      setFilteredData(response.data)
      console.log(response.data)
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    fetchData() // Fetch data when the component mounts
  }, [])
  return (
    <Fragment>
      <SEO
        titleTemplate="Auto House"
        description="Blog Post of flone react minimalist eCommerce template."
      />
      {/* <LayoutOne headerTop="visible"> */}
      {/* breadcrumb */}
      <Breadcrumb
        pages={[
          {
            label: filteredData && filteredData.ar_title,
            path: process.env.PUBLIC_URL + pathname,
          },
          { label: "الرئيسية", path: process.env.PUBLIC_URL + "/" },
        ]}
      />
      <div className="blog-area pb-100" dir="rtl" lang="ar">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className="blog-details-wrapper ">
                {/* blog post */}
                <div className="blog-details-top">
                  <div className="blog-details-img">
                    <img
                      alt=""
                      src={process.env.PUBLIC_URL + filteredData.image}
                    />
                  </div>
                  <div className="blog-details-content">
                    <div className="blog-meta-2">
                      <ul>
                        <li>
                          {filteredData.date && filteredData.date.split[0]}
                        </li>
                        {/* <li>
                <Link to={process.env.PUBLIC_URL + "/blog-details-standard"}>
                  4 <i className="fa fa-comments-o" />
                </Link>
              </li> */}
                      </ul>
                    </div>
                    <h3>{filteredData.ar_title}</h3>
                    <p style={{ textAlign: "justify" }}>
                      {filteredData && filteredData.ar_body
                        ? filteredData.ar_body
                            .split(/\r?\n/)
                            .map((part, index) => (
                              <span key={index}>
                                {part}
                                <br />
                              </span>
                            ))
                        : console.error(
                            "filteredData or filteredData.body is undefined."
                          )}
                    </p>

                    {/* <blockquote>
            Lorem ipsum dolor sit amet, consecte adipisicing elit, sed do
            eiusmod tempor incididunt labo dolor magna aliqua. Ut enim ad minim
            veniam quis nostrud.
          </blockquote>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehendrit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </p> */}
                  </div>
                </div>

                {/* blog post comment */}
                {/* <BlogComment /> */}
              </div>
            </div>
            <div className="col-lg-2"></div>

            {/* <div className="col-lg-3">
                <BlogSidebar />
              </div> */}
          </div>
        </div>
      </div>
      {/* </LayoutOne> */}
    </Fragment>
  )
}

export default BlogDetailsStandardAr
