import { Fragment, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { getDiscountPrice } from "../../helpers/product"
import SEO from "../../components/seo"
import LayoutOne from "../../layouts/LayoutOne"
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb"
import { addToCart } from "../../store/slices/cart-slice"
import axios from "axios"
import { Col, Row, Form, Alert } from "react-bootstrap"
import CarAppointment from "./CarAppointment"
import ServiceAppointment from "./ServiceAppointment"
import swal from "sweetalert"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import CarDetails from "./CarDetails"
import CarImages from "./CarImages"
import CarFeature from "./CarFeature"
import { Loading } from "react-loading-dot"
import carSchema from "./carSchema"

import CarInspection from "./CarInspection"
import dayjs from "dayjs"
// const HostedURL = "http://192.168.1.27:5000/"
const HostedURL = "https://autohouse.onrender.com/"
const PersonalInfo = ({
  selectedCar,
  setSelectedCar,
  setCar,
  car,
  carInitialization,
}) => {
  const [value, setValue] = useState("1")

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const [files, setFiles] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  const [deletedImages, setDeletedImages] = useState([])
  const [validationErrors, setValidationErrors] = useState({
    carInitialization,
  })
  const handleEdit = () => {
    let values = car
    console.log(car)
    setLoadingSubmit(true)

    values.make = values.make.value
    values.model = values.model.value
    values.year = values.year.value
    values.color = values.color.value
    values.end = values.end.value
    values.transmission = values.transmission.value
    values.bodyStyle = values.bodyStyle.value
    values.engineCapacity = values.engineCapacity.value
    values.trim = values.trim.value || ""
    values.price = parseInt(values.price)
    values.kilometers = parseInt(values.kilometers)
    values.car_features_data = []
    values.carFeatures.map((feature) => {
      feature.sub_car_features.map((one) => {
        values.car_features_data.push({
          feature_id: feature.id,
          data: one.value,
          ar_data: one.ar_value,
          sub_car_feature_id: one.id,
          type: one.type,
        })
      })
    })
    values.car_inspection_data = []
    values.carInspections.map((inspection) => {
      inspection.sub_car_inspections.map((one) => {
        values.car_inspection_data.push({
          inspection_id: inspection.id,
          sub_car_inspection_id: one.id,
          x: one.x,
          y: one.y,
          comments: one.comments.map((comment) => {
            return comment.id
          }),
          status: one.status,
        })
      })
    })
    console.log(values)

    var fdata = new FormData()
    files.map((file) => {
      fdata.append("images", file)
    })
    // fdata.append("images", files)
    fdata.append("deleted", JSON.stringify(deletedImages))
    fdata.append("current", JSON.stringify(values.images))
    fdata.append("make_id", values.make)
    fdata.append("model_id", values.model)
    fdata.append("year", values.year)
    fdata.append("color", values.color)
    fdata.append("end", values.end)
    fdata.append("transmission_id", values.transmission)
    fdata.append("body_style_id", values.bodyStyle)
    fdata.append("engine_capacity_id", values.engineCapacity)
    fdata.append("license_valid_to", values.license_valid_to)
    fdata.append("trim", values.trim)
    fdata.append("price", values.price)
    fdata.append("kilometers", values.kilometers)
    fdata.append("description", values.description)
    // fdata.append("end", "1")

    fdata.append(`car_features_data`, JSON.stringify(values.car_features_data))

    fdata.append(
      `car_inspection_data`,
      JSON.stringify(values.car_inspection_data)
    )

    // console.log("Here")
    setError(null)
    axios
      .patch(HostedURL + "car/update_car/" + car.id, fdata, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setCar(carInitialization)
        setLoadingSubmit(false)
        setSuccess(
          "Car edited successfully. The admin has to approve it first. Please wait."
        )

        setSelectedCar(carInitialization)
      })
      .catch((e) => {
        console.log(e)
        setLoadingSubmit(false)
        setError(e.response.data.comment)
      })
      .finally(() => {
        setLoadingSubmit(false)
      })
  }
  const handleAdd = async () => {
    let values = car

    try {
      await carSchema.validate(car, { abortEarly: false })

      console.log(car)
      setLoadingSubmit(true)

      values.make = values.make?.value
      values.model = values.model?.value
      values.year = values.year?.value
      values.color = values.color?.value
      values.end = values.end?.value
      values.transmission = values.transmission?.value
      values.bodyStyle = values.bodyStyle?.value
      values.engineCapacity = values.engineCapacity?.value
      values.trim = values.trim?.value || ""
      values.price = parseInt(values.price)
      values.kilometers = parseInt(values.kilometers)
      values.car_features_data = []
      if (values.carFeatures.length > 0)
        values.carFeatures.map((feature) => {
          feature.sub_car_features.map((one) => {
            values.car_features_data.push({
              feature_id: feature.id,
              data: one.value,
              ar_data: one.ar_value,
              sub_car_feature_id: one.id,
              type: one.type,
            })
          })
        })
      values.car_inspection_data = []
      if (values.carInspections.length > 0)
        values.carInspections.map((inspection) => {
          inspection.sub_car_inspections.map((one) => {
            values.car_inspection_data.push({
              inspection_id: inspection.id,
              sub_car_inspection_id: one.id,
              x: one.x,
              y: one.y,
              comments: one.comments.map((comment) => {
                return comment.id
              }),
              status: one.status,
            })
          })
        })
      console.log(values)

      var fdata = new FormData()
      files.map((file) => {
        fdata.append("images", file)
      })
      // fdata.append("images", files)

      fdata.append("make_id", values.make)
      fdata.append("model_id", values.model)
      fdata.append("year", values.year)
      fdata.append("color_id", values.color)
      fdata.append("end", values.end)
      fdata.append("transmission_id", values.transmission)
      fdata.append("body_style_id", values.bodyStyle)
      fdata.append("engine_capacity_id", values.engineCapacity)
      fdata.append(
        "license_valid_to",
        dayjs(values.license_valid_to).format("YYYY-MM-DD")
      )
      fdata.append("trim", values.trim)
      fdata.append("price", values.price)
      fdata.append("kilometers", values.kilometers)
      fdata.append("description", values.description)
      // fdata.append("end", "1")

      fdata.append(
        `car_features_data`,
        JSON.stringify(values.car_features_data)
      )

      fdata.append(
        `car_inspection_data`,
        JSON.stringify(values.car_inspection_data)
      )

      // console.log("Here")
      setError(null)
      axios
        .post(HostedURL + "car/add_car", fdata, {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        })
        .then((res) => {
          setCar(carInitialization)
          setLoadingSubmit(false)
          setSuccess(
            "Car added successfully. The admin has to approve it first. Please wait."
          )
        })
        .catch((e) => {
          console.log(e.response.data.comment)
          setLoadingSubmit(false)
          setError(e.response.data.comment)
        })
        .finally(() => {
          setLoadingSubmit(false)
        })
    } catch (error) {
      const validationErrors = {}
      error.inner.forEach((e) => {
        validationErrors[e.path] = e.message
      })
      console.log(validationErrors)
      setValidationErrors(validationErrors)
    }
  }
  useEffect(() => {
    let errorTimer, successTimer

    // Set up timers to clear the messages after 10 seconds
    if (error) {
      errorTimer = setTimeout(() => setError(""), 10000)
    }

    if (success) {
      successTimer = setTimeout(() => setSuccess(""), 10000)
    }

    return () => {
      clearTimeout(errorTimer)
      clearTimeout(successTimer)
    }
  }, [error, success])

  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              textColor="primary"
              indicatorColor="primary"
            >
              <Tab
                style={{ padding: "12px 16px" }}
                label="Car Details"
                value="1"
              />
              <Tab
                style={{ padding: "12px 16px" }}
                label="Car Features"
                value="2"
              />
              <Tab
                style={{ padding: "12px 16px" }}
                label="Car Inspections"
                value="3"
              />
              <Tab
                style={{ padding: "12px 16px" }}
                label="Car Images"
                value="4"
              />
            </TabList>
          </Box>
          <Form
            onSubmit={(e) => {
              e.preventDefault()

              if (selectedCar) {
                handleEdit()
              } else {
                handleAdd()
              }
            }}
          >
            {error && (
              <Alert
                className="alert-danger"
                onClose={() => setError("")}
                dismissible
              >
                {error}
              </Alert>
            )}
            {success && (
              <Alert
                className="alert-success"
                onClose={() => setSuccess("")}
                dismissible
              >
                {success}
              </Alert>
            )}

            <TabPanel value="1">
              <CarDetails
                car={car}
                setCar={setCar}
                validationErrors={validationErrors}
              />
            </TabPanel>
            <TabPanel value="2">
              <CarFeature car={car} setCar={setCar} />
            </TabPanel>
            <TabPanel value="3">
              <CarInspection
                car={car}
                setCar={setCar}
                setSelectedCar={setSelectedCar}
                selectedCar={selectedCar}
              />
            </TabPanel>
            <TabPanel value="4">
              <CarImages
                files={files}
                setFiles={setFiles}
                car={car}
                setCar={setCar}
                setDeletedImages={setDeletedImages}
                deletedImages={deletedImages}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
              />
              {loadingSubmit == true ? (
                <Loading />
              ) : (
                <div
                  className="sticky-button"
                  style={{
                    backgroundColor: "red",
                    padding: "10px",
                    paddingLeft: "7%",
                    paddingRight: "7%",
                    textAlign: "center",
                    borderRadius: "20px",
                    display: "flex",
                    justifyContent: "center",
                    width: "30%",
                    float: "right",
                  }}
                >
                  <button
                    style={{ color: "#fff", textAlign: "center" }}
                    type="submit"
                  >
                    <> {selectedCar ? "Edit Car" : "Add Car"}</>
                  </button>
                </div>
              )}
            </TabPanel>
          </Form>
        </TabContext>
      </Box>
    </>
  )
}
export default PersonalInfo
