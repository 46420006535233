import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import App from "./App"
import { store } from "./store/store"
import PersistProvider from "./store/providers/persist-provider"
import { setProducts } from "./store/slices/product-slice"
import products from "./data/products.json"
import { createTheme, ThemeProvider } from "@mui/material/styles"

import "animate.css"
import "swiper/swiper-bundle.min.css"
import "yet-another-react-lightbox/styles.css"
import "yet-another-react-lightbox/plugins/thumbnails.css"
import "./assets/scss/style.scss"
import "./i18n"

store.dispatch(setProducts(products))
const theme = createTheme({
  palette: {
    primary: {
      main: "#dc3545",
    },
    secondary: {
      main: "#fff",
    },
    dark: {
      main: "#000",
    },
  },
})
const container = document.getElementById("root")
const root = createRoot(container)
root.render(
  <Provider store={store}>
    <PersistProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </PersistProvider>
  </Provider>
)
