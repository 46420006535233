import PropTypes from "prop-types";

import { Link } from "react-router-dom";

const HeroSliderTenSingle = ({ data }) => {
  let route = process.env.PUBLIC_URL + "/shop-grid-full-width"; 

  
  switch (data.case) {
    case "model":
      route = process.env.PUBLIC_URL + `/shop-grid-full-model/${data.info}`;
      break;
    case "car":
      route = process.env.PUBLIC_URL + `/product/${data.info}`;
      break;
    case "service":
      route = process.env.PUBLIC_URL + `/product-slider/${data.info}`;
      break;
    case "brand":
      route = process.env.PUBLIC_URL + `/shop-grid-full/${data.info}`;
      break;
      case "undefined":
      route = process.env.PUBLIC_URL;
      break;
    default:
      // default route
      route = process.env.PUBLIC_URL + "/shop-grid-full-width";
      break;
  }

  return (
    <Link
    className="animated"
    to={route}
    >
    <div
      className="single-slider-2 slider-height-2 d-flex align-items-center bg-img"
      style={{ backgroundImage: `url(${process.env.PUBLIC_URL + data.image})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-7 col-md-8 col-12 ms-auto">
            <div className="slider-content-3 slider-animated-1 text-center">
              <h3 className="animated" style={{color:'#fff'}}>Auto House</h3>
              <h1 className="animated"  style={{color:'#fff'}}>Drive Your Dreams Home</h1>
              <h4 className="animated"  style={{color:'#fff'}}>Find your perfect ride at Auto House</h4>
              {/* <p className="animated"  style={{color:'#fff'}}>{data.info}</p> */}
              {/* <div className="slider-btn btn-hover"  style={{color:'#fff'}}>
                <Link
                  className="animated"
                  to={process.env.PUBLIC_URL + "/shop-grid-full-width"}
                  >
                  SHOP NOW
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div> </Link>
  );
};

HeroSliderTenSingle.propTypes = {
  data: PropTypes.shape({})
};

export default HeroSliderTenSingle;
