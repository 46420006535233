import PropTypes from "prop-types"
import clsx from "clsx"
import car from "../../assets/car-exchange3.jpg"
import { isMobile } from "react-device-detect"

const SectionTitleWithText = ({ spaceTopClass, spaceBottomClass }) => {
  return (
    <div
      className={clsx("welcome-area", spaceTopClass, spaceBottomClass)}
      dir="rtl"
    >
      <div class="css-e3lyfe erhkdy5">
        {isMobile ? (
          <div class=" e1mcbupo0">
            <div className="css-1ugal98 e1mcbupo1 row">
              <div class=" e1mcbupo2 col-sm-12">
                <div class="css-19qmc55 e1mcbupo3">
                  <div className="welcome-content text-center">
                    <h5>من نحن</h5>
                    <h1>مرحباً بكم في أوتو هاوس</h1>
                    <p style={{ textAlign: "justify" }}>
                      {
                        "مصدرك الموثوق للمركبات الجديدة والمستعملة عالية الجودة! نحن ملتزمون بتمكين عملائنا للعثور على السيارة التي تناسب احتياجاتهم وميزانيتهم بشكل مثالي. سواء كنت تبحث عن سيارة سيدان أنيقة وكفءة، أو عن SUV واسعة وصديقة للعائلة، أو عن شاحنة قوية، فإن مخزوننا الواسع يلبي جميع الرغبات."
                      }
                    </p>
                    <br />
                    <p>
                      {
                        "في أوتو هاوس، نؤمن بالشفافية وتجربة شراء السيارات خالية من الضغوط. فريقنا المتخصص والودود مكرس لمساعدتك في كل خطوة من العملية، بدءًا من تصفح مخزوننا الواسع إلى تأمين خيارات التمويل التي تتناسب مع أهدافك المالية."
                      }
                    </p>
                  </div>
                </div>
              </div>
              <div className=" e1mcbupo6 col-sm-12">
                <div class="e1mcbupo7">
                  <div class=" e1mcbupo8">
                    <div class=" css-akxjdn">
                      <img src={car} style={{ width: "100%" }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div class="css-pqa8ah e1mcbupo0">
            <div className="css-1ugal98 e1mcbupo1">
              <div class="css-6jarbg e1mcbupo2">
                <div class="css-19qmc55 e1mcbupo3">
                  <div className="welcome-content text-center">
                    <h5>من نحن</h5>
                    <h1>مرحبًا بك في أوتو هاوس</h1>
                    <p>
                      {
                        "مصدرك الموثوق للمركبات الجديدة والمستعملة عالية الجودة! نحن ملتزمون بتمكين عملائنا للعثور على السيارة التي تناسب احتياجاتهم وميزانيتهم بشكل مثالي. سواء كنت تبحث عن سيارة سيدان أنيقة وكفءة، أو عن SUV واسعة وصديقة للعائلة، أو عن شاحنة قوية، فإن مخزوننا الواسع يلبي جميع الرغبات."
                      }
                    </p>
                    <br />
                    <p>
                      {
                        "في أوتو هاوس، نؤمن بالشفافية وتجربة شراء السيارات خالية من الضغوط. فريقنا المتخصص والودود مكرس لمساعدتك في كل خطوة من العملية، بدءًا من تصفح مخزوننا الواسع إلى تأمين خيارات التمويل التي تتناسب مع أهدافك المالية."
                      }
                    </p>
                  </div>
                </div>
              </div>
              <div className="css-esgtwk e1mcbupo6">
                <img src={car} style={{ width: "100%", height: " 53vh" }} />
              </div>
            </div>{" "}
          </div>
        )}
      </div>

      {/* <div className="container">
        <div className="welcome-content text-center">
          <h5>Who Are We</h5>
          <h1>Welcome To Auto House</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt labor et dolore magna aliqua. Ut enim ad
            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commo consequat irure{" "}
          </p>
        </div>
      </div> */}
    </div>
  )
}

SectionTitleWithText.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
}

export default SectionTitleWithText
