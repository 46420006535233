import { Fragment, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { getDiscountPrice } from "../../helpers/product"
import SEO from "../../components/seo"
import LayoutOne from "../../layouts/LayoutOne"
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb"
import { addToCart } from "../../store/slices/cart-slice"
import axios from "axios"
import { Col, Row, Form, Alert } from "react-bootstrap"
import CarAppointment from "./CarAppointment"
import ServiceAppointment from "./ServiceAppointment"
import swal from "sweetalert"
import SellCar from "./SellCar"
import defImage from "../../assets/def.png"
import AllCars from "./AllCars"

import { isMobile } from "react-device-detect"
// import BasicInfo from "./BasicInfo";
// import EducationalInfo from "./EducationalInfo";
// import PersonalInfo from "./PersonalInfo";
const PersonalInfo = () => {
  const carInitialization = {
    make: "",
    model: "",
    year: "",
    color: "",
    kilometers: "",
    price: "",
    transmission: "",
    description: "",
    bodyStyle: "",
    engineCapacity: "",
    license_valid_to: "",
    specal: false,
    trim: "",
    carFeatures: [],
    carInspections: [],
    images: [],
  }

  const [car, setCar] = useState(carInitialization)
  const dispatch = useDispatch()
  let { pathname } = useLocation()
  const [oldPassword, setOldPassword] = useState("")
  const [user, setUser] = useState({})
  const [alert, setAlert] = useState("")
  const [name, setName] = useState("")
  const [number, setNumber] = useState("")
  const [address, setAddress] = useState("")
  const [governoratesData, setGovernoratesData] = useState({})
  const [selectedCar, setSelectedCar] = useState("")
  const [areas, setAreas] = useState([])
  const [cities, setCities] = useState([])
  const [newPassword, setNewPassword] = useState("")
  const [active, setActive] = useState(1)
  const [confirmPassword, setConfirmPassword] = useState("")
  const [state, setArea] = useState("")
  const [city, setCity] = useState("")
  const [activeSection, setActiveSection] = useState("basicInfo")
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]
  var d = new Date()
  var dayName = days[d.getDay()]
  const handleClick = (section) => {
    setActiveSection(section)
  }
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  const [filteredData, setFilteredData] = useState({})
  const TOKEN = sessionStorage.getItem("token")

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://autohouse.onrender.com/customer/show_one_user",
        {
          headers: {
            token: `${TOKEN}`,
          },
        }
      )
      setFilteredData(response.data.user_data)
      console.log(response.data)
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }
  const handleChangePassword = (e) => {
    e.preventDefault()
    if (newPassword == confirmPassword) {
      const token = sessionStorage.getItem("token")
      const body = {
        oldPassword: oldPassword,
        newPassword: newPassword,
        // email: user.email,
      }

      axios
        .patch(
          "https://autohouse.onrender.com/customer/change_password_user",
          body,
          {
            headers: {
              token: ` ${token}`, // set Authorization header with token value
            },
          }
        )
        .then((res) => {
          // console.log(res);
          if (res.data.success === true) {
            setActive(3)
            // logOut();
            setSuccess("your password changed!")
            swal("Good job!", "your password changed!", "success")
          } else {
            setAlert("Wrong old password.")
          }
        })
        .catch((err) => {
          setError(err.response.data.comment)
          console.log(err)
        })
    } else {
      setAlert("New password does not match Confrim password.")
    }
  }
  console.log(city)
  // const handleImageChange = (e) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.files[0],
  //   });
  // };
  const handleEdit = (e) => {
    e.preventDefault()
    const fileInput = document.getElementById("imageInput")
    const file = fileInput.files[0]
    // console.log(name, number);
    const formData = new FormData()
    formData.append("name", name)
    formData.append("phone", number)
    formData.append("address", address)
    formData.append("city", city.selectedCity || filteredData.city || "")
    formData.append("state", state.selectedArea || filteredData.state || "")

    if (file) {
      formData.append("image", file)
    }

    axios
      .patch("https://autohouse.onrender.com/customer/update_user", formData, {
        headers: {
          token: `${TOKEN}`,
        },
      })
      .then((res) => {
        // console.log(res);
        setUser({
          ...user,
          name: name,
          number: number,
        })
      })
      .catch((err) => {
        // console.log(err);
      })
  }
  useEffect(() => {
    fetchData() // Fetch data when the component mounts
  }, [])

  const handleImageChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setFilteredData({ ...filteredData, image: reader.result })
      }
      reader.readAsDataURL(file)
    }
  }
  const handleCityChange = (e) => {
    const selectedCity = e.target.value || filteredData.city || "" // Use filteredData.city if available, otherwise default to an empty string
    const selectedAreas = governoratesData[selectedCity]?.items || []
    setCity({
      selectedCity,
    })
    setAreas(selectedAreas)
  }
  const handleAreaChange = (e) => {
    const selectedArea = e.target.value || filteredData.state || ""
    setArea({
      selectedArea,
    })
  }
  useEffect(() => {
    axios
      .get("https://autohouse.onrender.com/address")
      .then((response) => {
        console.log(response)
        setGovernoratesData(response.data.governorates)

        setCities(Object.keys(response.data.governorates))
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])
  return (
    <Fragment>
      <SEO
        titleTemplate="AUTO HOUSE"
        description="AUTO HOUSE page of flone react minimalist eCommerce template."
      />
      {/* <LayoutOne headerTop="visible"> */}
      {/* breadcrumb */}
      <Breadcrumb
        pages={[
          { label: "Home", path: process.env.PUBLIC_URL + "/" },
          { label: "profile", path: process.env.PUBLIC_URL + pathname },
        ]}
      />

      <div className="cart-main-area pt-90 pb-100">
        <div className="container">
          <Row>
            <Col
              style={{
                textAlign: isMobile && "center",
              }}
              xs={12}
              lg={3}
              className="menrow"
            >
              <div
                className={active === 1 ? "menitem active" : "menitem"}
                onClick={() => {
                  setActive(1)
                }}
              >
                <i
                  class={
                    isMobile ? "fa-solid fa-user " : "fa-solid fa-user fa-lg"
                  }
                  aria-hidden="true"
                  style={{ color: "red", marginRight: "2%" }}
                ></i>
                <span
                  className="forFont"
                  style={{
                    fontSize: isMobile ? "10px" : "14px",
                    fontWeight: isMobile ? "600" : "",
                  }}
                >
                  {" "}
                  Edit Profile
                </span>
              </div>
              <div
                className={active === 2 ? "menitem active" : "menitem"}
                onClick={() => {
                  setActive(2)
                }}
              >
                {/* <i class="fa-solid fa-calendar-days"></i> */}
                <i
                  class={isMobile ? "fa fa-calendar " : "fa fa-calendar fa-lg"}
                  aria-hidden="true"
                  style={{ color: "red", marginRight: "2%" }}
                ></i>
                <span
                  className="forFont"
                  style={{
                    fontSize: isMobile ? "10px" : "14px",
                    fontWeight: isMobile ? "600" : "",
                  }}
                >
                  Booked Car Appointments
                </span>
              </div>
              <div
                className={active === 5 ? "menitem active" : "menitem"}
                onClick={() => {
                  setActive(5)
                }}
              >
                <i
                  class={isMobile ? "fa fa-calendar " : "fa fa-calendar fa-lg"}
                  aria-hidden="true"
                  style={{ color: "red", marginRight: "2%" }}
                ></i>
                <span
                  className="forFont"
                  style={{
                    fontSize: isMobile ? "10px" : "14px",
                    fontWeight: isMobile ? "600" : "",
                  }}
                >
                  Booked Services Appointments
                </span>
              </div>
              <div
                className={active === 7 ? "menitem active" : "menitem"}
                onClick={() => {
                  setActive(7)
                }}
              >
                <i
                  class={isMobile ? "fa fa-car " : "fa fa-car fa-lg"}
                  aria-hidden="true"
                  style={{ color: "red", marginRight: "2%" }}
                ></i>
                <span
                  className="forFont"
                  style={{
                    fontSize: isMobile ? "10px" : "14px",
                    fontWeight: isMobile ? "600" : "",
                  }}
                >
                  My Ads
                </span>
              </div>
              <div
                className={active === 6 ? "menitem active" : "menitem"}
                onClick={() => {
                  setActive(6)
                }}
              >
                <i
                  class={isMobile ? "fa fa-car " : "fa fa-car fa-lg"}
                  aria-hidden="true"
                  style={{ color: "red", marginRight: "2%" }}
                ></i>
                <span
                  className="forFont"
                  style={{
                    fontSize: isMobile ? "10px" : "14px",
                    fontWeight: isMobile ? "600" : "",
                  }}
                >
                  {" "}
                  Sell Car{" "}
                </span>
              </div>
              <div
                className={active === 4 ? "menitem active" : "menitem"}
                onClick={() => {
                  setActive(4)
                }}
              >
                <i
                  class={isMobile ? "fa fa-eye " : "fa fa-eye fa-lg"}
                  aria-hidden="true"
                  style={{ color: "red", marginRight: "2%" }}
                ></i>
                <span
                  className="forFont"
                  style={{
                    fontSize: isMobile ? "10px" : "14px",
                    fontWeight: isMobile ? "600" : "",
                  }}
                >
                  {" "}
                  Change Password{" "}
                </span>
              </div>

              <div
                className={active === 3 ? "menitem active" : "menitem"}
                onClick={() => {
                  setActive(3)
                  sessionStorage.clear()
                  sessionStorage.clear()
                  window.location.replace("/")
                  //   logOut();
                }}
              >
                <i
                  class={isMobile ? "fa fa-sign-out " : "fa fa-sign-out fa-lg"}
                  aria-hidden="true"
                  style={{ color: "red", marginRight: "2%" }}
                ></i>
                <span
                  className="forFont"
                  style={{
                    fontSize: isMobile ? "10px" : "14px",
                    fontWeight: isMobile ? "600" : "",
                  }}
                >
                  {" "}
                  Logout{" "}
                </span>
              </div>
            </Col>
            <Col
              style={{
                textAlign: isMobile && "center",
              }}
              xs={12}
              lg={9}
            >
              {active === 6 && (
                <SellCar
                  car={car}
                  setCar={setCar}
                  setSelectedCar={setSelectedCar}
                  selectedCar={selectedCar}
                  carInitialization={carInitialization}
                />
              )}
              {active === 7 && (
                <AllCars
                  setSelectedCar={setSelectedCar}
                  selectedCar={selectedCar}
                  setActive={setActive}
                  setCar={setCar}
                  carInitialization={carInitialization}
                  car={car}
                />
              )}
              {active === 1 && (
                <Fragment>
                  <Form>
                    <Row className="mb-3">
                      <div
                        className="imgdiv"
                        style={{
                          position: "relative",
                          height: isMobile ? "30vh" : "20vh",
                          maxWidth: isMobile ? "100%" : "16vw",
                        }}
                      >
                        <img
                          src={
                            filteredData.image ? filteredData.image : defImage
                          }
                          alt=""
                          style={{
                            maxWidth: isMobile && " 90%",
                            width: isMobile ? "90%" : "10vw",
                          }}
                        />
                        <label
                          htmlFor="imageInput"
                          style={{
                            position: "absolute",
                            bottom: "-10px",
                            right: isMobile ? "25px" : "85px",
                            backgroundColor: "black",
                            borderRadius: "50%",
                            width: "40px",
                            height: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <i
                            className="fa fa-pencil fa-lg"
                            title="Change Image"
                            style={{ fontSize: "20px", color: "#fff" }}
                          ></i>
                        </label>
                        <input
                          id="imageInput"
                          type="file"
                          onChange={handleImageChange}
                          accept="image/*"
                          style={{ display: "none" }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            right: "-10px",
                            width: "20px",
                            height: "20px",
                            backgroundColor: "#fff",
                            transform: "rotate(45deg)",
                            zIndex: -1,
                          }}
                        ></div>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        xs={isMobile ? 12 : 0}
                        controlId="formGridEmail"
                      >
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={filteredData.name}
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value)
                          }}
                        />
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        xs={isMobile ? 12 : 0}
                        controlId="formGridAddress1"
                      >
                        <Form.Label>Number</Form.Label>
                        <Form.Control
                          placeholder={filteredData.phone}
                          value={number}
                          onChange={(e) => {
                            setNumber(e.target.value)
                          }}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        xs={isMobile ? 12 : 0}
                        controlId="formGridAddress1"
                      >
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          placeholder={filteredData.email}
                          disabled
                          value={user.email}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        xs={isMobile ? 12 : 0}
                        controlId="formGridAddress1"
                      >
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          placeholder={filteredData.address}
                          value={address}
                          onChange={(e) => {
                            setAddress(e.target.value)
                          }}
                        />
                      </Form.Group>
                    </Row>

                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        xs={isMobile ? 12 : 0}
                        controlId="formGridAddress1"
                      >
                        <Form.Label>City</Form.Label>
                        <select
                          className="form-control"
                          // value={filteredData.city}
                          onChange={handleCityChange}
                        >
                          <option value="">{filteredData.city}</option>
                          {cities.map((city) => (
                            <option key={city} value={city}>
                              {city}
                            </option>
                          ))}
                        </select>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        xs={isMobile ? 12 : 0}
                        controlId="formGridAddress1"
                      >
                        <Form.Label>State</Form.Label>
                        <select
                          className="form-control"
                          // value={filteredData.state}
                          onChange={handleAreaChange}
                        >
                          <option value="">{filteredData.state}</option>
                          {areas.map((area) => (
                            <option key={area} value={area}>
                              {area}
                            </option>
                          ))}
                        </select>
                      </Form.Group>
                    </Row>
                    <div className="cart-shiping-update-wrapper">
                      <div className="cart-shiping-update">
                        <Link

                        // disabled={
                        //   name.first + " " + name.last === user.name &&
                        //   number === user.number
                        //     ? true
                        //     : false
                        // }
                        >
                          <button
                            onClick={(e) => {
                              handleEdit(e)
                            }}
                            // disabled={
                            //   // name == "" ||
                            //   // number == "" ||

                            //   address == ""
                            // }
                          >
                            Submit Changes
                          </button>
                        </Link>
                      </div>
                    </div>
                  </Form>
                </Fragment>
              )}
              {active === 2 && (
                <Fragment>
                  <CarAppointment />
                </Fragment>
              )}
              {active === 5 && (
                <Fragment>
                  <ServiceAppointment />
                </Fragment>
              )}
              {active === 4 && (
                <Fragment>
                  <>
                    {error && <Alert className="alert-danger">{error}</Alert>}
                    {success && (
                      <Alert className="alert-success">{success}</Alert>
                    )}
                    {alert !== "" && <Alert variant="danger">{alert}</Alert>}
                    <section
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <p className="goodmoring">
                        Happy {dayName},{filteredData.name}
                      </p>
                      <div className="imgdiv">
                        {/* <img
                    src={
                      sessionStorage.getItem("user_image") &&
                      sessionStorage.getItem("user_image") !== "undefined"
                        ? sessionStorage.getItem("user_image")
                        : defImage
                    }
                  /> */}
                      </div>
                    </section>
                    <section>
                      <Form>
                        <Row className="mb-3">
                          <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Old Password</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Enter old password"
                              value={oldPassword}
                              onChange={(e) => {
                                setOldPassword(e.target.value)
                              }}
                            />
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            controlId="formGridEmail"
                          ></Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Enter new password"
                              value={newPassword}
                              onChange={(e) => {
                                setNewPassword(e.target.value)
                              }}
                            />
                          </Form.Group>
                          <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Confirm New Password</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Confirm new password"
                              value={confirmPassword}
                              onChange={(e) => {
                                setConfirmPassword(e.target.value)
                              }}
                            />
                          </Form.Group>
                        </Row>
                        <div className="cart-shiping-update-wrapper">
                          <div className="cart-shiping-update">
                            <Link

                            // disabled={
                            //   name.first + " " + name.last === user.name &&
                            //   number === user.number
                            //     ? true
                            //     : false
                            // }
                            >
                              <button
                                onClick={(e) => {
                                  handleChangePassword(e)
                                }}
                                disabled={
                                  oldPassword == "" ||
                                  newPassword == "" ||
                                  confirmPassword == ""
                                }
                              >
                                Submit Changes
                              </button>
                            </Link>
                          </div>
                        </div>
                      </Form>
                    </section>
                  </>
                </Fragment>
              )}
            </Col>
          </Row>
        </div>
      </div>
      {/* </LayoutOne> */}
    </Fragment>
  )
}

export default PersonalInfo
