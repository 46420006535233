import { Fragment, useState } from "react"
import { useLocation } from "react-router-dom"
import SEO from "../../components/seo"
import LayoutOne from "../../layouts/LayoutOne"
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb"
import GoogleMap from "../../components/google-map"
import axios from "axios"
import swal from "sweetalert"
const ContactAr = () => {
  let { pathname } = useLocation()
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [subject, setSubject] = useState("")

  const [isLoading, setIsLoading] = useState(false)
  const handleFormSubmit = (e) => {
    setIsLoading(true)

    e.preventDefault()
    const data = {
      name,
      email,
      subject,
      message,
    }
    if (!name || !email || !subject || !message) {
      swal("Please provide a valid email address.")
    } else {
      axios
        .post("https://autohouse.onrender.com/contact_us/add_contact_us", data)
        .then((response) => {
          console.log(response.data)
          swal(
            "Good job!",
            "Your Data has been sent successfully!",
            "success"
          ).then(window.location.reload())
          setIsLoading(false)
        })
        .catch((error) => {
          console.log(error)
          swal("Oops!", "Please try again!", "error")
        })
      setIsLoading(false)

      // If the form is successfully submitted, show a success toast
      swal("Good job!", "Form submitted successfully!", "success")
      setName("")
      setEmail("")
      setSubject("")

      setMessage("")
      setIsLoading(false)
    }
  }
  return (
    <Fragment>
      <SEO
        titleTemplate="Contact"
        description="Contact page of flone react minimalist eCommerce template."
      />
      {/* <LayoutOne headerTop="visible"> */}
      {/* breadcrumb */}
      <Breadcrumb
        dir="rtl"
        lang="ar"
        pages={[
          { label: "تواصل معنا", path: process.env.PUBLIC_URL + pathname },

          { label: "الرئيسية", path: process.env.PUBLIC_URL + "/" },
        ]}
      />
      <div className="contact-area  pb-100">
        <div className="container">
          <div className="contact-map mb-10">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3411.8444737678074!2d29.945025075599823!3d31.225037974350577!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzHCsDEzJzMwLjEiTiAyOcKwNTYnNTEuNCJF!5e0!3m2!1sen!2seg!4v1707390393258!5m2!1sen!2seg"
              style={{ width: "100%", height: "50vh" }}
              allowFullScreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
            {/* <GoogleMap lat={30.55} lng={30.876} /> */}
          </div>
          <div className="custom-row-2">
            <div className="col-12 col-lg-8 col-md-7">
              <div className="contact-form">
                <div className="contact-title mb-30">
                  <h2 dir="rtl" lang="ar">
                    تواصل معنا
                  </h2>
                </div>
                <form
                  className="contact-form-style"
                  onSubmit={handleFormSubmit}
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <input
                        name="email"
                        dir="rtl"
                        lang="ar"
                        placeholder="البريد الاليكتروني*"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-6">
                      <input
                        dir="rtl"
                        lang="ar"
                        name="name"
                        placeholder="الاسم*"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>

                    <div className="col-lg-12">
                      <input
                        dir="rtl"
                        lang="ar"
                        name="subject"
                        placeholder="الموضوع*"
                        type="text"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-12">
                      <textarea
                        name="message"
                        dir="rtl"
                        lang="ar"
                        placeholder="رسالتك*"
                        defaultValue={""}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <button className="submit" type="submit">
                        {isLoading ? (
                          <i class="fas fa-spinner fa-spin"></i>
                        ) : (
                          "تأكيد"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
                <p className="form-message" />
              </div>
            </div>
            <div className="col-12 col-lg-4 col-md-5 ">
              <div className="contact-info-wrap" dir="rtl" lang="ar">
                <div className="single-contact-info">
                  <div className="contact-iconAr">
                    <i className="fa fa-phone" />
                  </div>
                  <div className="contact-info-dec">
                    <p>
                      <a href="tel:01222185218">5218 218 122 20+</a>{" "}
                    </p>

                    {/* <p><a href="tel:01222185218" >+20 122 2185218</a> </p> */}
                    {/* <p>+20 122 2185218</p> */}
                  </div>
                </div>
                <div className="single-contact-info">
                  <div className="contact-iconAr">
                    <i className="fa fa-envelope" />
                  </div>
                  <div className="contact-info-dec">
                    <p>
                      <a href="mailto:autoHouse@gmail.com">
                        autoHouse@gmail.com
                      </a>
                    </p>
                    {/* <p>
                        <a href="https://neoneg.com">
                          neoneg.com
                        </a>
                      </p> */}
                  </div>
                </div>
                <div className="single-contact-info">
                  <div className="contact-iconAr">
                    <i className="fa fa-map-marker" />
                  </div>
                  <div className="contact-info-dec">
                    <p style={{ textAlign: "justify" }}>
                      401 ابو قير، ابو النواطير، سيدي جابر، محافظة الاسكندرية.{" "}
                    </p>
                  </div>
                </div>
                <div className="contact-social text-center">
                  <h3>تابعنا</h3>
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/nabilassem1966?mibextid=ibOpuV">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    {/* <li>
                        <a href="https://www.facebook.com/nabilassem1966">
                          <i className="fa fa-pinterest-p" />
                        </a>
                      </li> */}
                    <li>
                      <a
                        onClick={() => {
                          window.open(`https://wa.me/+201222185218`)
                        }}
                        target="_blank"
                      >
                        <i className="fa fa-whatsapp" />
                      </a>
                    </li>
                    {/* <li>
                        <a href="https://www.facebook.com/nabilassem1966">
                          <i className="fa fa-youtube" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/nabilassem1966">
                          <i className="fa fa-instagram" />
                        </a>
                      </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </LayoutOne> */}
    </Fragment>
  )
}

export default ContactAr
