import React, { Fragment, useState } from "react"
import SEO from "../../components/seo"
import LayoutTwo from "../../layouts/LayoutTwo"
import HeroSliderThree from "../../wrappers/hero-slider/HeroSliderThree"
import CategoryOneSlider from "../../wrappers/category/CategoryOneSlider"
import SectionTitleWithTextAr from "../../components/section-title/SectionTitleWithTextAr"
import TabProductThree from "../../wrappers/product/TabProductThree"
import BannerThree from "../../wrappers/banner/BannerThree"
import TestimonialOne from "../../wrappers/testimonial/TestimonialOne"
import BrandLogoSliderOne from "../../wrappers/brand-logo/BrandLogoSliderOne"
import BlogFeaturedTwo from "../../wrappers/blog-featured/BlogFeaturedTwo"
import HeroSliderTen from "../../wrappers/hero-slider/HeroSliderTen"
import LayoutOne from "../../layouts/LayoutOne"
import { Row, Col } from "react-bootstrap"
import FeatureIconTwo from "../../wrappers/feature-icon/FeatureIconTwo"
import Tab from "react-bootstrap/Tab"
import Nav from "react-bootstrap/Nav"
import Select from "react-select"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import image from "../../assets/purchase_image.33b022d1.png"
import HeroSliderTenAr from "../../wrappers/hero-slider/HeroSliderTenAr"
import CategoryOneSliderAr from "../../wrappers/category/CategoryOneSliderAr"
import TabProductThreeAr from "../../wrappers/product/TabProductThreeAr"
import FeatureIconTwoAr from "../../wrappers/feature-icon/FeatureIconTwoAr"
import BrandLogoSliderOneAr from "../../wrappers/brand-logo/BrandLogoSliderOneAr"
import BlogFeaturedTwoAr from "../../wrappers/blog-featured/BlogFeaturedTwoAr"

const HomeElectronicsAr = () => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      "&:hover": {
        borderColor: "#dc3545",
      },

      borderColor: state.isFocused ? "#dc3545" : "#adb5bd",
      boxShadow: "#dc3545",
    }),
    option: (provided, state) => ({
      ...provided,
      "&:active": {
        backgroundColor: "#dc3545",
      },
      backgroundColor: state.isFocused ? "#dc3545" : "#fff", // Background color on hover
      color: state.isFocused ? "#fff" : null, // Text color on hover
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isActive ? "#dc3545" : null, // Background color on active state
    }),
  }
  const monthsOptions = [
    { label: "12 Months", value: 12 },
    { label: "24 Months", value: 24 },
    { label: "36 Months", value: 36 },
    { label: "48 Months", value: 48 },
  ]
  const [carType, setCarType] = useState("new")
  const [carPrice, setCarPrice] = useState("")
  const [installmentAmount, setInstallmentAmount] = useState(0)
  const [totalRepayment, setTotalRepayment] = useState(0)
  const [totalInterest, setTotalInterest] = useState(0)
  const [monthlyInstallments, setMonthlyInstallments] = useState("")
  const [paymentCount, setPaymentCount] = useState(12)
  const [totalPrice, setTotalPrice] = useState(null)
  const calculateInstallments = () => {
    console.log(carPrice)
    console.log(paymentCount.value)
    console.log(monthlyInstallments)
    const loanAmount = parseFloat(carPrice) || 0.0
    const loanTermInMonths = parseInt(paymentCount.value) // Convert years to months
    const interestRate = parseFloat(monthlyInstallments) || 0.0

    const monthlyInterest = interestRate / 100 / 12
    const monthlyPayment =
      (loanAmount * monthlyInterest) /
      (1 - Math.pow(1 + monthlyInterest, -loanTermInMonths))

    setInstallmentAmount(monthlyPayment)
    setTotalRepayment(monthlyPayment * loanTermInMonths)
    setTotalInterest(totalRepayment - loanAmount)
  }

  const handleCarTypeChange = (type) => {
    setCarType(type)
  }

  const handleCarPriceChange = (e) => {
    setCarPrice(e.target.value)
  }

  const handleMonthlyInstallmentsChange = (e) => {
    setMonthlyInstallments(e.target.value)
  }

  const handlePaymentCountChange = (e) => {
    console.log(e.value)
    setPaymentCount(e)
  }

  const calculateTotalPrice = () => {
    const price = parseFloat(carPrice)
    const installments = parseFloat(monthlyInstallments)

    if (isNaN(price) || isNaN(installments)) {
      alert(
        "Please enter valid numbers for Car Price and Monthly Installments."
      )
      return
    }

    const total = price + installments * paymentCount
    setTotalPrice(total.toFixed(2))
  }

  return (
    <Fragment>
      <SEO titleTemplate="AUTO HOUSE " description="AUTO HOUSE" />

      <HeroSliderTenAr />

      <CategoryOneSliderAr spaceBottomClass="pb-95" />
      <SectionTitleWithTextAr spaceBottomClass="pb-90" />

      <TabProductThreeAr spaceBottomClass="pb-20" category="electronics" />
      <FeatureIconTwoAr spaceTopClass="pt-100" spaceBottomClass="pb-60" />

      <BrandLogoSliderOneAr
        bgColorClass="bg-red-3"
        // spaceBottomClass="pb-95"
        // spaceTopClass="pt-100"
      />

      {/* blog featured */}
      <BlogFeaturedTwoAr spaceBottomClass="pb-15 pt-90" />
      <h2
        className="title-center"
        style={{ textAlign: "center", padding: "1%" }}
      >
        هل أنت جاهز للانطلاق على الطريق في سيارتك الحلم؟
      </h2>
      <p
        className="text-center"
        style={{
          fontSize: "16px",
          color: "#4b4b4b",
          lineHeight: "30px",
        }}
      >
        {
          "قم بتقدير دفعاتك الشهرية باستخدام آلة الحاسبة للتمويل المفيدة لدينا. ما عليك سوى إدخال شروط القرض المرغوبة."
        }
        <br />
        {"والدفعة الأولى للحصول على فكرة شخصية عن القدرة على التحمل."}
      </p>
      <div className="login-register-area pt-10 pb-100 ">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-md-6 ms-auto me-auto"
              style={{
                // backgroundImage: `url(${image})`,
                backgroundSize: "cover",
              }}
            >
              <div
                className="login-register-wrapper"
                style={{ backgroundColor: "rgba(255, 128, 128, 0.1 )" }}
              >
                <Tab.Container defaultActiveKey="Installments">
                  {/* <Nav variant="pills" className="login-register-tab-list">
                      <Nav.Item>
                        <Nav.Link eventKey="Installments">
                          <h4>Using Installments</h4>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="Using">
                          <h4>Using Car Price</h4>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav> */}
                  <Tab.Content>
                    <Tab.Pane eventKey="Installments">
                      <div
                        className="login-form-containera"
                        style={{ padding: "3rem" }}
                        dir="rtl"
                      >
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <h4
                            style={{
                              fontSize: "25px",
                              fontWeight: "700",
                              color: "black",
                              margin: "0 20px",
                              textTransform: "capitalize",
                              transition: "all 0.3s ease 0s",
                            }}
                          >
                            استخدام الأقساط
                          </h4>
                          {/* <p>Car Status</p>
                            <div className="d-block"> */}
                          {/* <label>
                                <input
                                  readOnly
                                  value="new"
                                  checked={carType === "new"}
                                  style={{
                                    textAlign: "center",
                                    cursor: "pointer",
                                  }}
                                  onChange={() => handleCarTypeChange("new")}
                                />
                              </label>
                              <label>
                                <input
                                  readOnly
                                  style={{
                                    textAlign: "center",
                                    cursor: "pointer",
                                  }}
                                  value="used"
                                  checked={carType === "used"}
                                  onChange={() => handleCarTypeChange("used")}
                                />
                              </label> */}
                          {/* </div> */}
                        </Row>

                        <br />
                        <form
                          onSubmit={(e) => {
                            e.preventDefault()
                            calculateInstallments()
                          }}
                        >
                          <Row
                            className="mt-3"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <label>المبلغ الإجمالي (ج.م)</label>
                            <br />
                            <OutlinedInput
                              style={{ width: "100%", height: "2.5rem" }}
                              id="outlined-adornment-price"
                              required
                              error="Please fill this feild"
                              type="number"
                              endAdornment={
                                <InputAdornment position="end">
                                  ج.م
                                </InputAdornment>
                              }
                              aria-describedby="outlined-price-helper-text"
                              inputProps={{
                                "aria-label": "price",
                              }}
                              value={carPrice}
                              onChange={handleCarPriceChange}
                            />
                            {/* <input
                                  type="number"
                                  className="form-control"
                                  value={carPrice}
                                  onChange={handleCarPriceChange}
                                /> */}
                            <label>سعر الفائدة (ج.م)</label>
                            <br />
                            <OutlinedInput
                              style={{ width: "100%", height: "2.5rem" }}
                              id="outlined-adornment-price"
                              required
                              type="number"
                              endAdornment={
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              }
                              aria-describedby="outlined-price-helper-text"
                              inputProps={{
                                "aria-label": "price",
                              }}
                              value={monthlyInstallments}
                              onChange={handleMonthlyInstallmentsChange}
                            />
                            {/* <input
                                  type="number"
                                  className="form-control"
                                  value={monthlyInstallments}
                                  onChange={handleMonthlyInstallmentsChange}
                                /> */}{" "}
                            <label style={{ width: "100%" }}>
                              عدد الدفعات{" "}
                            </label>
                            <Select
                              required
                              options={monthsOptions}
                              value={paymentCount}
                              styles={customStyles}
                              style={{
                                backgroundColor: "transparent !important",
                                height: "2.5rem",
                              }}
                              onChange={handlePaymentCountChange}
                              className="form-control p-0"
                            />
                          </Row>
                          <Row
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                          >
                            <div
                              className="sticky-button"
                              style={{
                                backgroundColor: "red",
                                padding: "10px",
                                paddingLeft: "7%",
                                paddingRight: "7%",
                                textAlign: "center",
                                borderRadius: "10px",
                                display: "flex",
                                justifyContent: "center",
                                width: "60%",
                                float: "center",
                                marginTop: "10px",
                              }}
                            >
                              <button
                                className="btn"
                                type="submit"
                                style={{ color: "#fff", textAlign: "center" }}
                                // onClick={}
                              >
                                حساب السعر الإجمالي
                              </button>
                            </div>
                            <br />
                            {/* {totalPrice !== null && (
                              <p>Total Price {totalPrice} EGP</p>
                            )} */}
                          </Row>
                        </form>
                        <br />

                        {totalRepayment != 0 && (
                          <>
                            <div
                              className="mt-3"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              {" "}
                              <p
                                style={{ fontSize: "22", fontWeight: "700" }}
                                // className="mt-5"
                              >
                                إجمالي المبلغ المسترد{" "}
                              </p>{" "}
                              <span
                                style={{ fontSize: "20", fontWeight: "500" }}
                              >
                                {parseInt(totalRepayment, 10).toLocaleString()}{" "}
                                ج.م
                              </span>{" "}
                            </div>{" "}
                            <div
                              className="mt-1"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <p style={{ fontSize: "22", fontWeight: "700" }}>
                                مبلغ الأقساط الشهرية{" "}
                              </p>
                              <span
                                style={{ fontSize: "20", fontWeight: "500" }}
                              >
                                {parseInt(
                                  installmentAmount,
                                  10
                                ).toLocaleString()}{" "}
                                ج.م
                              </span>
                            </div>
                            <div
                              className="mt-1"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <p style={{ fontSize: "22", fontWeight: "700" }}>
                                إجمالي الفائدة{" "}
                              </p>{" "}
                              <span
                                style={{ fontSize: "20", fontWeight: "500" }}
                              >
                                {" "}
                                {parseInt(
                                  totalInterest,
                                  10
                                ).toLocaleString()}{" "}
                                ج.م
                              </span>{" "}
                            </div>
                          </>
                        )}
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="Using">
                      <div className="login-form-container">
                        <p>Car Status</p>
                        {/* <label>
                            <input
                              readOnly
                              value="new"
                              checked={carType === "new"}
                              style={{ textAlign: "center" }}
                              onChange={() => handleCarTypeChange("new")}
                            />
                          </label>
                          <label>
                            <input
                              readOnly
                              style={{ textAlign: "center" }}
                              value="used"
                              checked={carType === "used"}
                              onChange={() => handleCarTypeChange("used")}
                            />
                          </label> */}

                        <br />
                        <label>
                          المبلغ الإجمالي (جنيه مصري)
                          <OutlinedInput
                            id="outlined-adornment-price"
                            required
                            type="number"
                            endAdornment={
                              <InputAdornment position="end">LE</InputAdornment>
                            }
                            aria-describedby="outlined-price-helper-text"
                            inputProps={{
                              "aria-label": "price",
                            }}
                            value={carPrice}
                            onChange={handleCarPriceChange}
                          />
                        </label>
                        <br />
                        <label>
                          سعر الفائدة
                          <OutlinedInput
                            id="outlined-adornment-price"
                            required
                            type="number"
                            endAdornment={
                              <InputAdornment position="end">&</InputAdornment>
                            }
                            aria-describedby="outlined-price-helper-text"
                            inputProps={{
                              "aria-label": "price",
                            }}
                            value={monthlyInstallments}
                            onChange={handleMonthlyInstallmentsChange}
                          />
                        </label>
                        <br />
                        <label>عدد الدفعات</label>
                        <Select
                          required
                          options={monthsOptions}
                          value={paymentCount}
                          styles={customStyles}
                          onChange={handlePaymentCountChange}
                          className="form-control p-0"
                        />
                        <br />
                        <div
                          className="sticky-button"
                          style={{
                            backgroundColor: "red",
                            padding: "10px",
                            paddingLeft: "7%",
                            paddingRight: "7%",
                            textAlign: "center",
                            borderRadius: "20px",
                            display: "flex",
                            justifyContent: "center",
                            width: "30%",
                            float: "right",
                          }}
                        >
                          <button
                            style={{ color: "#fff", textAlign: "center" }}
                            onClick={calculateInstallments}
                            type="submit"
                          >
                            حساب السعر الإجمالي
                          </button>
                        </div>
                        {/* <button onClick={calculateTotalPrice}>
                            Calculate Total Price
                          </button> */}
                        <br />
                        {installmentAmount !== null && (
                          <>
                            <p>
                              سداد
                              {totalRepayment.toLocaleString()} EGP
                            </p>{" "}
                            <p>
                              إجمالي مبلغ الأقساط الشهرية {installmentAmount}{" "}
                              EGP
                            </p>
                            <p>إجمالي الفائدة {totalInterest} EGP</p>
                          </>
                        )}
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </LayoutOne> */}
    </Fragment>
  )
}

export default HomeElectronicsAr
