import PropTypes from "prop-types"
import clsx from "clsx"
import React, { Fragment, useEffect, useState } from "react"

import featureIconData from "../../data/feature-icons/feature-icon-two.json"
import FeatureIconTwoSingle from "../../components/feature-icon/FeatureIconTwoSingle.js"
import axios from "axios"
import Swiper, { SwiperSlide } from "../../components/swiper"
// import { Swiper, SwiperSlide } from "swiper/react";

const settings = {
  // loop: true,
  autoplay: true,
  grabCursor: true,
  breakpoints: {
    320: {
      slidesPerView: 2,
    },
    640: {
      slidesPerView: 3,
    },
    1024: {
      slidesPerView: 4,
    },
    768: {
      slidesPerView: 4,
    },
  },
}
const FeatureIconTwo = ({ spaceTopClass, spaceBottomClass }) => {
  const [types, setTypes] = useState([])

  useEffect(() => {
    axios
      .get("https://autohouse.onrender.com/car/show_all_body_style_user")
      .then((response) => {
        console.log(response)
        const filteredTypes = response.data.result.filter(
          (item) => item.name !== "All"
        )

        setTypes(filteredTypes)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])
  console.log("Types:", types)

  // const filteredTypes = types.slice(1);

  return (
    <div className={clsx("support-area", spaceTopClass, spaceBottomClass)}>
      <div className="container">
        <div
          className="row feature-icon-two-wrap"
          style={{ justifyContent: "center", display: "flex" }}
        >
          {types.length > 0 && (
            <Swiper options={settings}>
              {types.map((single, key) => (
                <SwiperSlide
                  key={key}
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <FeatureIconTwoSingle
                    data={single}
                    spaceBottomClass="mb-30"
                    textAlignClass="text-center"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      </div>
    </div>
  )
}

FeatureIconTwo.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
}

export default FeatureIconTwo
