import React, { Fragment, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import Tab from "react-bootstrap/Tab"
import Nav from "react-bootstrap/Nav"
import SEO from "../../components/seo"
import LayoutOne from "../../layouts/LayoutOne"
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb"
import axios from "axios"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
const Forget = () => {
  const MySwal = withReactContent(Swal)
  let { pathname } = useLocation()

  const [Login, setLogin] = useState({
    userEmail: "",
  })

  const handleSubmitLogin = (e) => {
    e.preventDefault()
    // console.log(Login);
    axios
      .patch(
        "https://autohouse.onrender.com/customer/user_forget_password",
        Login
      )
      .then((response) => {
        console.log(response.data)

        MySwal.fire({
          title: "Enter Verification Code",
          html:
            '<input id="swal-input1" class="swal2-input" placeholder="Enter Verification Code" autocapitalize="off">' +
            '<input id="swal-input2" class="swal2-input" type="password" placeholder="Enter New Password" autocapitalize="off">',
          showCancelButton: true,
          confirmButtonText: "Look up",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return {
              verification_code: document.getElementById("swal-input1").value,
              newPassword: document.getElementById("swal-input2").value,
            }
          },
        }).then((value) => {
          console.log(value)
          if (
            value.value === null ||
            value.value === "" ||
            value.value === undefined
          ) {
            MySwal.fire("opps", "No verification code entered!", "error")
            // console.log('No verification code entered');
            return
          }
          const { verification_code, newPassword } = value.value

          axios
            .patch(
              `https://autohouse.onrender.com/customer/reset_password_user`,
              {
                verification_code,
                email: Login.userEmail,
                newPassword,
              }
            )
            .then((response) => {
              // console.log(response.data);
              MySwal.fire(
                "Success",
                "Verification code submitted. Please go to login!",
                "success"
              ).then(() => {
                window.location.replace("/login")
              })
            })
            .catch((error) => {
              // console.log(error);
              MySwal.fire("Oops!", "Please try again!", "error")
            })
        })
      })
      .catch((error) => {
        console.log(error)
        if (error.response.data.comment === "verify First") {
          MySwal.fire({
            title: "Error",
            text: error.response.data.comment,
            icon: "error",
            buttons: {
              ok: {
                text: "OK",
                value: "ok",
              },
            },
            closeOnClickOutside: false,
          }).then((value) => {
            console.log(value)

            if (value.value === true) {
              MySwal.fire({
                title: "Enter Verification Code",
                input: "text",
                inputPlaceholder: "Enter Verification Code",
                inputAttributes: {
                  autocapitalize: "off",
                },
                showCancelButton: true,
                confirmButtonText: "Look up",
                showLoaderOnConfirm: true,
              }).then((value) => {
                console.log(value)
                if (
                  value.value === null ||
                  value.value === "" ||
                  value.value === undefined
                ) {
                  MySwal.fire("opps", "No verification code entered!", "error")
                  // console.log('No verification code entered');
                  return
                }

                const verification_code = value.value

                axios
                  .patch(
                    `https://autohouse.onrender.com/customer/user_verification`,
                    {
                      verification_code,
                      email: Login.email,
                    }
                  )
                  .then((response) => {
                    // console.log(response.data);
                    MySwal.fire(
                      "Success",
                      "Verification code submitted. Please go to login!",
                      "success"
                    ).then(() => {
                      window.location.replace("/login")
                    })
                  })
                  .catch((error) => {
                    // console.log(error);
                    MySwal.fire("Oops!", "Please try again!", "error")
                  })
              })
            } else {
              // console.log('fi moshkla');
            }
          })
        } else {
          console.log(error.response.data.message)
          MySwal.fire("Error", error.response.data.message, "error")
        }
      })
  }

  return (
    <Fragment>
      <SEO
        titleTemplate="Login"
        description="Login page of flone react minimalist eCommerce template."
      />
      {/* <LayoutOne headerTop="visible"> */}
      {/* breadcrumb */}
      <Breadcrumb
        pages={[
          { label: "Home", path: process.env.PUBLIC_URL + "/" },
          {
            label: "Login Register",
            path: process.env.PUBLIC_URL + pathname,
          },
        ]}
      />
      <div className="login-register-area pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-12 ms-auto me-auto">
              <div className="login-register-wrapper">
                <Tab.Container defaultActiveKey="login">
                  <Nav variant="pills" className="login-register-tab-list">
                    <Nav.Item>
                      <Nav.Link eventKey="login">
                        <h4>Forget Password</h4>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="login">
                      <div className="login-form-container">
                        <div className="login-register-form">
                          <form onSubmit={handleSubmitLogin}>
                            <input
                              type="email"
                              name="email"
                              placeholder="Email"
                              value={Login.userEmail}
                              onChange={(e) =>
                                setLogin({
                                  ...Login,
                                  userEmail: e.target.value,
                                })
                              }
                            />

                            <div className="button-box">
                              <button type="submit">
                                <span>Submit</span>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </LayoutOne> */}
    </Fragment>
  )
}

export default Forget
