import { useState } from "react";
const ShopSearch = ({ onSearchChange }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    onSearchChange({ name: term });  };

  return (
    <div className="sidebar-widget">
    <h4 className="pro-sidebar-title">Search </h4>
    <div className="pro-sidebar-search mb-50 mt-25">
      <form className="pro-sidebar-search-form" action="#">
        <input
          type="text"
          placeholder="Search here..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button>
          <i className="pe-7s-search" />
        </button>
      </form>
    </div>
  </div>
  );
};

export default ShopSearch;
