import PropTypes from "prop-types";

import { setActiveLayout } from "../../helpers/product";

const ShopTopAction = ({
  getLayout,
  handleFilterSortParams,
  productCount,
  sortedProductCount
}) => {
  return (
    <div className="shop-top-bar mb-35">
      {/* <div className="select-shoing-wrap">
        <div className="shop-select">
          <select
            onChange={e => handleFilterSortParams("sort", e.target.value)}
          >
            <option value="Most Recent">Most Recent</option>
            <option value="Highest Price">Highest Price</option>
            <option value="Lowest Price">Lowest Price</option>
            <option value="Highest Year">Highest Year</option>
            <option value="Lowest Year">Lowest Year</option>
            <option value="Highest Kilometers">Highest Kilometers</option>
            <option value="Lowest Kilometers">Lowest Kilometers</option>

          </select>
        </div>
        <p>
          Showing {sortedProductCount} of {productCount} result
        </p>
      </div> */}

      <div className="shop-tab">
        {/* <button
          onClick={e => {
            getLayout("grid two-column");
            setActiveLayout(e);
          }}
        >
          <i className="fa fa-th-large" />
        </button> */}
        <button
          onClick={e => {
            getLayout("grid three-column");
            setActiveLayout(e);
          }}
        >
          <i className="fa fa-th" />
        </button>
        <button
          onClick={e => {
            getLayout("list");
            setActiveLayout(e);
          }}
        >
          <i className="fa fa-list-ul" />
        </button>
      </div>
    </div>
  );
};

ShopTopAction.propTypes = {
  handleFilterSortParams: PropTypes.func,
  getLayout: PropTypes.func,
  productCount: PropTypes.number,
  sortedProductCount: PropTypes.number
};

export default ShopTopAction;
