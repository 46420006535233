import { Fragment, useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"

import axios from "axios"
import ProductModal from "../../components/product/ProductModal"
import CarModal from "../../components/product/CarModal"
import { MDBDataTable } from "mdbreact"

const CarAppointment = () => {
  const [modalShow, setModalShow] = useState(false)
  const [carDataForModal, setCarDataForModal] = useState(null)

  const [loading, setLoading] = useState(false)
  const [dynamicLabelState, setDynamicLabelState] = useState("")

  const [filteredData, setFilteredData] = useState([])
  const TOKEN = sessionStorage.getItem("token")
  // const [columns, setColumns] = useState([]);

  const fetchData = async () => {
    setLoading(true)

    try {
      const response = await axios.get(
        "https://autohouse.onrender.com/book/show_all_sent_books_user",
        {
          headers: {
            token: `${TOKEN}`,
            lang: "AR",
          },
        }
      )
      setFilteredData(response.data.result)
      setLoading(false)
      generateColumns(response.data.result)
      console.log(response.data.result)
    } catch (error) {
      setLoading(false)

      console.error("Error fetching data:", error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  const generateColumns = (data) => {
    const hasBranchOrCustomer = data.some(
      (appointment) =>
        appointment.car.branch !== null || appointment.customer !== null
    )
    const dynamicLabel = hasBranchOrCustomer ? "Branch" : "Customer"
    setDynamicLabelState(dynamicLabel)
  }

  const columns = [
    {
      label: "الاسم",
      field: "name",
      sort: "asc",
    },
    {
      label: "تاريخ الحضور",
      field: "attend_date",
      sort: "asc",
    },
    {
      label: "وقت الحضور",
      field: "attend_time",
      sort: "asc",
    },

    {
      label: "الموافقة",
      field: "accept",
      sort: "asc",
    },

    // {
    //   label: "Car",
    //   field: "car",
    //   sort: "asc",
    // },
    {
      label: dynamicLabelState,
      field: "branch_or_customer",
      sort: "asc",
    },
    {
      label: "حذف",
      field: "delete",
      sort: "asc",
    },
  ]
  const rows = filteredData.map((appointment, key) => ({
    name: appointment.car.make + " " + appointment.car.model,
    attend_date: new Date(appointment.attend_date).toLocaleDateString(),
    attend_time: appointment.attend_time,
    accept: (
      <div
        style={{
          backgroundColor:
            appointment.accept === "معلق"
              ? "yellow"
              : appointment.accept === "مقبول"
              ? "green"
              : appointment.accept === "مرفوض"
              ? "red"
              : "transparent",
          color: "black",
          margin: "3%",
        }}
      >
        {appointment.accept}
      </div>
    ),
    branch_or_customer: appointment.car.branch ? (
      appointment.car.branch
    ) : (
      <button
        onClick={() => {
          setCarDataForModal(appointment.customer)
          setModalShow(true)
        }}
        title="Quick View"
      >
        <i className="fa fa-eye"></i>
      </button>
    ),
    // car: (
    //   <button
    //     onClick={() => {
    //       setCarDataForModal(appointment.car);
    //       setModalShow(true);
    //     }}
    //     title="Quick View"
    //   >
    //     <i className="fa fa-eye"></i>
    //   </button>
    // ),
    delete: (
      <button
        onClick={(e) => {
          e.preventDefault()
          handleDelete(appointment.id)
        }}
        title="Delete appointment"
      >
        <i className="fa-regular fa-trash-can"></i>
      </button>
    ),
  }))

  const data = {
    columns,
    rows,
  }
  const handleDelete = (id) => {
    axios
      .delete(`https://autohouse.onrender.com/book/delete_book_user/${id}`, {
        headers: {
          token: TOKEN,
        },
      })
      .then((res) => {
        console.log(res)
        window.location.reload()
        // setReload(!reload)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  return (
    <Fragment>
      <div className="cart-main-area ">
        <div className="container">
          <Fragment>
            <h3 className="cart-page-title">مواعيد سيارتك</h3>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "70vh",
                  alignItems: "center",
                }}
              >
                <div className="typing-indicator">
                  <div className="typing-circle"></div>
                  <div className="typing-circle"></div>
                  <div className="typing-circle"></div>
                  <div className="typing-shadow"></div>
                  <div className="typing-shadow"></div>
                  <div className="typing-shadow"></div>
                </div>
              </div>
            ) : (
              <div className="row" style={{ marginTop: "1rem" }}>
                <div className="col-12">
                  <div className="table-content table-responsive cart-table-content">
                    <MDBDataTable
                      striped
                      bordered
                      small
                      data={{
                        columns: columns,
                        rows: rows.map((one) => {
                          return {
                            ...one,
                            attend_date: new Date(
                              one.attend_date
                            ).toLocaleDateString("ar-EG", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }),
                          }
                        }),
                      }}
                      noBottomColumns
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-lg-12">
                <div className="cart-shiping-update-wrapper">
                  <div className="cart-shiping-update">
                    <Link to={process.env.PUBLIC_URL + "/shop-grid-full-width"}>
                      متابعة التسوق
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        </div>
      </div>
      {carDataForModal && (
        <CarModal
          show={modalShow}
          onHide={() => {
            setModalShow(false)
            setCarDataForModal(null)
          }}
          product={carDataForModal}
        />
      )}
    </Fragment>
  )
}

export default CarAppointment
