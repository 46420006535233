import React, { useState } from "react"
import { Col, Row, Form, Alert } from "react-bootstrap"

import { DropzoneArea } from "material-ui-dropzone"

const DropzoneAreaExample = ({
  car,
  setCar,
  files,
  setFiles,
  setDeletedImages,
  deletedImages,
}) => {
  const handleChange = (newFiles) => {
    setFiles(newFiles)
    console.log(newFiles)
  }

  return (
    <Row className="mt-5">
      <DropzoneArea
        initialFiles={files}
        onChange={handleChange}
        filesLimit={10}
      />
      <Row className="mt-3">
        {car.images.map((image) => {
          return (
            <Col md={3}>
              <div style={{ position: "relative" }}>
                {" "}
                <img
                  src={image.image}
                  style={{ width: "150px", height: "150px" }}
                />{" "}
                <span style={{ position: "absolute", top: "-5%", left: "90%" }}>
                  <i
                    style={{ color: "red", cursor: "pointer" }}
                    className="fa-solid fa-trash fs-4"
                    onClick={() => {
                      setDeletedImages((prev) => [...prev, image])
                      setCar((prev) => ({
                        ...prev,
                        images: prev.images.filter((img) => img !== image),
                      }))
                    }}
                  ></i>
                </span>
              </div>
            </Col>
          )
        })}
      </Row>
    </Row>
  )
}

export default DropzoneAreaExample
