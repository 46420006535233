import PropTypes from "prop-types";

const TermsAndConditions = () => {
  return (
    <>
      <div className="contact-area " style={{ backgroundColor: "transparent" }}>
        <div className="container" style={{ maxWidth: "1120px" }}>
          <div className="row">
            <div className="col-md-12">
              <div className="container ">
                <h3
                  style={{
                    fontWeight: "600",
                    lineHeight: "1.2em",
                    fontFamily: "Playfair Display",
                    fontSize: "23px",
                    padding: "20px 0px 0px 0px",
                  }}
                >
                  Use of the Website
                </h3>
                <p>
                  You may only use the Website for lawful purposes and in
                  accordance with these Terms. You agree not to:
                </p>
                <ul>
                  <li
                    style={{
                      listStyleType: "disc",
                      lineHeight: "2em",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    Use the Website in any way that could damage, disable,
                    overburden, or impair the Website or any server or network
                    connected to the Website.
                  </li>
                  <li
                    style={{
                      listStyleType: "disc",
                      lineHeight: "2em",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    Attempt to gain unauthorized access to the Website, any
                    portion of the Website, or any other systems or networks
                    connected to the Website, by hacking, password mining, or
                    any other means.
                  </li>
                  <li
                    style={{
                      listStyleType: "disc",
                      lineHeight: "2em",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    Use the Website to collect or harvest personal data about
                    other users.
                  </li>
                  <li
                    style={{
                      listStyleType: "disc",
                      lineHeight: "2em",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    Transmit any spam, viruses, or other harmful code through
                    the Website.
                  </li>
                  <li
                    style={{
                      listStyleType: "disc",
                      lineHeight: "2em",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    Infringe on the intellectual property rights of Auto House
                    or any third party.
                  </li>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    Content and Information{" "}
                  </h3>
                  <p>
                    The information and content on the Website, including, but
                    not limited to, text, graphics, images, logos, and data (the
                    "Content"), is the property of Auto House or its licensors
                    and is protected by copyright, trademark, and other
                    intellectual property laws. You may not use the Content
                    without the express written permission of Auto House.
                  </p>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    Disclaimer of Warranties{" "}
                  </h3>
                  <p>
                    The Website and the Content are provided "as is" and without
                    warranties of any kind, express or implied. Auto House
                    disclaims all warranties, including, but not limited to,
                    warranties of merchantability, fitness for a particular
                    purpose, title, and non-infringement. Auto House does not
                    warrant that the Website will be uninterrupted or
                    error-free, that defects will be corrected, or that the
                    Website or the server that makes it available is free of
                    viruses or other harmful components.
                  </p>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    Limitation of Liability
                  </h3>
                  <p>
                    Auto House will not be liable for any damages arising out of
                    or related to your use of the Website, including, but not
                    limited to, direct, indirect, incidental, consequential,
                    punitive, or special damages.
                  </p>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    Indemnification
                  </h3>
                  <p>
                    You agree to indemnify and hold harmless Auto House, its
                    officers, directors, employees, agents, and licensors from
                    and against any and all claims, losses, damages,
                    liabilities, costs, and expenses (including attorney's fees)
                    arising out of or related to your use of the Website.
                  </p>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    Governing Law and Jurisdiction
                  </h3>
                  <p>
                    These Terms shall be governed by and construed in accordance
                    with the laws of the Arab Republic of Egypt, without regard
                    to its conflict of law provisions. Any legal action or
                    proceeding arising out of or relating to these Terms shall
                    be brought exclusively in the courts of Cairo Governorate.
                  </p>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    Entire Agreement
                  </h3>
                  <p>
                    These Terms constitute the entire agreement between you and
                    Auto House with respect to your use of the Website.
                  </p>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    Severability
                  </h3>
                  <p>
                    If any provision of these Terms is held to be invalid or
                    unenforceable, such provision shall be struck and the
                    remaining provisions shall remain in full force and effect.
                  </p>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    Waiver
                  </h3>
                  <p>
                    No waiver of any provision of these Terms by Auto House
                    shall be deemed a further or continuing waiver of such
                    provision or any other provision.
                  </p>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    Modifications to Terms
                  </h3>
                  <p style={{ marginBottom: "revert" }}>
                    Auto House reserves the right to modify these Terms at any
                    time without prior notice. We will post any changes on this
                    page. You are encouraged to review these Terms periodically
                    to stay informed of any updates.
                  </p>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

TermsAndConditions.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
};

export default TermsAndConditions;
