import React, { Fragment, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import Tab from "react-bootstrap/Tab"
import Nav from "react-bootstrap/Nav"
import SEO from "../../components/seo"
import LayoutOne from "../../layouts/LayoutOne"
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb"
import axios from "axios"
import Swal from "sweetalert2"
import { Alert } from "react-bootstrap"

import withReactContent from "sweetalert2-react-content"
const LoginRegisterAr = () => {
  const MySwal = withReactContent(Swal)
  const [error, setError] = useState("")
  console.log(error)
  const initialFormData = {
    name: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    address: "",
    password: "",
    gender: "",
  }
  const [formData, setFormData] = useState(initialFormData)
  const [Login, setLogin] = useState({
    email: "",
    password: "",
  })
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)

  const [governoratesData, setGovernoratesData] = useState({})

  const [areas, setAreas] = useState([])
  const [cities, setCities] = useState([])
  let { pathname } = useLocation()
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }
  const handleCityChange = (e) => {
    const selectedCity = e.target.value
    const selectedAreas = governoratesData[selectedCity]?.items || []
    setFormData({
      ...formData,
      city: selectedCity,
    })
    setAreas(selectedAreas)
  }

  const handleAreaChange = (e) => {
    const selectedArea = e.target.value
    setFormData({
      ...formData,
      state: selectedArea,
    })
  }
  const handleGenderChange = (e) => {
    const selectedGender = e.target.value
    setFormData({
      ...formData,
      gender: selectedGender,
    })
  }
  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0],
    })
  }
  useEffect(() => {
    axios
      .get("https://autohouse.onrender.com/address")
      .then((response) => {
        console.log(response)
        setGovernoratesData(response.data.governorates)

        setCities(Object.keys(response.data.governorates))
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  const handleSubmit = (e) => {
    setLoading(true)
    e.preventDefault()
    const data = new FormData()

    for (const key in formData) {
      data.append(key, formData[key])
    }

    // Send the data to the server using Axios
    axios
      .post(`https://autohouse.onrender.com/customer/add_user`, data, {})
      .then((response) => {
        if (response.data.success === true) {
          return MySwal.fire({
            title: "Enter verification code",
            input: "text",
            inputPlaceholder: "A verification code has been sent to your email",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Look up",
            showLoaderOnConfirm: true,
          })
        } else {
          setLoading(false)
          console.log("User added successfully:", response.data)
        }
      })
      .then((result) => {
        if (!result.dismiss) {
          const verification_code = result.value
          return axios.patch(
            `https://autohouse.onrender.com/customer/user_verification`,
            {
              verification_code,
              email: formData.email,
            }
          )
        } else {
          // User clicked Cancel or closed the dialog
          setLoading(false)
          return Promise.reject(new Error("Verification code not entered."))
        }
      })
      .then((response) => {
        MySwal.fire(
          "Success",
          "Verification code submitted, go to login!",
          "success"
        ).then(() => {
          window.location.reload()
        })
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
        setError(error.response.data)

        // MySwal.fire("Oops!", error.response.data, "error");
      })
  }
  const handleSubmitLogin = (e) => {
    e.preventDefault()
    setLoading1(true)
    setError(null)

    // console.log(Login);
    axios
      .post("https://autohouse.onrender.com/login/login_user", Login)
      .then((response) => {
        setLoading1(false)

        // console.log(response.data);
        sessionStorage.setItem("token", response.data.token)
        // sessionStorage.setItem("image", response.data.img);
        sessionStorage.setItem("loggedIn", true)
        // sessionStorage.setItem("user_email", response.data.email);
        // sessionStorage.setItem("user_id", response.data._id);
        // sessionStorage.setItem("code", response.data.code);

        // sessionStorage.setItem("diploma", response.data.studyYear.diploma._id);

        if (
          sessionStorage.getItem("token") &&
          sessionStorage.getItem("loggedIn", true)
        ) {
          // Check if the user is logged in and has a course ID set in local storage
          // console.log('here');

          window.location.replace("/")
        }
      })
      .catch((error) => {
        setLoading1(false)

        console.log(error)
        if (error.response.data.comment === "verify First") {
          MySwal.fire({
            title: "Error",
            text: error.response.data.comment,
            icon: "error",
            buttons: {
              ok: {
                text: "OK",
                value: "ok",
              },
            },
            closeOnClickOutside: false,
          }).then((value) => {
            console.log(value)

            if (value.value === true) {
              MySwal.fire({
                title: "Enter Verification Code",
                input: "text",
                inputPlaceholder: "Enter Verification Code",
                inputAttributes: {
                  autocapitalize: "off",
                },
                showCancelButton: true,
                confirmButtonText: "Look up",
                showLoaderOnConfirm: true,
              }).then((value) => {
                console.log(value)
                if (
                  value.value === null ||
                  value.value === "" ||
                  value.value === undefined
                ) {
                  MySwal.fire("opps", "No verification code entered!", "error")
                  // console.log('No verification code entered');
                  return
                }

                const verification_code = value.value

                axios
                  .patch(
                    `https://autohouse.onrender.com/customer/user_verification`,
                    {
                      verification_code,
                      email: Login.email,
                    }
                  )
                  .then((response) => {
                    // console.log(response.data);
                    MySwal.fire(
                      "Success",
                      "Verification code submitted. Please go to login!",
                      "success"
                    ).then(() => {
                      window.location.reload()
                    })
                  })
                  .catch((error) => {
                    // console.log(error);
                    setError(error.response.data.message)
                  })
              })
            } else {
              // console.log('fi moshkla');
            }
          })
        } else {
          setLoading1(false)
          setError(error.response.data.message)
          console.log(error.response.data.message)
          // MySwal.fire("Error", error.response.data.message, "error");
        }
      })
  }

  return (
    <Fragment>
      <SEO
        titleTemplate="Login"
        description="Login page of flone react minimalist eCommerce template."
      />
      {/* <LayoutOne headerTop="visible"> */}
      {/* breadcrumb */}
      <Breadcrumb
        pages={[
          {
            label: "تسجيل الدخول",
            path: process.env.PUBLIC_URL + pathname,
          },
          { label: "الرئيسية", path: process.env.PUBLIC_URL + "/" },
        ]}
      />
      <div className="login-register-area  pb-100 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-12 ms-auto me-auto">
              <div className="login-register-wrapper">
                <Tab.Container defaultActiveKey="login">
                  <Nav variant="pills" className="login-register-tab-list">
                    <Nav.Item>
                      <Nav.Link eventKey="login">
                        <h4>تسجيل الدخول</h4>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="register">
                        <h4>قم بالتسجيل</h4>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="login" dir="rtl" lang="ar">
                      <div className="login-form-container">
                        <div className="login-register-form">
                          <form onSubmit={handleSubmitLogin}>
                            {error && (
                              <Alert className="alert-danger">{error}</Alert>
                            )}
                            <input
                              type="email"
                              name="email"
                              placeholder="البريد الالكتروني"
                              value={Login.email}
                              onChange={(e) =>
                                setLogin({ ...Login, email: e.target.value })
                              }
                            />
                            <input
                              type="password"
                              name="password"
                              placeholder="كلمة السر"
                              value={Login.password}
                              onChange={(e) =>
                                setLogin({ ...Login, password: e.target.value })
                              }
                            />
                            <div className="button-box">
                              <div className="login-toggle-btn">
                                {/* <input type="checkbox" /> */}
                                {/* <label className="ml-10">Remember me</label> */}
                                <Link to={process.env.PUBLIC_URL + "/forget"}>
                                  هل نسيت كلمة السر؟
                                </Link>
                              </div>
                              <button type="submit">
                                <span>
                                  {loading1 ? (
                                    <i className="fas fa-spinner fa-spin"></i>
                                  ) : (
                                    "تسجيل الدخول"
                                  )}
                                </span>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="register" dir="rtl" lang="ar">
                      <div className="login-form-container">
                        <div className="login-register-form">
                          <form onSubmit={handleSubmit}>
                            <input
                              type="text"
                              name="name"
                              value={formData.name}
                              onChange={handleInputChange}
                              placeholder="الاسم"
                            />
                            <input
                              name="email"
                              value={formData.email}
                              onChange={handleInputChange}
                              placeholder="البريد الالكتروني"
                              type="email"
                            />
                            <input
                              type="password"
                              name="password"
                              value={formData.password}
                              onChange={handleInputChange}
                              placeholder="كلمة السر"
                            />
                            <input
                              name="phone"
                              value={formData.phone}
                              onChange={handleInputChange}
                              placeholder="رقم التليفون"
                              type="phone"
                            />
                            <input
                              name="address"
                              value={formData.address}
                              onChange={handleInputChange}
                              placeholder="العنوان"
                              type="address"
                            />
                            <div dir="rtl" lang="ar">
                              <label
                                dir="rtl"
                                lang="ar"
                                style={{
                                  float: "rights",
                                  width: "100%",
                                  textAlign: "justify",
                                }}
                              >
                                المحافظة:
                              </label>
                              <select
                                className="form-control"
                                value={formData.city}
                                onChange={handleCityChange}
                              >
                                <option value="">اختر محافظة</option>
                                {cities.map((city) => (
                                  <option key={city} value={city}>
                                    {city}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <label
                                style={{
                                  float: "rights",
                                  width: "100%",
                                  textAlign: "justify",
                                }}
                              >
                                المنطقة:
                              </label>
                              <select
                                className="form-control"
                                value={formData.state}
                                onChange={handleAreaChange}
                              >
                                <option value="">اختر منطقة</option>
                                {areas.map((area) => (
                                  <option key={area} value={area}>
                                    {area}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <label
                                style={{
                                  float: "rights",
                                  width: "100%",
                                  textAlign: "justify",
                                }}
                              >
                                النوع:
                              </label>
                              <select
                                className="form-control"
                                value={formData.gender}
                                onChange={handleGenderChange}
                              >
                                <option value="">اختر نوع</option>

                                <option value="male">ذكر</option>
                                <option value="female">انثي</option>
                              </select>
                            </div>
                            <label
                              htmlFor=""
                              style={{
                                float: "rights",
                                width: "100%",
                                textAlign: "justify",
                              }}
                            >
                              صورة
                            </label>
                            <input
                              type="file"
                              name="image"
                              onChange={handleFileChange}
                            />

                            <div className="button-box">
                              <button type="submit">
                                <span>
                                  {loading ? (
                                    <i className="fas fa-spinner fa-spin"></i>
                                  ) : (
                                    "قم بالتسجيل"
                                  )}
                                </span>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </LayoutOne> */}
    </Fragment>
  )
}

export default LoginRegisterAr
