import PropTypes from "prop-types";
import React,{ useState } from 'react';
// import { setActiveSort } from "../../helpers/product";
import * as Accordion from '@radix-ui/react-accordion';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import classNames from 'classnames';

import './style.css';
const AccordionTrigger = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Header className="AccordionHeader">
    <Accordion.Trigger
      className={classNames('AccordionTrigger', className)}
      {...props}
      ref={forwardedRef}
    >
      {children}
      <ChevronDownIcon className="AccordionChevron" aria-hidden />
    </Accordion.Trigger>
  </Accordion.Header>
));

const AccordionContent = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Content
    className={classNames('AccordionContent', className)}
    {...props}
    ref={forwardedRef}
  >
    <div className="AccordionContentText">{children}</div>
  </Accordion.Content>
));

const ShopCategories = ({ onFilterChange,categories ,setSelectedFilters}) => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [activeCategories, setActiveCategories] = useState({
    old: false,
    new: false,
    old_by_admin: false,
    special: false,
  });
  const toggleAccordion = () => {
    setAccordionOpen(!accordionOpen);
  };
  const handleFilterChange = (category) => {
    const updatedActiveCategories = { ...activeCategories, [category]: !activeCategories[category] };
    setActiveCategories(updatedActiveCategories);
  
    const updatedFilters = { ...onFilterChange, [category]: !onFilterChange[category] };
    setSelectedFilters(updatedFilters);
  
    console.log('Active Categories:', updatedActiveCategories);
    console.log('Selected Filters:', updatedFilters);
  };
  
  const setActiveSort = (e) => {
    if (e.currentTarget) {
      const filterButtons = document.querySelectorAll(
        ".sidebar-widget-list-left button, .sidebar-widget-tag button, .product-filter button"
      );
      filterButtons.forEach((item) => {
        item.classList.remove("active");
      });
      e.currentTarget.classList.add("active");
  
      console.log('Active sort button:', e.currentTarget);
    }
  };
  const displayNamesMapping = {
    special: "Special",
    new: "New",
    old_by_admin: "Used by auto house",

    old: "Used",
  };
  return (
    <div className="sidebar-widget">
    <Accordion.Root className="AccordionRoot" type="single" defaultValue="item-1" collapsible>
    <Accordion.Item className="AccordionItem" value="item-1">
          <AccordionTrigger className="pro-sidebar-title">Car Status </AccordionTrigger>
      
          <AccordionContent className="sidebar-widget-list mt-30">
          <ul class="accordion-list"> 
          {categories.map((category) => (
                <li key={category}>
                  <div className="sidebar-widget-list-left">
                    <button
                      onClick={(e) =>   { setActiveSort(e); handleFilterChange(category)}}
                      className={classNames({ active: activeCategories[category] })}
                    >
                      <span className="checkmark" />{displayNamesMapping[category]}
                    </button>
                  </div>
                </li>
              ))}
          
          
          </ul>  </AccordionContent>
        </Accordion.Item>
      </Accordion.Root>
        {/* ) : (
          "No categories found"
        )} */}
      
    </div>
  );
};

ShopCategories.propTypes = {
  categories: PropTypes.array,
  getSortParams: PropTypes.func
};

export default ShopCategories;
