import {
  Fragment,
  useState,
  useEffect,
  setSortedProducts,
  useCallback,
} from "react"
import Paginator from "react-hooks-paginator"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { getSortedProducts } from "../../helpers/product"
import SEO from "../../components/seo"
import LayoutOne from "../../layouts/LayoutOne"
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb"
import ShopSidebar from "../../wrappers/product/ShopSidebar"
import ShopTopbar from "../../wrappers/product/ShopTopbar"
import ShopProducts from "../../wrappers/product/ShopProducts"
import axios from "axios"
import { isMobile } from "react-device-detect"

const ShopGridFullId = ({ location }) => {
  const initialState = {
    product: {
      products: [], // Array of products
    },
    // ... other state properties
  }
  // Assuming your Redux state looks like this:
  const { products } = useSelector((state) => state.product)
  const { products: productsObject } = useSelector((state) => state.product)
  const productsArray = Object.values(productsObject)

  const [layout, setLayout] = useState("grid three-column")
  const [sortType, setSortType] = useState("")
  const [sortValue, setSortValue] = useState("")
  const [filterSortType, setFilterSortType] = useState("")
  const [filterSortValue, setFilterSortValue] = useState("")
  const [offset, setOffset] = useState(0)
  // const { products } = useSelector((state) => state.product);
  const [filteredProducts, setFilteredProducts] = useState([])
  const [sortedProducts, setSortedProducts] = useState([])
  const [currentData, setCurrentData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [total_pages, setTotalPages] = useState(1)
  const [sidebarVisible, setSidebarVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible)
  }

  const per_page = 6
  const displayPages = 5
  const halfDisplay = Math.floor(displayPages / 2)
  let startPage = Math.max(1, currentPage - halfDisplay)
  let endPage = Math.min(total_pages, startPage + displayPages - 1)
  if (endPage - startPage + 1 < displayPages) {
    startPage = Math.max(1, endPage - displayPages + 1)
  }
  let { pathname } = useLocation()
  // console.log(pathname)
  const id = pathname.split("/shop-grid-full/")
  // console.log(id[1])
  const brand = id[1]
  const [brandID, setBrandID] = useState(brand)
  const [selectedFilters, setSelectedFilters] = useState({
    pricefrom: "",
    priceto: "",
    name: "",
    sort: "",
    new: "",
    old: "",
    old_by_admin: "",
    special: "",
    trim: "",
    transmission: "",
    body_style: "",
    model: "",
    brand: brand,
    engine_capacity: "",
    kilometerfrom: "0",
    kilometerto: "",
    yearfrom: "",
    yearto: "",
  })
  const pageLimit = 10

  const handleFilterChange = (selectedFilters) => {
    // Update filteredProducts with the selected filters
    setFilteredProducts(selectedFilters)
  }

  const getLayout = (layout) => {
    setLayout(layout)
  }

  const getSortParams = (sortType, sortValue) => {
    setSortType(sortType)
    setSortValue(sortValue)
  }

  const getFilterSortParams = (sortType, sortValue) => {
    setFilterSortType(sortType)
    setFilterSortValue(sortValue)
  }

  // useEffect(() => {
  //   // Update sortedProducts with filteredProducts
  //   setSortedProducts(filteredProducts);

  //   // Update currentData based on offset and pageLimit
  //   setCurrentData(filteredProducts.slice(offset, offset + pageLimit));
  // }, [offset, filteredProducts]);

  // useEffect(() => {
  //   let sortedProducts = getSortedProducts(products, sortType, sortValue);
  //   const filterSortedProducts = getSortedProducts(
  //     sortedProducts,
  //     filterSortType,
  //     filterSortValue
  //   );
  //   sortedProducts = filterSortedProducts;
  //   setSortedProducts(sortedProducts);
  //   setCurrentData(filteredProducts.slice(offset, offset + pageLimit));
  // }, [offset, filteredProducts]);

  useEffect(() => {
    handleEndpointCall()
  }, [handleEndpointCall, currentPage])
  // Only call when selectedFilters change

  const handleEndpointCall = useCallback(async () => {
    setLoading(true)

    try {
      // Construct the URL parameters
      console.log(selectedFilters)
      const urlParams = Object.entries(selectedFilters)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&&")

      // Make the endpoint call with Axios
      const response = await axios.get(
        `https://autohouse.onrender.com/car/show_all_cars?${urlParams}&&page=${currentPage}`
      )
      setLoading(false)

      // Update filteredProducts with the actual response data
      setFilteredProducts(response.data.result)
      setTotalPages(response.data.total_pages)
    } catch (error) {
      setLoading(false)

      console.error("Error fetching data:", error)
    }
  }, [selectedFilters, currentPage])

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
    // console.log(newPage);
  }

  return (
    <Fragment>
      <SEO
        titleTemplate="AUTO HOUSE"
        description="AUTO HOUSE of flone react minimalist eCommerce template."
      />

      {/* <LayoutOne headerTop="visible"> */}
      {/* breadcrumb */}
      <Breadcrumb
        pages={[
          { label: "Home", path: process.env.PUBLIC_URL + "/" },
          { label: "Shop", path: process.env.PUBLIC_URL + pathname },
        ]}
      />

      <div className="shop-area pt-95 pb-100">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 order-2 order-lg-1">
              {/* shop sidebar */}
              {isMobile ? (
                <button
                  onClick={() => {
                    toggleSidebar()
                  }}
                  className="mobile-filter-button"
                >
                  Filter
                </button>
              ) : (
                ""
              )}
              <div
                id="filter-section"
                className={
                  isMobile
                    ? `sidebar-container ${sidebarVisible ? "show" : ""}`
                    : `sidebar-container show`
                }
              >
                <ShopSidebar
                  // bodyStyle={bodyStyle}
                  brand={brand}
                  brandID={brandID}
                  total_pages={total_pages}
                  setTotalPages={setTotalPages}
                  products={products}
                  getSortParams={getSortParams}
                  sideSpaceClass="mr-30"
                  onFilterChange={handleFilterChange}
                  setSelectedFilters={setSelectedFilters}
                  selectedFilters={selectedFilters}
                />
              </div>
            </div>
            {isMobile ? (
              <div>
                {sidebarVisible === false ? (
                  <div
                    className="col-lg-9 order-1 order-lg-2"
                    style={{ width: isMobile ? "100%" : "75%" }}
                  >
                    {/* shop topbar default */}
                    <ShopTopbar
                      getLayout={getLayout}
                      getFilterSortParams={getFilterSortParams}
                      productCount={products.length}
                      sortedProductCount={currentData.length}
                    />

                    {/* shop page content default */}
                    <ShopProducts layout={layout} products={filteredProducts} />

                    {/* shop product pagination */}
                    <div className="pro-pagination-style text-center mt-20">
                      <ul
                        className="pagination"
                        style={{ justifyContent: "center" }}
                      >
                        {currentPage !== 1 && total_pages > 1 && (
                          <li className="page-item">
                            <a
                              className={`page-link ${
                                currentPage === 1 ? "disabled" : ""
                              }`}
                              aria-label="Previous"
                              onClick={() => handlePageChange(currentPage - 1)}
                            >
                              <i className="fa fa-angle-double-left" />
                            </a>
                          </li>
                        )}
                        {Array.from(
                          { length: endPage - startPage + 1 },
                          (_, index) => (
                            <li className="page-item" key={startPage + index}>
                              <a
                                className={`page-link ${
                                  currentPage === startPage + index
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  handlePageChange(startPage + index)
                                }
                                disabled={currentPage === startPage + index}
                              >
                                {startPage + index}
                              </a>
                            </li>
                          )
                        )}
                        {currentPage !== total_pages && total_pages > 1 && (
                          <li className="page-item">
                            <a
                              className={`page-link ${
                                currentPage === total_pages ? "disabled" : ""
                              }`}
                              aria-label="Next"
                              onClick={() => handlePageChange(currentPage + 1)}
                            >
                              <i className="fa fa-angle-double-right" />
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div
                className="col-lg-9 order-1 order-lg-2"
                style={{ width: isMobile ? "100%" : "75%" }}
              >
                {/* shop topbar default */}
                <ShopTopbar
                  getLayout={getLayout}
                  getFilterSortParams={getFilterSortParams}
                  productCount={products.length}
                  sortedProductCount={currentData.length}
                />

                {/* shop page content default */}
                <ShopProducts layout={layout} products={filteredProducts} />

                {/* shop product pagination */}
                <div className="pro-pagination-style text-center mt-20">
                  <ul
                    className="pagination"
                    style={{ justifyContent: "center" }}
                  >
                    {currentPage !== 1 && total_pages > 1 && (
                      <li className="page-item">
                        <a
                          className={`page-link ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                          aria-label="Previous"
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          <i className="fa fa-angle-double-left" />
                        </a>
                      </li>
                    )}
                    {Array.from(
                      { length: endPage - startPage + 1 },
                      (_, index) => (
                        <li className="page-item" key={startPage + index}>
                          <a
                            className={`page-link ${
                              currentPage === startPage + index ? "active" : ""
                            }`}
                            onClick={() => handlePageChange(startPage + index)}
                            disabled={currentPage === startPage + index}
                          >
                            {startPage + index}
                          </a>
                        </li>
                      )
                    )}
                    {currentPage !== total_pages && total_pages > 1 && (
                      <li className="page-item">
                        <a
                          className={`page-link ${
                            currentPage === total_pages ? "disabled" : ""
                          }`}
                          aria-label="Next"
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          <i className="fa fa-angle-double-right" />
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* </LayoutOne> */}
    </Fragment>
  )
}

export default ShopGridFullId
