import PropTypes from "prop-types"
import { Fragment, useState } from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import clsx from "clsx"
import { getDiscountPrice } from "../../helpers/product"
import Rating from "./sub-components/ProductRating"
import ProductModal from "./ProductModal"
import { addToCart } from "../../store/slices/cart-slice"
// import { addToWishlist } from "../../store/slices/wishlist-slice";
import { addToCompare } from "../../store/slices/compare-slice"
import axios from "axios"
import special from "../../../src/assets/special (1).png"
import usedByAdmin from "../../../src/assets/special (2).png"
import { isMobile } from "react-device-detect"

const ProductGridListSingle = ({
  product,
  currency,
  cartItem,
  wishlistItem,
  compareItem,
  spaceBottomClass,
  reload,
  setReload,
  setProducts,
  products,
  currentIndex,
}) => {
  const [modalShow, setModalShow] = useState(false)
  // console.log(product);
  const TOKEN = sessionStorage.getItem("token")

  const deleteFromWishlist = (id) => {
    axios
      .patch(
        `https://autohouse.onrender.com/customer/update_customer_remove_wichlist`,
        {
          wishlist: id,
        },
        {
          headers: {
            token: TOKEN,
          },
        }
      )
      .then((res) => {
        console.log(res)
        let t = [...products]
        t[currentIndex].fav = false
        setProducts([...t])
        setReload(!reload)
        // window.location.reload()
        // setReload(!reload)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const discountedPrice = getDiscountPrice(product.price, product.discount)
  const finalProductPrice = +(product.price * currency.currencyRate).toFixed(2)
  const finalDiscountedPrice = +(
    discountedPrice * currency.currencyRate
  ).toFixed(2)
  const dispatch = useDispatch()

  const addToWishlist = async (id) => {
    try {
      const response = await axios
        .patch(
          "https://autohouse.onrender.com/customer/update_customer_wichlist",
          { wishlist: id },
          {
            headers: {
              token: `${TOKEN}`,
            },
          }
        )
        .then((response) => {
          let t = [...products]
          t[currentIndex].fav = true
          setProducts([...t])
          setReload(!reload)
        })
        .catch((e) => {
          console.log("Error fetching data:", e)
        })
      // setFilteredData(response.data.result);
      // console.log(response.data)
    } catch (error) {
      console.log("Error fetching data:", error)
    }
  }
  return (
    <Fragment>
      <div className={clsx("product-wrap", spaceBottomClass)}>
        <div className="product-img">
          <Link to={process.env.PUBLIC_URL + "/product/" + product.id}>
            <img
              className="default-img"
              style={{ height: "25vh" }}
              src={
                process.env.PUBLIC_URL + product.images &&
                product.images[0].image
              }
              alt=""
            />
            {product.images.length > 1 ? (
              <img
                className="hover-img"
                style={{ height: "25vh" }}
                src={process.env.PUBLIC_URL + product.images[1].image}
                alt=""
              />
            ) : (
              ""
            )}
          </Link>

          <div className="product-img-badges">
            {product.specal ? (
              <img
                src={special}
                alt=""
                style={{ height: "6vh", width: isMobile ? "12vw" : "4vw" }}
              />
            ) : (
              ""
            )}

            {product.old_by_admin && product.specal ? (
              ""
            ) : product.old_by_admin ? (
              <img
                src={usedByAdmin}
                alt=""
                style={{ height: "6vh", width: isMobile ? "12vw" : "4vw" }}
              />
            ) : (
              ""
            )}
          </div>

          <div className="product-action">
            {sessionStorage.getItem("loggedIn") === "true" ? (
              <div className="pro-same-action pro-wishlist">
                <button
                  className={product.fav ? "active" : ""}
                  title={product ? "Added to wishlist" : "Add to wishlist"}
                  onClick={() => {
                    console.log(product)
                    if (product.fav) {
                      deleteFromWishlist(product.id)
                    } else addToWishlist(product.id)
                  }}
                >
                  <i className="pe-7s-like" />
                </button>
              </div>
            ) : (
              <div className="pro-same-action pro-wishlist">
                <Link to={process.env.PUBLIC_URL + "/login-register"}>
                  {" "}
                  <i className="fa fa-heart-o" />
                </Link>
              </div>
            )}
            {sessionStorage.getItem("loggedIn") === "true" ? (
              <div className="pro-same-action pro-cart">
                {/* {product.affiliateLink ? ( */}
                {/* <a
                    href={product.affiliateLink}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {" "}
                    Buy now{" "}
                  </a> */}
                {/* ) : */}
                {/* product.variation && product.variation.length >= 1 ? ( */}
                <Link to={process.env.PUBLIC_URL + "/product/" + product.id}>
                  Show car
                </Link>
              </div>
            ) : (
              <div className="pro-same-action pro-cart">
                <Link to={process.env.PUBLIC_URL + "/product/" + product.id}>
                  Show car
                </Link>
              </div>
            )}
            <div className="pro-same-action pro-quickview">
              <button onClick={() => setModalShow(true)} title="Quick View">
                <i className="pe-7s-look" />
              </button>
            </div>
          </div>
        </div>
        <div className="product-content text-center">
          <h3 style={{ display: "flex", justifyContent: "space-between" }}>
            <Link to={process.env.PUBLIC_URL + "/product/" + product.id}>
              {product.make + " " + product.model}
            </Link>

            <div className="product-price">
              {discountedPrice !== null ? (
                <Fragment>
                  <span>{finalDiscountedPrice.toLocaleString() + " EGP"}</span>{" "}
                  <span className="old">
                    {finalProductPrice.toLocaleString() + " EGP"}
                  </span>
                </Fragment>
              ) : (
                <span style={{ color: "red" }}>
                  {finalProductPrice === 0 ? (
                    "Negotiable"
                  ) : (
                    <>
                      {finalProductPrice.toLocaleString()}
                      <span>EGP</span>
                    </>
                  )}{" "}
                </span>
              )}
            </div>
            <div>
              <p>{product.trim}</p>
            </div>
          </h3>
          <div
            className="pro-details-rating-wrap"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              className="pro-details-rating"
              style={{ paddingRight: "10px" }}
            >
              <i
                className="fa-solid fa-gauge-high"
                style={{ color: "red" }}
              ></i>{" "}
              {product.kilometers.toLocaleString()} KM
            </div>
            <div
              className="pro-details-rating"
              style={{ paddingRight: "10px" }}
            >
              <i
                className="fa-regular fa-calendar"
                style={{ color: "red" }}
              ></i>{" "}
              {product.year}
            </div>
            <div
              className="pro-details-rating"
              style={{ paddingRight: "10px" }}
            >
              <i className="fa-solid fa-gears" style={{ color: "red" }}></i>{" "}
              {product.transmission}
            </div>
          </div>
        </div>
      </div>
      <div className="shop-list-wrap mb-30">
        <div className="row">
          <div className="col-xl-4 col-md-5 col-sm-6">
            <div className="product-list-image-wrap">
              <div className="product-img">
                <Link to={process.env.PUBLIC_URL + "/product/" + product.id}>
                  <img
                    className="default-img img-fluid"
                    src={
                      process.env.PUBLIC_URL + product.images &&
                      product.images[0].image
                    }
                    alt=""
                    style={{ height: "35vh" }}
                  />
                  {product.images.length > 1 ? (
                    <img
                      className="hover-img img-fluid"
                      src={process.env.PUBLIC_URL + product.images[1].image}
                      alt=""
                      style={{ height: "35vh" }}
                    />
                  ) : (
                    ""
                  )}
                </Link>
                {/* {product.old_by_admin || product.specal ? ( */}
                <div className="product-img-badges">
                  {product.specal ? (
                    <img src={special} style={{ height: "5vh" }} />
                  ) : (
                    // "vrvr"

                    ""
                  )}
                  {product.old_by_admin && product.specal ? (
                    ""
                  ) : product.old_by_admin ? (
                    <img
                      src={usedByAdmin}
                      style={{
                        height: "6vh",
                        width: isMobile ? "12vw" : "4vw",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                {/* ) : (
                    ""
                  )} */}
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-md-7 col-sm-6">
            <div className="shop-list-content">
              <h3>
                <Link to={process.env.PUBLIC_URL + "/product/" + product.id}>
                  {product.make + " " + product.model}
                </Link>
              </h3>
              <div className="product-list-price">
                {discountedPrice !== null ? (
                  <Fragment>
                    <span>{"EGP" + finalDiscountedPrice}</span>{" "}
                    <span className="old">
                      {"EGP" + finalProductPrice.toLocaleString()}
                    </span>
                  </Fragment>
                ) : (
                  <span>{finalProductPrice.toLocaleString() + " EGP"} </span>
                )}
              </div>
              {product.rating && product.rating > 0 ? (
                <div className="rating-review">
                  <div className="product-list-rating">
                    <Rating ratingValue={product.rating} />
                  </div>
                </div>
              ) : (
                ""
              )}
              {product.shortDescription ? (
                <p>{product.shortDescription}</p>
              ) : (
                ""
              )}

              <div className="shop-list-actions d-flex align-items-center">
                {sessionStorage.getItem("loggedIn") === "true" ? (
                  <div className="shop-list-btn btn-hover">
                    {/* {product.affiliateLink ? (
                      <a
                        href={product.affiliateLink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {" "}
                        Buy now{" "}
                      </a>
                    ) : product.variation && product.variation.length >= 1 ? (
                      <Link
                        to={`${process.env.PUBLIC_URL}/product/${product.id}`}
                      >
                        Select Option
                      </Link>
                    ) : product.stock && product.stock > 0 ? (
                      <button
                        onClick={() => dispatch(addToCart(product))}
                        className={
                          cartItem !== undefined && cartItem.quantity > 0
                            ? "active"
                            : ""
                        }
                        disabled={
                          cartItem !== undefined && cartItem.quantity > 0
                        }
                        title={
                          cartItem !== undefined
                            ? "Added to cart"
                            : "Add to cart"
                        }
                      >
                        {" "}
                        <i className="pe-7s-cart"></i>{" "}
                        {cartItem !== undefined && cartItem.quantity > 0
                          ? "Added"
                          : "Add to cart"}
                      </button>
                    ) : ( */}

                    <Link
                      to={process.env.PUBLIC_URL + "/product/" + product.id}
                    >
                      Show car
                    </Link>
                    {/* )} */}
                  </div>
                ) : (
                  <div className="shop-list-btn btn-hover">
                    <Link
                      to={process.env.PUBLIC_URL + "/product/" + product.id}
                    >
                      Show car
                    </Link>
                  </div>
                )}
                {sessionStorage.getItem("loggedIn") === "true" ? (
                  <div className="shop-list-wishlist ml-10">
                    <button
                      className={wishlistItem !== undefined ? "active" : ""}
                      // disabled={wishlistItem !== undefined}
                      title={
                        wishlistItem !== undefined
                          ? "Added to wishlist"
                          : "Add to wishlist"
                      }
                      onClick={() => dispatch(addToWishlist(product.id))}
                    >
                      <i className="pe-7s-like" />
                    </button>
                  </div>
                ) : (
                  <div className="shop-list-wishlist ml-10">
                    <Link to={process.env.PUBLIC_URL + "/login-register"}>
                      <i className="pe-7s-like" />
                    </Link>
                  </div>
                )}
                {/* <div className="shop-list-compare ml-10">
                    <button
                      className={compareItem !== undefined ? "active" : ""}
                      disabled={compareItem !== undefined}
                      title={
                        compareItem !== undefined
                          ? "Added to compare"
                          : "Add to compare"
                      }
                      onClick={() => dispatch(addToCompare(product))}
                    >
                      <i className="pe-7s-shuffle" />
                    </button>
                  </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* product modal */}
      <ProductModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        product={product}
        currency={currency}
        discountedPrice={discountedPrice}
        finalProductPrice={finalProductPrice}
        finalDiscountedPrice={finalDiscountedPrice}
        wishlistItem={wishlistItem}
        compareItem={compareItem}
      />
    </Fragment>
  )
}

ProductGridListSingle.propTypes = {
  setReload: PropTypes.bool,
  reload: PropTypes.bool,
  currentIndex: PropTypes.any,
  setProducts: PropTypes.array,
  products: PropTypes.array,
  cartItem: PropTypes.shape({}),
  compareItem: PropTypes.shape({}),
  currency: PropTypes.shape({}),
  product: PropTypes.shape({}),
  spaceBottomClass: PropTypes.string,
  wishlistItem: PropTypes.shape({}),
}

export default ProductGridListSingle
