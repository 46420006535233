import PropTypes from "prop-types"
import clsx from "clsx"
import Swiper, { SwiperSlide } from "../../components/swiper"
import BrandLogoOneSingle from "../../components/brand-logo/BrandLogoOneSingle"
// import brandLogoData from "../../data/brand-logos/brand-logo-one.json";
import React, { Fragment, useEffect, useState } from "react"
import axios from "axios"

const settings = {
  // loop: true,
  autoplay: true,
  grabCursor: true,
  breakpoints: {
    320: {
      slidesPerView: 2,
    },
    640: {
      slidesPerView: 3,
    },
    1024: {
      slidesPerView: 7,
    },
    768: {
      slidesPerView: 4,
    },
  },
}

const BrandLogoSliderOneAr = ({
  spaceBottomClass,
  spaceTopClass,
  bgColorClass,
}) => {
  const [brandLogoData, setBrandLogoData] = useState([])

  useEffect(() => {
    axios
      .get("https://autohouse.onrender.com/brand/show_all_brands_user", {
        headers: {
          lang: "AR",
        },
      })
      .then((response) => {
        console.log(response)
        setBrandLogoData(response.data.result)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])
  return (
    <div
      className={clsx(
        "testimonial-area",
        bgColorClass,
        spaceBottomClass,
        spaceTopClass
      )}
    >
      <div className="container-fluid">
        <div className="brand-logo-active testimonial-active nav-style-1 nav-testi-style">
          {brandLogoData && (
            <Swiper options={settings}>
              {brandLogoData.map((single, key) => (
                <SwiperSlide key={key}>
                  <BrandLogoOneSingle data={single} spaceBottomClass="mb-30" />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      </div>
    </div>
  )
}

BrandLogoSliderOneAr.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
}

export default BrandLogoSliderOneAr
