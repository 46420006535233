import { Fragment, useState } from "react"
import PropTypes from "prop-types"
import { EffectFade, Thumbs } from "swiper"
import { Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import Rating from "./sub-components/ProductRating"
import Swiper, { SwiperSlide } from "../../components/swiper"
import { getProductCartQuantity } from "../../helpers/product"
import { addToCart } from "../../store/slices/cart-slice"
// import { addToWishlist } from "../../store/slices/wishlist-slice";
import { addToCompare } from "../../store/slices/compare-slice"
import axios from "axios"
import Swal from "sweetalert2"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { StyledEngineProvider } from "@mui/material/styles"
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker"
import { Alert } from "react-bootstrap"

import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import withReactContent from "sweetalert2-react-content"
import { isMobile } from "react-device-detect"
function ProductModalAr({
  product,
  currency,
  discountedPrice,
  finalProductPrice,
  finalDiscountedPrice,
  show,
  onHide,
  wishlistItem,
  compareItem,
}) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const dispatch = useDispatch()
  const { cartItems } = useSelector((state) => state.cart)
  const TOKEN = sessionStorage.getItem("token")
  const MySwal = withReactContent(Swal)

  const [selectedProductColor, setSelectedProductColor] = useState(
    product.variation ? product.variation[0].color : ""
  )
  const [selectedProductSize, setSelectedProductSize] = useState(
    product.variation ? product.variation[0].size[0].name : ""
  )
  const [productStock, setProductStock] = useState(
    product.variation ? product.variation[0].size[0].stock : product.stock
  )
  const [quantityCount, setQuantityCount] = useState(1)
  const productCartQty = getProductCartQuantity(
    cartItems,
    product,
    selectedProductColor,
    selectedProductSize
  )

  const gallerySwiperParams = {
    spaceBetween: 10,
    loop: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    thumbs: { swiper: thumbsSwiper },
    modules: [EffectFade, Thumbs],
  }

  const thumbnailSwiperParams = {
    onSwiper: setThumbsSwiper,
    spaceBetween: 10,
    slidesPerView: 4,
    touchRatio: 0.2,
    freeMode: true,
    loop: true,
    slideToClickedSlide: true,
    navigation: true,
  }
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const onCloseModal = () => {
    setThumbsSwiper(null)
    onHide()
  }

  const addToWishlist = async (id) => {
    try {
      const response = await axios
        .patch(
          "https://autohouse.onrender.com/customer/update_customer_wichlist",
          { wishlist: id },
          {
            headers: {
              token: `${TOKEN}`,
            },
          }
        )
        .then((response) =>
          MySwal.fire(
            "Success",
            "Added to wishlist. Continue shopping!",
            "success"
          ).then(() => {
            window.location.reload()
          })
        )
        .catch((e) => {
          console.log("Error fetching data:", e)
          MySwal.fire("Oops!", e.response.data.comment, "error")
        })
    } catch (error) {
      console.log("Error fetching data:", error)
    }
  }

  const [selectedDate, setSelectedDate] = useState(dayjs("2024-04-17"))
  const [selectedTime, setSelectedTime] = useState(dayjs("15:30"))
  const addBook = async () => {
    try {
      console.log(selectedDate)
      const requestData = {
        attend_time: selectedTime,
        attend_date: dayjs(selectedDate).format("YYYY-MM-DD"),
        car_id: product.id,
      }

      const headers = {
        token: ` ${TOKEN}`,
        // 'Content-Type': 'application/json', // You can add this line if needed
      }

      const response = await axios.post(
        "https://autohouse.onrender.com/book/add_book",
        requestData,
        {
          headers: {
            token: `${TOKEN}`,
          },
        }
      )
      setSuccess("Appointment booked successfully.")

      // Handle success, you can access response.data for the server's response
      console.log("Success:", response.data)
    } catch (error) {
      setError(error.response.data.comment)

      // Handle error, you can access error.response.data for the server's error response
      console.error("Error:", error.response)
    }
  }
  return (
    <Modal
      show={show}
      onHide={onCloseModal}
      className="product-quickview-modal-wrapper"
    >
      <Modal.Header closeButton></Modal.Header>
      {error && <Alert className="alert-danger">{error}</Alert>}
      {success && <Alert className="alert-success">{success}</Alert>}

      <div className="modal-body" dir="rtl" lang="ar">
        <div className="row">
          <div className="col-md-5 col-sm-12 col-xs-12">
            <div className="product-large-image-wrapper">
              <Swiper options={gallerySwiperParams}>
                {product.images &&
                  product.images.map((img, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <div className="single-image">
                          <img
                            src={process.env.PUBLIC_URL + img.image}
                            className="img-fluid"
                            alt="Product"
                            style={{ height: isMobile ? "35vh" : "40vh" }}
                          />
                        </div>
                      </SwiperSlide>
                    )
                  })}
              </Swiper>
            </div>
            <div className="product-small-image-wrapper mt-15">
              <Swiper options={thumbnailSwiperParams}>
                {product.images &&
                  product.images.map((img, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <div className="single-image">
                          <img
                            style={{ height: "8vh" }}
                            src={process.env.PUBLIC_URL + img.image}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                      </SwiperSlide>
                    )
                  })}
              </Swiper>
            </div>
          </div>
          <div className="col-md-7 col-sm-12 col-xs-12">
            <div className="product-details-content quickview-content">
              <h2>{product.make + " " + product.model}</h2>
              <div
                className="product-details-price"
                style={{ justifyContent: "space-between" }}
              >
                <Fragment>
                  <span>
                    {finalProductPrice === 0 ? (
                      "Negotiable"
                    ) : (
                      <>
                        {finalProductPrice.toLocaleString()}{" "}
                        <span style={{ color: "gray", fontSize: "14px" }}>
                          EGP
                        </span>
                      </>
                    )}
                  </span>{" "}
                </Fragment>
                {sessionStorage.getItem("loggedIn") === "true" ? (
                  <button
                    className={wishlistItem !== undefined ? "active" : ""}
                    disabled={wishlistItem !== undefined}
                    title={
                      wishlistItem !== undefined
                        ? "Added to wishlist"
                        : "Add to wishlist"
                    }
                    onClick={() => dispatch(addToWishlist(product.id))}
                  >
                    <i className="pe-7s-like fs-5" />
                  </button>
                ) : (
                  <div className="pro-wishlist-2" style={{ marginRight: "2%" }}>
                    <button
                      className={wishlistItem !== undefined ? "active" : ""}
                      // disabled={wishlistItem !== undefined}
                    >
                      <Link to={process.env.PUBLIC_URL + "/login-register"}>
                        {" "}
                        <i className="fa fa-heart-o fs-5" />
                      </Link>
                    </button>
                  </div>
                )}
              </div>
              {/* <h1>I Love You Kirol</h1> */}

              {product.rating && product.rating > 0 ? (
                <div className="pro-details-rating-wrap">
                  <div className="pro-details-rating">
                    <Rating ratingValue={product.rating} />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="pro-details-list">
                <p>{product.description ? product.description : ""}</p>
              </div>

              {/* {product.variation ? ( */}
              {isMobile ? (
                <div
                  className="pro-details-size-color "
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="row" style={{ flexDirection: "column" }}>
                    <div className="col">
                      <div
                        className="pro-details-color-wrap"
                        style={{
                          width: "100%",
                          margin: "7%",
                          border: "1px solid #d0d5dd",
                          height: "12vh",
                          padding: "12px",
                          borderRadius: "16px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                        }}
                      >
                        <span style={{ textAlign: "center" }}>
                          <i class="fa-regular fa-newspaper fs-5"></i>
                        </span>

                        <div
                          style={{ textAlign: "center", color: "red" }}
                          className="pro-details-color-content"
                        >
                          {product.used === false
                            ? "حديثة"
                            : product.old_by_admin === true
                            ? "مستعملة ب A.H"
                            : "مستعملة"}
                        </div>
                        <span style={{ textAlign: "center", fontSize: "12px" }}>
                          حالة السيارة
                        </span>
                      </div>
                    </div>
                    <div className="col">
                      <div
                        className="pro-details-size"
                        style={{
                          width: "100%",
                          margin: "7%",
                          border: "1px solid #d0d5dd",
                          height: "12vh",
                          padding: "12px",
                          borderRadius: "16px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                        }}
                      >
                        <span style={{ textAlign: "center" }}>
                          <i class="fa-regular fa-id-card fs-5"></i>
                        </span>

                        <div
                          style={{
                            textAlign: "center",
                            color: "red",
                            fontSize: "11px",
                          }}
                          className="pro-details-size-content"
                        >
                          {/* {product.variation &&
                             product.variation.map(single => {
                               return single.color === selectedProductColor
                                 ? single.size.map((singleSize, key) => { */}
                          {/* return ( */}

                          {product.license_valid_to
                            ? product.license_valid_to
                            : "غير مرخصة"}

                          {/* );
                                   })
                             //     : "";
                             // })} */}
                        </div>
                        <span style={{ textAlign: "center", fontSize: "12px" }}>
                          تاريخ الصلاحية
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ flexDirection: "column" }}>
                    <div className="col">
                      <div
                        className="pro-details-color-wrap"
                        style={{
                          width: "100%",
                          margin: "7%",
                          border: "1px solid #d0d5dd",
                          height: "12vh",
                          padding: "12px",
                          borderRadius: "16px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                        }}
                      >
                        <span style={{ textAlign: "center" }}>
                          <img
                            src={
                              product.body_style ? product.body_style.image : ""
                            }
                            style={{ height: "3vh" }}
                          />
                        </span>

                        <div
                          style={{ textAlign: "center", color: "red" }}
                          className="pro-details-color-content"
                        >
                          {product.body_style ? product.body_style.name : ""}
                        </div>
                        <span style={{ textAlign: "center", fontSize: "12px" }}>
                          نوع الهيكل{" "}
                        </span>
                      </div>
                    </div>
                    <div className="col">
                      <div
                        className="pro-details-color-wrap"
                        style={{
                          width: "100%",
                          margin: "7%",
                          border: "1px solid #d0d5dd",
                          height: "12vh",
                          padding: "12px",
                          borderRadius: "16px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                        }}
                      >
                        <span style={{ textAlign: "center" }}>
                          <i class="fa-solid fa-car fs-5"></i>
                        </span>

                        <div
                          style={{ textAlign: "center", color: "red" }}
                          className="pro-details-color-content"
                        >
                          {product.engine_capacity}
                        </div>
                        <span style={{ textAlign: "center", fontSize: "12px" }}>
                          سعة المحرك
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ flexDirection: "column" }}>
                    <div className="col">
                      <div
                        className="pro-details-color-wrap"
                        style={{
                          width: "100%",
                          margin: "7%",
                          border: "1px solid #d0d5dd",
                          height: "12vh",
                          padding: "12px",
                          borderRadius: "16px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                        }}
                      >
                        <span style={{ textAlign: "center" }}>
                          <i class="fa-solid fa-gears fs-5"></i>
                        </span>

                        <div
                          style={{ textAlign: "center", color: "red" }}
                          className="pro-details-color-content"
                        >
                          {product.transmission}
                        </div>
                        <span style={{ textAlign: "center", fontSize: "12px" }}>
                          ناقل الحركة
                        </span>
                      </div>
                    </div>

                    <div className="col">
                      <div
                        className="pro-details-color-wrap"
                        style={{
                          width: "100%",
                          margin: "7%",
                          border: "1px solid #d0d5dd",
                          height: "12vh",
                          padding: "12px",
                          borderRadius: "16px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                        }}
                      >
                        <span style={{ textAlign: "center" }}>
                          <i class="fa-solid fa-rainbow fs-5"></i>
                        </span>

                        <div
                          style={{ textAlign: "center", color: "red" }}
                          className="pro-details-color-content"
                        >
                          {product.color}
                        </div>
                        <span style={{ textAlign: "center", fontSize: "12px" }}>
                          اللون
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="pro-details-size-color "
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="row" style={{ flexDirection: "column" }}>
                    <div className="col">
                      <div
                        className="pro-details-color-wrap"
                        style={{
                          width: "100%",
                          margin: "7%",
                          border: "1px solid #d0d5dd",
                          height: "12vh",
                          padding: "12px",
                          borderRadius: "16px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                        }}
                      >
                        <span style={{ textAlign: "center" }}>
                          <i class="fa-regular fa-newspaper fs-5"></i>
                        </span>

                        <div
                          style={{ textAlign: "center", color: "red" }}
                          className="pro-details-color-content"
                        >
                          {product.used === false
                            ? "حديثة"
                            : product.old_by_admin === true
                            ? "مستعملة ب A.H"
                            : "مستعملة"}
                        </div>
                        <span style={{ textAlign: "center", fontSize: "12px" }}>
                          حالة السيارة
                        </span>
                      </div>
                    </div>
                    <div className="col">
                      <div
                        className="pro-details-size"
                        style={{
                          width: "100%",
                          margin: "7%",
                          border: "1px solid #d0d5dd",
                          height: "12vh",
                          padding: "12px",
                          borderRadius: "16px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                        }}
                      >
                        <span style={{ textAlign: "center" }}>
                          <i class="fa-regular fa-id-card fs-5"></i>
                        </span>

                        <div
                          style={{
                            textAlign: "center",
                            color: "red",
                            fontSize: "11px",
                          }}
                          className="pro-details-size-content"
                        >
                          {/* {product.variation &&
                            product.variation.map(single => {
                              return single.color === selectedProductColor
                                ? single.size.map((singleSize, key) => { */}
                          {/* return ( */}

                          {product.license_valid_to
                            ? product.license_valid_to
                            : "غير مرخصة"}

                          {/* );
                                  })
                            //     : "";
                            // })} */}
                        </div>
                        <span style={{ textAlign: "center", fontSize: "12px" }}>
                          تاريخ الصلاحية
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ flexDirection: "column" }}>
                    <div className="col">
                      <div
                        className="pro-details-color-wrap"
                        style={{
                          width: "100%",
                          margin: "7%",
                          border: "1px solid #d0d5dd",
                          height: "12vh",
                          padding: "12px",
                          borderRadius: "16px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                        }}
                      >
                        <span style={{ textAlign: "center" }}>
                          <img
                            src={
                              product.body_style ? product.body_style.image : ""
                            }
                            style={{ height: "3vh" }}
                          />
                        </span>

                        <div
                          style={{ textAlign: "center", color: "red" }}
                          className="pro-details-color-content"
                        >
                          {product.body_style ? product.body_style.name : ""}
                        </div>
                        <span style={{ textAlign: "center", fontSize: "12px" }}>
                          نوع الهيكل
                        </span>
                      </div>
                    </div>
                    <div className="col">
                      <div
                        className="pro-details-color-wrap"
                        style={{
                          width: "100%",
                          margin: "7%",
                          border: "1px solid #d0d5dd",
                          height: "12vh",
                          padding: "12px",
                          borderRadius: "16px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                        }}
                      >
                        <span style={{ textAlign: "center" }}>
                          <i class="fa-solid fa-car fs-5"></i>
                        </span>

                        <div
                          style={{ textAlign: "center", color: "red" }}
                          className="pro-details-color-content"
                        >
                          {product.engine_capacity}
                        </div>
                        <span style={{ textAlign: "center", fontSize: "12px" }}>
                          سعة المحرك
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ flexDirection: "column" }}>
                    <div className="col">
                      <div
                        className="pro-details-color-wrap"
                        style={{
                          width: "100%",
                          margin: "7%",
                          border: "1px solid #d0d5dd",
                          height: "12vh",
                          padding: "12px",
                          borderRadius: "16px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                        }}
                      >
                        <span style={{ textAlign: "center" }}>
                          <i class="fa-solid fa-gears fs-5"></i>
                        </span>

                        <div
                          style={{ textAlign: "center", color: "red" }}
                          className="pro-details-color-content"
                        >
                          {product.transmission}
                        </div>
                        <span style={{ textAlign: "center", fontSize: "12px" }}>
                          ناقل الحركة
                        </span>
                      </div>
                    </div>

                    <div className="col">
                      <div
                        className="pro-details-color-wrap"
                        style={{
                          width: "100%",
                          margin: "7%",
                          border: "1px solid #d0d5dd",
                          height: "12vh",
                          padding: "12px",
                          borderRadius: "16px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                        }}
                      >
                        <span style={{ textAlign: "center" }}>
                          <i class="fa-solid fa-rainbow fs-5"></i>
                        </span>

                        <div
                          style={{ textAlign: "center", color: "red" }}
                          className="pro-details-color-content"
                        >
                          {product.color}
                        </div>
                        <span style={{ textAlign: "center", fontSize: "12px" }}>
                          اللون
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div style={{ borderTop: "1px solid #e5e5e5", marginTop: "1%" }}>
                {product.branch ? (
                  <>
                    <i
                      class="fa-solid fa-location-dot fs-5"
                      style={{ marginTop: "3%", color: "red" }}
                    ></i>{" "}
                    {product.branch}
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="row">
                {sessionStorage.getItem("loggedIn") === "true" ? (
                  <div>
                    <div className="row">
                      <div className="col">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DemoItem label="اختر تاريخ">
                              <DatePicker
                                minDate={dayjs(new Date())}
                                value={selectedDate && selectedDate}
                                onChange={(newDate) => {
                                  setSelectedDate(newDate)
                                }}
                              />
                            </DemoItem>
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className="col">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={[
                              "TimePicker",
                              "MobileTimePicker",
                              "DesktopTimePicker",
                              "StaticTimePicker",
                            ]}
                          >
                            <DemoItem label="اختر ساعة">
                              <MobileTimePicker
                                value={selectedTime}
                                onChange={(newTime) => {
                                  console.log(newTime.$H + ":" + newTime.$m)
                                  setSelectedTime(newTime.$H + ":" + newTime.$m)
                                }}
                                ampm={false}
                              />
                            </DemoItem>
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </div>
                    <button
                      onClick={addBook}
                      className="btn-hover"
                      style={{
                        backgroundColor: "red",
                        color: "#fff",
                        padding: "2%",
                        marginLeft: "2%",
                        marginTop: "2%",
                        borderRadius: "10px",
                      }}
                    >
                      احجز ميعاد
                    </button>
                  </div>
                ) : (
                  // <Link to={`${process.env.PUBLIC_URL}/calendar/${product.id}`}>
                  // </Link>

                  <Link
                    to={process.env.PUBLIC_URL + "/login-register"}
                    className="btn-hover"
                    style={{
                      backgroundColor: "red",
                      color: "#fff",
                      padding: "2%",
                      marginTop: "2%",
                      marginLeft: "4%",
                      borderRadius: "10px",
                      width: "auto",
                    }}
                  >
                    احجز ميعاد
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

ProductModalAr.propTypes = {
  currency: PropTypes.shape({}),
  discountedprice: PropTypes.number,
  finaldiscountedprice: PropTypes.number,
  finalproductprice: PropTypes.number,
  onHide: PropTypes.func,
  product: PropTypes.shape({}),
  show: PropTypes.bool,
  wishlistItem: PropTypes.shape({}),
  compareItem: PropTypes.shape({}),
}

export default ProductModalAr
