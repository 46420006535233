import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { EffectFade, Thumbs } from "swiper";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Rating from "./sub-components/ProductRating";
import Swiper, { SwiperSlide } from "../../components/swiper";
import { getProductCartQuantity } from "../../helpers/product";
import { addToCart } from "../../store/slices/cart-slice";
import { addToWishlist } from "../../store/slices/wishlist-slice";
import { addToCompare } from "../../store/slices/compare-slice";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

function CarModal({
  product,
  currency,
  discountedPrice,
  finalProductPrice,
  finalDiscountedPrice,
  show,
  onHide,
  wishlistItem,
  compareItem,
}) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  console.log(product);
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.cart);

  const [quantityCount, setQuantityCount] = useState(1);

  const gallerySwiperParams = {
    spaceBetween: 10,
    loop: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    thumbs: { swiper: thumbsSwiper },
    modules: [EffectFade, Thumbs],
  };

  const thumbnailSwiperParams = {
    onSwiper: setThumbsSwiper,
    spaceBetween: 10,
    slidesPerView: 4,
    touchRatio: 0.2,
    freeMode: true,
    loop: true,
    slideToClickedSlide: true,
    navigation: true,
  };

  const onCloseModal = () => {
    setThumbsSwiper(null);
    onHide();
  };

  return (

    <div className="popup"> 
          <div className="cardSold">
          <p onClick={onCloseModal} style={{    display: "flex",    justifyContent: "end",width: "100%",margin: "0",cursor:'pointer'}}><i class="fa-solid fa-xmark" style={{color:'red'}}> </i></p>

          <div className="col-md-12 col-sm-12 col-xs-12" > <div className="single-image" style={{display:'flex',justifyContent:'center'}}>
                          <img
                            src={process.env.PUBLIC_URL + product.image}
                            className="img-fluid"
                            alt="Product"
                            style={{ height: "15vh" }}
                          />
                        </div>
            <div className="product-details-content quickview-content">
              <h2 className="text-center pt-3">{product.name}</h2>
              <div
                className=" text-center"
                style={{ justifyContent: "center" }}
              >
                <Fragment className="text-center pt-3">
                  <a href={`tel:${product.phone}`} className="text-center" title="Phone number">
                  <i className="fa-solid fa-phone " style={{color:'red'}}></i>      Call the car owner
                  </a>{" "}
                </Fragment>
              </div>

            </div>
          </div>
          </div>
        </div>
 
     
  );
}

CarModal.propTypes = {
  currency: PropTypes.shape({}),
  discountedprice: PropTypes.number,
  finaldiscountedprice: PropTypes.number,
  finalproductprice: PropTypes.number,
  onHide: PropTypes.func,
  product: PropTypes.shape({}),
  show: PropTypes.bool,
  wishlistItem: PropTypes.shape({}),
  compareItem: PropTypes.shape({}),
};

export default CarModal;
