import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect"

const Logo = ({ imageUrl, logoClass }) => {
  return (
    <div className={clsx(logoClass)} style={{display:'flex',justifyContent:'center',marginTop:isMobile?"0px": "15px",    marginBottom:isMobile?"0px": "15px"}}>
      <Link to={process.env.PUBLIC_URL + "/"} style={{display:'flex',justifyContent:'center',width:isMobile?'50%':'25%'}}>
        <img alt="" src={process.env.PUBLIC_URL + imageUrl} style={{width:'100%'}} />
      </Link>
    </div>
  );
};

Logo.propTypes = {
  imageUrl: PropTypes.string,
  logoClass: PropTypes.string
};

export default Logo;
