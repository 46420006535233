import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import SEO from "../../components/seo";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import TermsAndConditions from "../../components/terms";

const Terms = () => {
  let { pathname } = useLocation();

  return (
    <Fragment>
      <SEO
        titleTemplate="Terms & Conditions"
        description="Terms page of flone react minimalist eCommerce template."
      />
      {/* <LayoutOne headerTop="visible"> */}
      {/* breadcrumb */}
      <Breadcrumb
        pages={[
          { label: "Home", path: process.env.PUBLIC_URL + "/" },
          {
            label: "Terms & Conditions",
            path: process.env.PUBLIC_URL + pathname,
          },
        ]}
      />

      <TermsAndConditions />
    </Fragment>
  );
};

export default Terms;
