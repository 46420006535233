import { Fragment } from "react"
import { useLocation } from "react-router-dom"
import SEO from "../../components/seo"
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb"
import axios from "axios"
import React, { useState, useEffect } from "react"

const DealerDetailsStandard = () => {
  let { pathname } = useLocation()
  const [filteredData, setFilteredData] = useState([])
  const [about, setAbout] = useState("")
  const [image, setImage] = useState("")
  const [name, setName] = useState("")
  const [number, setNumber] = useState("")
  const match = pathname.match(/^\/dealer-details-standard\/(\d+)$/)

  const blogId = match[1]
  console.log("Extracted ID:", blogId)

  console.log(match)
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://autohouse.onrender.com/client/show_client_branchs_user/${blogId}`
      )
      setFilteredData(response.data.result)
      setAbout(response.data.about)
      setName(response.data.name)
      setNumber(response.data.number)

      setImage(response.data.image)

      console.log(response.data)
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    fetchData() // Fetch data when the component mounts
  }, [])
  return (
    <Fragment>
      <SEO
        titleTemplate="Auto House"
        description="Blog Post of flone react minimalist eCommerce template."
      />
      {/* <LayoutOne headerTop="visible"> */}
      {/* breadcrumb */}
      <Breadcrumb
        pages={[
          { label: "Home", path: process.env.PUBLIC_URL + "/" },
          {
            label: name && name,
            path: process.env.PUBLIC_URL + pathname,
          },
        ]}
      />
      <div className="blog-area  pb-100">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className="blog-details-wrapper ml-20">
                <div className="blog-details-top">
                  <div className="blog-details-img">
                    <img
                      alt=""
                      src={process.env.PUBLIC_URL + image}
                      style={{ height: "40vh" }}
                    />
                  </div>
                  <h4 className="text-center" style={{ paddingTop: "2%" }}>
                    {name}
                  </h4>
                  <div className="blog-details-content">
                    <p style={{ textAlign: "justify" }}>
                      {about && about
                        ? about.split(/\r?\n/).map((part, index) => (
                            <span key={index}>
                              {part}
                              <br />
                            </span>
                          ))
                        : console.error(
                            "filteredData or filteredData.body is undefined."
                          )}
                    </p>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: "18px",

                          border: "1px  solid red",
                          borderRadius: "20px",
                          padding: "7px",
                        }}
                      >
                        <a href={`tel:${number}`}>
                          <i
                            class="fa-solid fa-phone fs-5"
                            style={{ color: "red" }}
                          ></i>{" "}
                          Call Us
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
          <div className="row pt-4">
            <div className="col-lg-2"></div>
            {filteredData?.map((singlePost) => (
              <div className="col-lg-4 col-md-6 col-sm-12" key={singlePost.id}>
                <div className="blog-wrap-2 mb-30">
                  <div className="blog-content-2">
                    <div className="blog-meta-2"></div>
                    <h4 className="text-center" style={{ fontWeight: "600" }}>
                      {singlePost.name}
                    </h4>
                    <p style={{ fontWeight: "600", fontSize: "18px" }}>
                      <i
                        class="fa-solid fa-location-dot fs-5"
                        style={{ color: "red" }}
                      ></i>{" "}
                      {singlePost.address}
                    </p>

                    <div className="blog-btn-2">
                      {singlePost.city + " , " + singlePost.state}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        border: "1px  solid red",
                        borderRadius: "20px",
                        padding: "5px",
                      }}
                    >
                      <a href={`tel:${singlePost.number}`}>
                        <i
                          class="fa-solid fa-phone fs-5"
                          style={{ color: "red" }}
                        ></i>{" "}
                        Call Us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* </LayoutOne> */}
    </Fragment>
  )
}

export default DealerDetailsStandard
