import { Fragment, useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import { MDBDataTable } from "mdbreact"

import axios from "axios"
import ProductModal from "../../components/product/ProductModal"
import CarModal from "../../components/product/CarModal"
import ServiceModal from "../../components/product/ServiceModal"
const ServiceAppointment = () => {
  const [modalShow, setModalShow] = useState(false)
  const [carDataForModal, setCarDataForModal] = useState(null)

  const [loading, setLoading] = useState(false)

  const [filteredData, setFilteredData] = useState([])
  const TOKEN = sessionStorage.getItem("token")

  const fetchData = async () => {
    setLoading(true)

    try {
      const response = await axios.get(
        "https://autohouse.onrender.com/book/show_all_sent_books_service_user",
        {
          headers: {
            token: `${TOKEN}`,
          },
        }
      )
      setFilteredData(response.data.result)
      setLoading(false)

      console.log(response.data.result)
    } catch (error) {
      setLoading(false)

      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])
  const columns = [
    {
      label: "Name",
      field: "name",
      sort: "asc",
    },
    {
      label: "Attend Date",
      field: "attend_date",
      sort: "asc",
    },
    {
      label: "Attend Time",
      field: "attend_time",
      sort: "asc",
    },
    {
      label: "Branch",
      field: "branch",
      sort: "asc",
    },
    {
      label: "Accept",
      field: "accept",
      sort: "asc",
    },
  ]

  // Map your data into the format required by MDBDataTable
  const rows = filteredData.map((appointment, key) => ({
    name: appointment.service_id.name,

    attend_date: appointment.attend_date.slice(0, -12),
    attend_time: appointment.attend_time,
    branch: `${appointment.branch_id.name} ${appointment.branch_id.address} ${appointment.branch_id.city} ${appointment.branch_id.state}`,
    accept: (
      <div
        style={{
          backgroundColor:
            appointment.accept === "Pending"
              ? "yellow"
              : appointment.accept === "Accepted"
              ? "green"
              : appointment.accept === "Rejected"
              ? "red"
              : "transparent",
          color: "white",
          margin: "3%",
        }}
      >
        {appointment.accept}
      </div>
    ),
  }))

  // Construct the data object
  const data = {
    columns,
    rows,
  }

  return (
    <Fragment>
      <div className="cart-main-area">
        <div className="container">
          <Fragment>
            <h3 className="cart-page-title">Your Service Appointments</h3>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "70vh",
                  alignItems: "center",
                }}
              >
                <div className="typing-indicator">
                  <div className="typing-circle"></div>
                  <div className="typing-circle"></div>
                  <div className="typing-circle"></div>
                  <div className="typing-shadow"></div>
                  <div className="typing-shadow"></div>
                  <div className="typing-shadow"></div>
                </div>
              </div>
            ) : (
              <div className="row" style={{ marginTop: "1rem" }}>
                <div className="col-12">
                  <div className="table-content table-responsive cart-table-content">
                    <MDBDataTable
                      striped
                      bordered
                      small
                      data={data}
                      noBottomColumns
                    />
                  </div>
                </div>
              </div>
            )}
            {/* <div className="row">
                  <div className="col-lg-12">
                    <div className="cart-shiping-update-wrapper">
                      <div className="cart-shiping-update">
                        <Link
                          to={process.env.PUBLIC_URL + "/shop-grid-full-width"}
                        >
                          Continue Shopping
                        </Link>
                      </div>
                   
                    </div>
                  </div>
                </div> */}
          </Fragment>
        </div>
      </div>
      {carDataForModal && (
        <ServiceModal
          show={modalShow}
          onHide={() => {
            setModalShow(false)
            setCarDataForModal(null)
          }}
          product={carDataForModal}
        />
      )}
    </Fragment>
  )
}

export default ServiceAppointment
