import React, { useEffect, useState, forwardRef } from "react"
import { useForm } from "react-hook-form"
import { Modal, Row, Col, Button, Alert } from "react-bootstrap"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
// import { EventInput } from "@fullcalendar/core";
import ShreyuDatepicker from "./ShreyuDatepicker"
import Select from "react-select"
import axios from "axios"
import FormInput from "./FormInput"
import { useLocation } from "react-router-dom"
import Swal from "sweetalert2"

import withReactContent from "sweetalert2-react-content"

// import { APICore } from "../../../helpers/api/apiCore";
// import FeatherIcons from "feather-icons-react";
// import { ColorResult, SketchPicker } from "react-color";

const AddEditBook = ({
  error,
  isOpen,
  onClose,
  isEditable,
  eventData,
  onRemoveEvent,
  onUpdateEvent,
  onAddEvent,
}) => {
  let { pathname } = useLocation()
  const match = pathname.match(/^\/calendar\/(\d+)$/)

  const CarId = match[1]
  console.log("Extracted ID:", CarId)
  var numericCarId = parseInt(CarId)

  console.log(eventData)

  const [startTime, setStartTime] = useState(
    isEditable
      ? new Date(
          new Date(eventData.start?.toString()).getTime() +
            new Date(eventData.start?.toString()).getTimezoneOffset() * 60000
        )
      : new Date()
  )
  console.log(startTime)
  const [endTime, setEndTime] = useState(
    isEditable
      ? new Date(
          new Date(eventData.end?.toString()).getTime() +
            new Date(eventData.end?.toString()).getTimezoneOffset() * 60000
        )
      : new Date()
  )

  const [title, settitle] = useState(eventData.title ? eventData.title : "")
  const [description, setdescription] = useState(
    eventData.description ? eventData.description : ""
  )
  const MySwal = withReactContent(Swal)

  const [participantsChosen, setParticipantsChosen] = useState([])
  const [selectOptions, setSelectOptions] = useState([])

  const [color, setColor] = useState("")

  const schemaResolver = yupResolver(
    yup.object().shape({
      title: yup.string().required("Please enter event name"),
      description: yup.string().required("Please enter description"),
      type: yup.string().required("Please select type"),
    })
  )

  const methods = useForm({
    defaultValues: eventData,
    resolver: schemaResolver,
  })

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods

  const onSubmitEvent = () => {
    const userMe = JSON.parse(sessionStorage.getItem("user"))
    var parts = []
    for (let i = 0; i < participantsChosen.length; i++) {
      parts.push(participantsChosen[i].value)
    }
    const body = {
      ...(isEditable && { id: eventData.id }),
      startDate: startTime,
      endDate: endTime,
      title: title,
      description: description,
      color: color,
      participants: parts,
      //   issuer: {
      //     id: userMe.id,
      //     name: userMe.name,
      //     image: userMe.image,
      //   },
    }
    console.log(body)
    isEditable ? onUpdateEvent(body) : onAddEvent(body)
  }

  //   const baseUrl = "/user";
  //   const api = new APICore();
  // const input =

  //     <DatepickerInput inputClass={props.inputClass} />

  const TOKEN = sessionStorage.getItem("token")
  // console.log(showTimeSelect)
  const addBook = async () => {
    try {
      const formattedTime = startTime.toLocaleTimeString("en-US", {
        hour12: false,
      })
      const formattedDate = startTime.toISOString().substring(0, 10)

      const requestData = {
        attend_time: formattedTime,
        attend_date: formattedDate,
        car_id: numericCarId,
      }

      const headers = {
        token: ` ${TOKEN}`,
        // 'Content-Type': 'application/json', // You can add this line if needed
      }

      const response = await axios.post(
        "https://autohouse.onrender.com/book/add_book",
        requestData,
        {
          headers: {
            token: `${TOKEN}`,
          },
        }
      )
      MySwal.fire(
        "Success",
        "Appointment booked, Continue shopping!",
        "success"
      ).then(() => {
        window.location.replace("/")
      })
      // Handle success, you can access response.data for the server's response
      console.log("Success:", response.data)
    } catch (error) {
      MySwal.fire("Oops!", error.response.data.comment, "error")

      // Handle error, you can access error.response.data for the server's error response
      console.error("Error:", error.response)
    }
  }

  return (
    <Modal show={isOpen} onHide={onClose} backdrop="static">
      <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
        <Modal.Title id="modal-title">
          <h5>{isEditable ? "Edit Appointment" : "Add Appointment"}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 pb-4 pt-0" style={{ height: "75vh" }}>
        {error && (
          <Alert variant="danger" className="my-2">
            {error}
          </Alert>
        )}
        <form noValidate name="chat-form" id="chat-form">
          <Row>
            <Col sm={12}>
              {/* <label className="form-label">Start Time</label> */}
              <ShreyuDatepicker
                hideAddon={true}
                showTimeSelect
                showTimeSelectOnly
                tI={60}
                dateFormat="h:mm aa"
                timeCaption="Time"
                value={startTime}
                inputClass="mb-3"
                onChange={(date) => {
                  setStartTime(date)
                }}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>
              {isEditable ? (
                <Button variant="danger" onClick={onRemoveEvent}>
                  Delete
                </Button>
              ) : null}
            </Col>
            <Col xs={8} className="text-end">
              <Button className="btn btn-light me-1" onClick={onClose}>
                Close
              </Button>
              <Button
                onClick={() => addBook()}
                variant="danger"
                className="btn btn-danger"
              >
                Save
              </Button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default AddEditBook
