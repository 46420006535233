import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
const FeatureIconTwoSingle = ({ data, spaceBottomClass, textAlignClass }) => {
  return (
    <div
      className={clsx(
        "support-wrap-2 support-shape",
        spaceBottomClass,
        textAlignClass
      )}
      style={{justifyContent:'center',display:'flex'}}
    >
      <div className="support-content-2" style={{justifyContent:'center'}}>
        <Link to={process.env.PUBLIC_URL + "/shop-grid-full-width/" + data.id}>
          <img
            className="animated"
            src={process.env.PUBLIC_URL + data.image}
            alt="car type"
            style={{ width: isMobile ? "50%" : "70%" }}
          />
        </Link>
        <h5>{data.name}</h5>
        {/* <p>{data.subtitle}</p> */}
      </div>
    </div>
  );
};

FeatureIconTwoSingle.propTypes = {
  data: PropTypes.shape({}),
  spaceBottomClass: PropTypes.string,
  textAlignClass: PropTypes.string,
};

export default FeatureIconTwoSingle;
