import { Fragment, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { getDiscountPrice } from "../../helpers/product"
import SEO from "../../components/seo"
import LayoutOne from "../../layouts/LayoutOne"
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb"
import { addToCart } from "../../store/slices/cart-slice"
import axios from "axios"
import { Col, Row, Form, Alert } from "react-bootstrap"
import CarAppointment from "./CarAppointment"
import ServiceAppointment from "./ServiceAppointment"
import swal from "sweetalert"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import dayjs from "dayjs"
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import Select from "react-select"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import FormControl from "@mui/material/FormControl"
import TextField from "@mui/material/TextField"
import { ValidationError } from "yup"
const HostedURL = "https://autohouse.onrender.com/"

// import BasicInfo from "./BasicInfo";
// import EducationalInfo from "./EducationalInfo";
// import PersonalInfo from "./PersonalInfo";
const CarDetails = ({ car, setCar, validationErrors }) => {
  const [colors, setColors] = useState([])
  const [brands, setBrands] = useState([])
  const [transmissions, setTransmissions] = useState([])
  const [bodyStyle, setBodyStyle] = useState([])
  const [engineCapacity, setEngineCapacity] = useState([])
  const [models, setModels] = useState([])
  const [years, setYears] = useState([])
  const [trims, setTrims] = useState([])
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      "&:hover": {
        borderColor: "#dc3545",
      },

      borderColor: state.isFocused ? "#dc3545" : "#adb5bd",
      boxShadow: "#dc3545",
    }),
    option: (provided, state) => ({
      ...provided,
      "&:active": {
        backgroundColor: "#dc3545",
      },
      backgroundColor: state.isFocused ? "#dc3545" : "#fff", // Background color on hover
      color: state.isFocused ? "#fff" : null, // Text color on hover
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isActive ? "#dc3545" : null, // Background color on active state
    }),
  }
  useEffect(() => {
    axios
      .get(HostedURL + `color/show_all_colors`, {
        headers: {
          lang: "EN",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        let tempo = []
        res.data.result.map((one) => {
          tempo.push({
            value: one.id,
            label: one.name,
          })
        })
        setColors(tempo)
      })
      .catch((e) => {
        console.error(e)
      })

    axios
      .get(HostedURL + `brand/show_all_brands`, {
        headers: {
          lang: "EN",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setBrands(
          res.data.result.map((br) => {
            return {
              label: br.name,
              value: br.id,
            }
          })
        )
      })
      .catch((e) => {})

    axios
      .get(HostedURL + `car/show_all_transmission`, {
        headers: {
          lang: "EN",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        let temp = []
        res.data.result.map((one) => {
          if (one.id != 0)
            temp.push({
              value: one.id,
              label: one.name,
            })
        })
        setTransmissions([...temp])
      })
      .catch((e) => {})
    axios
      .get(HostedURL + `car/show_all_body_style`, {
        headers: {
          lang: "EN",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setBodyStyle(
          res.data.result.map((one) => {
            return {
              value: one.id,
              label: one.name,
            }
          })
        )
      })
      .catch((e) => {})
    axios
      .get(HostedURL + `car/show_all_engine_capacity_user`, {
        headers: {
          lang: "EN",
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        let temp = []
        res.data.result.map((one) => {
          if (one.id != 0)
            temp.push({
              value: one.id,
              label: one.name,
            })
        })
        setEngineCapacity([...temp])
      })
      .catch((e) => {})
  }, [])
  const getBrandModels = (id) => {
    axios
      .get(HostedURL + `model/show_brand_models/${id}`, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res)
        let t = []
        res.data.result.map((one) => {
          t.push({
            value: one.id,
            label: one.name,
            years: one.years,
            trims: one.trims,
          })
        })
        setModels([...t])
      })
      .catch((e) => {
        // console.log(e)
      })
  }

  return (
    <>
      <Row className="mb-3">
        <Col md={4} className="mb-4">
          <label>Select Brand</label>
          <Select
            required
            options={brands}
            value={car.make}
            styles={customStyles}
            onChange={(selectedOption) => {
              setModels([])
              setYears([])
              setTrims([])
              setCar({
                ...car,
                make: selectedOption,
                year: "",
                trim: "",
                model: "",
              })
              getBrandModels(selectedOption.value)
            }}
            className="form-control p-0"
          />
        </Col>
        <Col md={4} className="mb-4">
          <label>Select Model</label>
          <Select
            required
            options={models}
            value={car.model}
            styles={customStyles}
            onChange={(selectedOption) => {
              setYears([])
              setTrims([])
              setCar({ ...car, model: selectedOption, year: "", trim: "" })
              setYears(
                selectedOption.years.map((year) => {
                  return {
                    label: year,
                    value: year,
                  }
                })
              )
              setTrims(
                selectedOption.trims.map((one) => {
                  return {
                    value: one.id,
                    label: one.name,
                  }
                })
              )
            }}
            className="form-control p-0"
          />
        </Col>
        <Col md={4} className="mb-4">
          <label>Select Year</label>
          <Select
            options={years}
            required
            value={car.year}
            styles={customStyles}
            onChange={(selectedOption) => {
              setCar({ ...car, year: selectedOption })
            }}
            className="form-control p-0"
          />
        </Col>
        <Col md={4} className="mb-4">
          <label>Select Trim</label>
          <Select
            options={trims}
            value={car.trim}
            required
            styles={customStyles}
            onChange={(selectedOption) => {
              setCar({ ...car, trim: selectedOption })
            }}
            className="form-control p-0"
          />
        </Col>
        <Col md={4} className="mb-4">
          <label>Select Transmission</label>
          <Select
            options={transmissions}
            required
            value={car.transmission}
            styles={customStyles}
            onChange={(selectedOption) => {
              setCar({ ...car, transmission: selectedOption })
            }}
            className="form-control p-0"
          />
        </Col>
        <Col md={4} className="mb-4">
          <label>Select Body Style</label>
          <Select
            options={bodyStyle}
            required
            value={car.bodyStyle}
            styles={customStyles}
            onChange={(selectedOption) => {
              setCar({ ...car, bodyStyle: selectedOption })
            }}
            className="form-control p-0"
          />
        </Col>
        <Col md={4} className="mb-4">
          <label>Select Color</label>
          <Select
            required
            options={colors}
            value={car.color}
            styles={customStyles}
            onChange={(selectedOption) => {
              setCar({ ...car, color: selectedOption })
            }}
            className="form-control p-0"
          />
        </Col>
        <Col md={4} className="mb-4">
          <label>Select Engine Capacity</label>
          <Select
            required
            options={engineCapacity}
            value={car.engineCapacity}
            styles={customStyles}
            onChange={(selectedOption) => {
              setCar({ ...car, engineCapacity: selectedOption })
            }}
            className="form-control p-0"
          />
        </Col>
        <Col md={4} className="mb-4">
          <label>Duration Of Ad</label>
          <Select
            required
            options={[
              { label: "1 week", value: "1" },
              { label: "2 week", value: "2" },
              { label: "3 week", value: "3" },
              { label: "4 week", value: "4" },
            ]}
            value={car.end}
            styles={customStyles}
            onChange={(selectedOption) => {
              setCar({ ...car, end: selectedOption })
            }}
            className="form-control p-0"
          />
        </Col>
        <Col md={4} className="mb-4 mt-2">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <label>Select License Expiry Date</label>
            <DatePicker
              value={car?.license_valid_to && dayjs(car.license_valid_to)}
              onChange={(newDate) => {
                setCar({
                  ...car,
                  license_valid_to: newDate,
                })
              }}
              className={
                validationErrors && validationErrors.license_valid_to && "error"
              }
              slotProps={{
                textField: {
                  helperText:
                    validationErrors &&
                    validationErrors.license_valid_to &&
                    validationErrors.license_valid_to,
                },
              }}
            />
          </LocalizationProvider>
        </Col>
        <Col md={4} className="mb-4">
          <FormControl sx={{ m: 1 }} variant="outlined">
            <label>Enter Kilometers</label>
            <OutlinedInput
              id="outlined-adornment-Kilometer"
              type="number"
              required
              endAdornment={<InputAdornment position="end">KM</InputAdornment>}
              aria-describedby="outlined-Kilometer-helper-text"
              inputProps={{
                "aria-label": "Kilometer",
              }}
              value={car.kilometers}
              onChange={(selectedOption) => {
                setCar({ ...car, kilometers: selectedOption.target.value })
              }}
            />
          </FormControl>
        </Col>
        <Col md={4} className="mb-4">
          <FormControl sx={{ m: 1 }} variant="outlined">
            <label>Enter Price</label>
            <OutlinedInput
              id="outlined-adornment-price"
              // required
              type="number"
              endAdornment={<InputAdornment position="end">LE</InputAdornment>}
              aria-describedby="outlined-price-helper-text"
              inputProps={{
                "aria-label": "price",
              }}
              value={car.price}
              onChange={(selectedOption) => {
                setCar({ ...car, price: selectedOption.target.value })
              }}
            />
          </FormControl>
        </Col>
        <Col md={12} className="mb-4">
          <FormControl sx={{ m: 1, width: "97%" }} variant="outlined">
            <label>Enter Description</label>
            <OutlinedInput
              id="outlined-adornment-price"
              type="text"
              // required
              endAdornment={
                <InputAdornment position="end">Notes</InputAdornment>
              }
              multiline
              aria-describedby="outlined-price-helper-text"
              inputProps={{
                "aria-label": "price",
              }}
              value={car.description}
              onChange={(selectedOption) => {
                setCar({ ...car, description: selectedOption.target.value })
              }}
            />
          </FormControl>
        </Col>
      </Row>
    </>
  )
}
export default CarDetails
