import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
// import { setActiveSort } from "../../helpers/product";
import * as Accordion from "@radix-ui/react-accordion"
import { ChevronDownIcon } from "@radix-ui/react-icons"
import classNames from "classnames"
import Select from "react-select"
import axios from "axios"

import "./style.css"
const AccordionTrigger = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Header className="AccordionHeader">
      <Accordion.Trigger
        className={classNames("AccordionTrigger", className)}
        {...props}
        ref={forwardedRef}
      >
        {children}
        <ChevronDownIcon className="AccordionChevron" aria-hidden />
      </Accordion.Trigger>
    </Accordion.Header>
  )
)

const AccordionContent = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Content
      className={classNames("AccordionContent", className)}
      {...props}
      ref={forwardedRef}
    >
      <div className="AccordionContentText">{children}</div>
    </Accordion.Content>
  )
)
// const setActiveSort = e => {
//   if (e.currentTarget) {
//     const filterButtons = document.querySelectorAll(
//       ".sidebar-widget-list-left button, .sidebar-widget-tag button, .product-filter button"
//     );
//     filterButtons.forEach(item => {
//       item.classList.remove("active");
//     });
//     e.currentTarget.classList.add("active");
//   }
// };
const ShopCC = ({ onFilterChange }) => {
  const [activeButtons, setActiveButtons] = useState([])

  const [filteredData, setFilteredData] = useState([])

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://autohouse.onrender.com/car/show_all_engine_capacity_user"
      )
      setFilteredData(response.data.result)
      console.log(response.data.result)
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    fetchData() // Fetch data when the component mounts
  }, [])

  const handleFilterChange = (selectedValue) => {
    const isActive = activeButtons.includes(selectedValue)

    // If the button is active, remove it from the activeButtons array
    // If it's not active, add it to the array
    const newActiveButtons = isActive
      ? activeButtons.filter((buttonId) => buttonId !== selectedValue)
      : [...activeButtons, selectedValue]

    setActiveButtons(newActiveButtons)

    // If no button is selected, send an empty value
    const engineCapacityValue =
      newActiveButtons.length === 0 ? "" : selectedValue
    onFilterChange("engine_capacity", engineCapacityValue)
  }

  const setActiveSort = (productId) => {
    setActiveButtons((prevActiveButtons) => [...prevActiveButtons, productId])
  }
  return (
    <div className="sidebar-widget mt-40">
      <Accordion.Root
        className="AccordionRoot"
        type="single"
        defaultValue="item-1"
        collapsible
      >
        <Accordion.Item className="AccordionItem" value="item-1">
          <AccordionTrigger className="pro-sidebar-title">
            Engine Capacity (cc)
          </AccordionTrigger>

          <AccordionContent className="sidebar-widget-list ">
            <ul class="accordion-list">
              {filteredData.map((product) => {
                return (
                  <li>
                    <div className="sidebar-widget-list-left">
                      <button
                        onClick={(e) => {
                          handleFilterChange(product.id)

                          // setActiveSort(product.id);
                        }}
                        className={classNames({
                          active: activeButtons.includes(product.id),
                        })}
                      >
                        {" "}
                        <span className="checkmark" /> {product.name}
                      </button>
                    </div>
                  </li>
                )
              })}
            </ul>
          </AccordionContent>
        </Accordion.Item>
      </Accordion.Root>
    </div>
  )
}

ShopCC.propTypes = {
  getSortParams: PropTypes.func,
  sizes: PropTypes.array,
}

export default ShopCC
