import PropTypes from "prop-types";
import React, { Fragment , useState} from "react";
import ShopTopAction from "../../components/product/ShopTopAction";

const ShopTopbar = ({
  getLayout,
  getFilterSortParams,
  productCount,
  sortedProductCount
}) => {
  const [localSortType, setLocalSortType] = useState("");
  const [localSortValue, setLocalSortValue] = useState("");

  const handleSortChange = (sortType, sortValue) => {
    setLocalSortType(sortType);
    setLocalSortValue(sortValue);
    getFilterSortParams(sortType, sortValue);
  };

  return (
    <Fragment>
      {/* shop top action */}
      <ShopTopAction
        getLayout={getLayout}
        handleSortChange={handleSortChange}

        getFilterSortParams={getFilterSortParams}
        productCount={productCount}
        sortedProductCount={sortedProductCount}
      />
    </Fragment>
  );
};

ShopTopbar.propTypes = {
  getFilterSortParams: PropTypes.func,
  getLayout: PropTypes.func,
  productCount: PropTypes.number,
  sortedProductCount: PropTypes.number
};

export default ShopTopbar;
