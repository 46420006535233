import { Fragment, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { getDiscountPrice } from "../../helpers/product"
import SEO from "../../components/seo"
import LayoutOne from "../../layouts/LayoutOne"
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb"
import { addToCart } from "../../store/slices/cart-slice"
import axios from "axios"
import { Col, Row, Form, Alert } from "react-bootstrap"
import CarAppointment from "./CarAppointment"
import ServiceAppointment from "./ServiceAppointment"
import swal from "sweetalert"
import Box from "@mui/material/Box"
// import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import dayjs from "dayjs"
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import Select from "react-select"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import FormControl from "@mui/material/FormControl"
import { MDBDataTable } from "mdbreact"
import BootstrapTab from "react-bootstrap/Tab" // Alias this import
import Nav from "react-bootstrap/Nav"

import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material"
import CustomerModal from "./CustomerModal"
const HostedURL = "https://autohouse.onrender.com/"

// import BasicInfo from "./BasicInfo";
// import EducationalInfo from "./EducationalInfo";
// import PersonalInfo from "./PersonalInfo";
const CarDetails = ({
  selectedCar,
  setSelectedCar,
  setActive,
  setCar,
  car,
}) => {
  const [carDataForModal, setCarDataForModal] = useState(null)
  const [modalShow, setModalShow] = useState(false)
  const [user, setUser] = useState()
  const TOKEN = sessionStorage.getItem("token")

  const getCar = (id) => {
    axios
      .get(HostedURL + "car/show_one_cars_front/" + id, {
        headers: {
          token: `${TOKEN}`,
        },
      })
      .then((res) => {
        const temp = res.data
        console.log(temp)

        setCar((prev) => {
          // console.log("dakhal")
          return {
            ...temp,
            end: {
              label:
                temp.end === 1
                  ? "أسبوع واحد"
                  : temp.end === 2
                  ? "أسبوعين"
                  : temp.end === 3
                  ? "ثلاثة أسابيع"
                  : "أربعة أسابيع",
              value: temp.end,
            },
            make: {
              label: temp.make.ar_name,
              value: temp.make.id,
            },
            model: {
              label: temp.model.ar_name,
              value: temp.model.id,
              trims: temp.model.trims,
              years: temp.model.years,
            },
            year: {
              label: temp.year,
              value: temp.year,
            },
            color: {
              label: temp.ar_color,
              value: temp.ar_color,
            },
            engineCapacity: {
              label: temp.engine_capacity.ar_name,
              value: temp.engine_capacity.id,
            },
            trim: {
              label: temp.trim.ar_name,
              value: temp.trim.id,
            },
            bodyStyle: {
              label: temp.body_style.ar_name,
              value: temp.body_style.id,
            },
            transmission: {
              label: temp.transmission.ar_name,
              value: temp.transmission.id,
            },
            license_valid_to: new Date(temp.license_valid_to),
            carInspections: temp.car_inspections,
            carFeatures: temp.car_Feature,
          }
        })
        setActive(6)
      })
      .catch((error) => {})
  }
  const handleVerifyServices = (id, verify) => {
    axios
      .patch(
        HostedURL + `book/accept_book/${id}`,
        {
          accept: verify,
        },
        {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        getCar(user.id)
      })
      .catch((e) => {
        // console.log(e)
      })
  }
  const columns = [
    {
      label: "Car Make",
      field: "Name",
      sort: "asc",
    },
    {
      label: "Car Model",
      field: "Model",
      sort: "asc",
    },
    {
      label: "Price",
      field: "price",
      sort: "asc",
    },
    {
      label: "Expire",
      field: "Expire",
    },

    {
      label: "Verify",
      field: "verify",
    },
    {
      label: "Sold",
      field: "sold",
    },
    {
      label: "",
      field: "actions",
    },
  ]
  const columns2 = [
    {
      label: "Car Make",
      field: "Name",
      sort: "asc",
    },
    {
      label: "Car Model",
      field: "Model",
      sort: "asc",
    },
    {
      label: "Year",
      field: "year",
      sort: "asc",
    },

    {
      label: "Attend Date",
      field: "attendDate",
    },
    // {
    //   label: "Used",
    //   field: "Used",
    // },

    {
      label: "Customer",
      field: "customer",
    },
    {
      label: "",
      field: "Actions",
    },
  ]
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      "&:hover": {
        borderColor: "#dc3545",
      },

      borderColor: state.isFocused ? "#dc3545" : "#adb5bd",
      boxShadow: "#dc3545",
    }),
    option: (provided, state) => ({
      ...provided,
      "&:active": {
        backgroundColor: "#dc3545",
      },
      backgroundColor: state.isFocused ? "#dc3545" : "#fff", // Background color on hover
      color: state.isFocused ? "#fff" : null, // Text color on hover
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isActive ? "#dc3545" : null, // Background color on active state
    }),
  }
  const carInitialization = {
    make: "",
    model: "",
    year: "",
    color: "",
    kilometers: "",
    price: "",
    transmission: "",
    description: "",
    bodyStyle: "",
    engineCapacity: "",
    license_valid_to: "",
    specal: false,
    trim: "",
    carFeatures: [],
    carInspections: [],
    image: "",
  }
  const [cars, setCars] = useState([])
  const [recieved, setRecieved] = useState([])
  const [showPopup, setShowPopup] = useState(false)
  const [carId, setCarId] = useState(0)
  console.log(carId)
  const handleAllow = async () => {
    try {
      await axios.patch(
        HostedURL + `car/update_car_sold/${carId}`,
        { sold: true }, // Move the data object here
        {
          headers: {
            token: TOKEN, // Send the token directly in the headers
          },
        }
      )
      // Handle success, e.g., close popup
      setShowPopup(false)
      // Perform any other necessary actions upon successful update
    } catch (error) {
      // Handle error
      console.error("Error marking car as sold:", error)
    }
  }

  useEffect(() => {
    axios
      .get(HostedURL + `car/show_all_user_cars`, {
        headers: {
          token: sessionStorage.getItem("token"),
          lang: "AR",
        },
      })
      .then((res) => {
        // console.log(res.data)
        let temp = []
        res.data.map((item) => {
          temp.push({
            ...item,
            make: item.make,
            model: item.model,
            year: item.year,
            color: item.color,
            engineCapacity: item.engine_capacity,
            trim: item.trim,
            bodyStyle: item.body_style,
            transmission: item.transmission,
          })
        })
        // res.data.map((item) => {
        //   temp.push({
        //     ...item,
        //     make: {
        //       label: item.make.name,
        //       value: item.make.id,
        //     },
        //     model: {
        //       label: item.model.name,
        //       value: item.model.id,
        //       trims: item.model.trims,
        //       years: item.model.years,
        //     },
        //     year: {
        //       label: item.year,
        //       value: item.year,
        //     },
        //     color: {
        //       label: item.color,
        //       value: item.color,
        //     },
        //     engineCapacity: {
        //       label: item.engine_capacity.name,
        //       value: item.engine_capacity.id,
        //     },
        //     trim: { label: item.trim.name, value: item.trim.id },
        //     bodyStyle: {
        //       label: item.body_style.name,
        //       value: item.body_style.id,
        //     },
        //     transmission: {
        //       label: item.transmission.name,
        //       value: item.transmission.id,
        //     },

        //     carInspections: item.car_inspections,
        //     carFeatures: item.car_Feature,
        //   })
        // })

        console.log(temp)
        setCars([...temp])
      })
      .catch((e) => {
        // console.log(e)
      })
    axios
      .get(HostedURL + `book/show_all_received_books_user`, {
        headers: {
          token: sessionStorage.getItem("token"),
          lang: "AR",
        },
      })
      .then((res) => {
        console.log(res.data)

        setRecieved(res.data)

        // console.log(temp)
        // setCars([...temp])
      })
  }, [])
  const handleDelete = (id) => {
    axios
      .delete(`https://autohouse.onrender.com/car/delete_car/${id}`, {
        headers: {
          token: TOKEN,
        },
      })
      .then((res) => {
        console.log(res)
        // window.location.reload()
        // setReload(!reload)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  return (
    <>
      <BootstrapTab.Container defaultActiveKey="myAds">
        <Nav
          variant="pills"
          className="description-review-topbar"
          style={{ justifyContent: "flex-start" }}
        >
          <Nav.Item>
            <Nav.Link
              eventKey="myAds"
              style={{ fontSize: "0.875rem", color: "#dc3545" }}
            >
              My Ads
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              eventKey="requsts"
              style={{ fontSize: "0.875rem", color: "#dc3545" }}
            >
              Requests
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <BootstrapTab.Content className="description-review-bottom">
          <BootstrapTab.Pane eventKey="myAds">
            <Row className="mb-3">
              <MDBDataTable
                hover
                responsive
                noBottomColumns
                data={{
                  columns: columns,
                  rows: cars.map((one) => {
                    return {
                      ...one,
                      image: (
                        <img
                          src={one.images[0].image}
                          style={{ maxWidth: "8vw" }}
                        />
                      ),
                      Name: one.make,
                      Model: one.model,
                      verify: one.verify ? (
                        <i
                          className="fas fa-check"
                          style={{ color: "green" }}
                        />
                      ) : (
                        <i className="fas fa-close" style={{ color: "red" }} />
                      ),
                      Expire: one.expire ? (
                        <i className="fas fa-check" />
                      ) : (
                        <i className="fas fa-close" />
                      ),
                      sold: one.sold ? (
                        <i className="fas fa-close" />
                      ) : (
                        <i
                          className="fas fa-check"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setShowPopup(true)
                            setCarId(one.id)
                          }}
                        />
                      ),
                      actions: (
                        <Row
                        // style={{
                        //     display: "flex",
                        //     justifyContent:
                        //         "space-evenly",
                        // }}
                        >
                          <Col xs={4} md={6}>
                            <img
                              src={one.image}
                              alt=""
                              style={{ width: "3vw" }}
                            />
                          </Col>
                          <Col xs={4} md={2}>
                            <Link
                              to="#"
                              className="btn btn-danger btn-sm mr-2"
                              onClick={(e) => {
                                e.preventDefault()
                                handleDelete(one.id)
                              }}
                            >
                              <i className="fas fa-trash"></i>
                            </Link>
                          </Col>
                          <Col xs={4} md={2}>
                            <Link
                              to="#"
                              className="btn btn-primary btn-sm"
                              onClick={(e) => {
                                e.preventDefault()
                                if (selectedCar && selectedCar.id === one.id) {
                                  setSelectedCar(carInitialization)
                                } else {
                                  setSelectedCar(one)
                                  console.log(one.id)
                                  getCar(one.id)
                                  // setActive(6)
                                }
                              }}
                            >
                              {selectedCar && selectedCar.id === one.id ? (
                                <i
                                  className="fas fa-close"
                                  style={{
                                    color: "white",
                                  }}
                                ></i>
                              ) : (
                                <i className="fas fa-edit"></i>
                              )}
                            </Link>
                          </Col>
                          {showPopup && (
                            <div className="popup">
                              <div className="cardSold">
                                <p className="cookieHeading">Mark as sold</p>
                                <p className="cookieDescription">
                                  Are you sure you want to mark your car as
                                  sold?
                                </p>
                                <div className="buttonContainer">
                                  <button
                                    className="acceptButton"
                                    onClick={handleAllow}
                                  >
                                    Confirm
                                  </button>
                                  <button
                                    className="declineButton"
                                    onClick={() => {
                                      setShowPopup(false)
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </Row>
                      ),
                    }
                  }),
                }}
              />
            </Row>
          </BootstrapTab.Pane>

          <BootstrapTab.Pane eventKey="requsts">
            <Row className="mb-3">
              <MDBDataTable
                hover
                responsive
                noBottomColumns
                data={{
                  columns: columns2,
                  rows: recieved.map((one) => {
                    return {
                      ...one,
                      image: (
                        <img
                          src={one.car.images[0].image}
                          style={{ maxWidth: "8vw" }}
                        />
                      ),
                      Name: one.car ? one.car.make : "",
                      Model: one.car ? one.car.model : "",
                      year: one.car ? one.car.year : "",

                      price: one.car ? one.car.price.toLocaleString() : "",
                      Used: one.car ? (
                        one.car.used ? (
                          <i className="fas fa-check" />
                        ) : (
                          <i className="fas fa-close" />
                        )
                      ) : (
                        ""
                      ),
                      attendDate: one.attend_date ? one.attend_date : "",

                      customer: (
                        <Row
                        // style={{
                        //     display: "flex",
                        //     justifyContent:
                        //         "space-evenly",
                        // }}
                        >
                          {/* <Col xs={4} md={6}>
                      <img src={one.image} alt="" style={{ width: "3vw" }} />
                    </Col> */}
                          {/* <Col xs={4} md={2}>
                      <Link
                        to="#"
                        className="btn btn-danger btn-sm mr-2"
                        onClick={(e) => {
                          e.preventDefault()
                          //   handleDelete(one.id)
                        }}
                      >
                        <i className="fas fa-trash"></i>
                      </Link>
                    </Col> */}
                          <Col xs={4} md={2}>
                            {/* <Link
                        to="#"
                        className="btn btn-primary btn-sm"
                        onClick={(e) => {
                          setCarDataForModal(one.customer);
                          setModalShow(true);
                        }}
                      > */}
                            {/* {console.log(one.customer.name)} */}
                            {one.customer && one.customer.name}

                            {/* </Link> */}
                          </Col>
                        </Row>
                      ),
                      Actions: (
                        <Row>
                          <Col md={4} xs={12}>
                            {one.accept == "Pending" ? (
                              <>
                                <Link
                                  to="#"
                                  className={`btn ${
                                    one.accept ? "btn-danger" : "btn-success"
                                  } btn-sm`}
                                  style={{
                                    marginLeft: "0.2vw",
                                    marginBottom: "3px",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setUser(one)
                                    handleVerifyServices(one.id, true)
                                  }}
                                >
                                  {one.accept == "Pending" ? (
                                    <> Accept?</>
                                  ) : (
                                    <> Reject?</>
                                  )}
                                </Link>
                                <Link
                                  to="#"
                                  className={`btn ${
                                    one.accept ? "btn-danger" : "btn-success"
                                  } btn-sm`}
                                  style={{
                                    marginLeft: "0.2vw",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    handleVerifyServices(one.id, false)
                                  }}
                                >
                                  <> Reject?</>
                                </Link>
                              </>
                            ) : one.accept == "Accepted" ? (
                              <Link
                                to="#"
                                className={`btn ${"btn-success"} btn-sm`}
                                style={{
                                  marginLeft: "0.2vw",
                                  cursor: "default",
                                }}
                              >
                                <> Accepted</>
                              </Link>
                            ) : one.accept == "Rejected" ? (
                              <Link
                                to="#"
                                className={`btn ${"btn-danger"} btn-sm`}
                                style={{
                                  marginLeft: "0.2vw",
                                  cursor: "default",
                                }}
                              >
                                <> Rejected</>
                              </Link>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      ),
                    }
                  }),
                }}
              />
            </Row>
          </BootstrapTab.Pane>
        </BootstrapTab.Content>
      </BootstrapTab.Container>
      {carDataForModal && (
        <CustomerModal
          show={modalShow}
          onHide={() => {
            setModalShow(false)
            setCarDataForModal(null)
          }}
          product={carDataForModal}
        />
      )}
    </>
  )
}
export default CarDetails
