import { Fragment, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { getDiscountPrice } from "../../helpers/product"
import SEO from "../../components/seo"
import LayoutOne from "../../layouts/LayoutOne"
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb"
import { addToCart } from "../../store/slices/cart-slice"
// import {  deleteAllFromWishlistAr } from "../../store/slices/wishlistAr-slice"
import axios from "axios"
const WishlistAr = () => {
  const dispatch = useDispatch()
  let { pathname } = useLocation()

  const currency = useSelector((state) => state.currency)
  const { wishlistItems } = useSelector((state) => state.wishlist)
  const { cartItems } = useSelector((state) => state.cart)
  const [filteredData, setFilteredData] = useState([])
  const TOKEN = sessionStorage.getItem("token")
  const deleteFromWishlist = (id) => {
    axios
      .patch(
        `https://autohouse.onrender.com/customer/update_customer_remove_wichlist`,
        {
          wishlist: id,
        },
        {
          headers: {
            token: TOKEN,
          },
        }
      )
      .then((res) => {
        console.log(res)
        window.location.reload()
        // setReload(!reload)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const deleteAllFromWishlist = () => {
    axios
      .patch(
        `https://autohouse.onrender.com/customer/clear_wichlist`,
        {},
        {
          headers: {
            token: TOKEN,
          },
        }
      )
      .then((res) => {
        console.log(res)
        window.location.reload()

        // setReload(!reload)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://autohouse.onrender.com/car/show_user_favorites",
        {
          headers: {
            token: `${TOKEN}`,
            lang: "AR",
          },
        }
      )
      setFilteredData(response.data.result)
      console.log(response.data)
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    fetchData() // Fetch data when the component mounts
  }, [])

  return (
    <Fragment>
      <SEO
        titleTemplate="Wishlist"
        description="Wishlist page of flone react minimalist eCommerce template."
      />
      {/* <LayoutOne headerTop="visible"> */}
      {/* breadcrumb */}
      <Breadcrumb
        pages={[
          { label: "القائمة المفضلة", path: process.env.PUBLIC_URL + pathname },
          { label: "الرئيسية", path: process.env.PUBLIC_URL + "/" },
        ]}
      />
      <div className="cart-main-area pt-50 pb-100" dir="rtl" lang="ar">
        <div className="container">
          {filteredData && filteredData.length >= 1 ? (
            <Fragment>
              <h3 className="cart-page-title">عناصر قائمة الرغبات الخاصة بك</h3>
              <div className="row" style={{ marginTop: "1rem" }}>
                <div className="col-12">
                  <div className="table-content table-responsive cart-table-content">
                    <table>
                      <thead>
                        <tr>
                          <th>الصورة</th>
                          <th>اسم المنتج</th>
                          <th>سعر الوحدة</th>
                          <th>عرض السيارة</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.map((wishlistItem, key) => {
                          const discountedPrice = getDiscountPrice(
                            wishlistItem.price,
                            wishlistItem.discount
                          )
                          const finalProductPrice = (
                            wishlistItem.price * currency.currencyRate
                          ).toFixed(2)
                          const finalDiscountedPrice = (
                            discountedPrice * currency.currencyRate
                          ).toFixed(2)
                          const cartItem = cartItems.find(
                            (item) => item.id === wishlistItem.id
                          )
                          return (
                            <tr key={key}>
                              <td className="product-thumbnail">
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/product/" +
                                    wishlistItem.id
                                  }
                                >
                                  <img
                                    className="img-fluid"
                                    src={
                                      process.env.PUBLIC_URL +
                                        wishlistItem.images.length >
                                        0 && wishlistItem.images[0].image
                                    }
                                    alt=""
                                  />
                                </Link>
                              </td>

                              <td className="product-name text-center">
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/product/" +
                                    wishlistItem.id
                                  }
                                >
                                  {wishlistItem.make} {wishlistItem.model}
                                </Link>
                              </td>

                              <td className="product-price-cart">
                                <span className="amount">
                                  {wishlistItem.price === null ? (
                                    "قابل للتفاوض"
                                  ) : (
                                    <>
                                      {wishlistItem.price &&
                                        wishlistItem.price.toLocaleString()}{" "}
                                      <span
                                        style={{
                                          color: "gray",
                                          paddingLeft: "3px",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {" "}
                                        EGP{" "}
                                      </span>
                                    </>
                                  )}
                                </span>
                              </td>

                              <td className="product-wishlist-cart">
                                {wishlistItem.affiliateLink ? (
                                  <a
                                    href={wishlistItem.affiliateLink}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    {" "}
                                    اشتري الان{" "}
                                  </a>
                                ) : wishlistItem.variation &&
                                  wishlistItem.variation.length >= 1 ? (
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/product/${wishlistItem.id}`}
                                  >
                                    اختر
                                  </Link>
                                ) : (
                                  <button
                                  // onClick={() =>
                                  //   dispatch(addToCart(wishlistItem))
                                  // }
                                  // className={
                                  //   cartItem !== undefined &&
                                  //   cartItem.quantity > 0
                                  //     ? "active"
                                  //     : ""
                                  // }
                                  // disabled={
                                  //   cartItem !== undefined &&
                                  //   cartItem.quantity > 0
                                  // }
                                  // title={
                                  //   wishlistItem !== undefined
                                  //     ? "Added to cart"
                                  //     : "Add to cart"
                                  // }
                                  >
                                    <Link
                                      to={`${process.env.PUBLIC_URL}/product/${wishlistItem.id}`}
                                      title="show car"
                                      style={{ color: "#fff" }}
                                    >
                                      عرض السيارة
                                    </Link>
                                  </button>
                                )}
                              </td>

                              <td className="product-remove">
                                <button
                                  onClick={() =>
                                    deleteFromWishlist(wishlistItem.id)
                                  }
                                >
                                  <i className="fa fa-times"></i>
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="cart-shiping-update-wrapper">
                    <div className="cart-shiping-update">
                      <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
                        استمر في التسوق
                      </Link>
                    </div>
                    <div className="cart-clear">
                      <button onClick={() => deleteAllFromWishlist()}>
                        مسح قائمة الرغبات
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <div className="item-empty-area text-center">
                  <div className="item-empty-area__icon mb-30">
                    <i className="pe-7s-like"></i>
                  </div>
                  <div className="item-empty-area__text">
                    لم يتم العثور على أي عناصر في قائمة الرغبات
                    <br />{" "}
                    <Link to={process.env.PUBLIC_URL + "/shop-grid-full-width"}>
                      إضافة عناصر
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* </LayoutOne> */}
    </Fragment>
  )
}

export default WishlistAr
