import { EffectFade } from "swiper"
import Swiper, { SwiperSlide } from "../../components/swiper"
import sliderData from "../../data/hero-sliders/hero-slider-ten.json"
import HeroSliderTenSingle from "../../components/hero-slider/HeroSliderTenSingle.js"
import axios from "axios"
import React, { useState, useEffect } from "react"
import HeroSliderTenSingleAr from "../../components/hero-slider/HeroSliderTenSingleAr.jsx"

const params = {
  effect: "fade",
  fadeEffect: {
    crossFade: true,
  },
  modules: [EffectFade],
  loop: true,
  speed: 1000,
  navigation: true,
  autoHeight: false,
  autoplay: false,
}

const HeroSliderTenAr = () => {
  const [sliderData, setFilteredData] = useState([])

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://autohouse.onrender.com/carousel/show_all_carousels"
      )
      setFilteredData(response.data.result)
      console.log(response.data.result)
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    fetchData() // Fetch data when the component mounts
  }, [])
  return (
    <div className="slider-area">
      <div className="slider-active nav-style-1">
        <Swiper options={params}>
          {sliderData &&
            sliderData.map((single, key) => {
              return (
                <SwiperSlide key={key}>
                  <HeroSliderTenSingleAr data={single} />
                </SwiperSlide>
              )
            })}
        </Swiper>
      </div>
    </div>
  )
}

export default HeroSliderTenAr
