import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
// import { setActiveSort } from "../../helpers/product";
import * as Accordion from "@radix-ui/react-accordion"
import { ChevronDownIcon } from "@radix-ui/react-icons"
import classNames from "classnames"
import Select from "react-select"
import axios from "axios"
import "./style.css"
const AccordionTrigger = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Header className="AccordionHeader">
      <Accordion.Trigger
        className={classNames("AccordionTrigger", className)}
        {...props}
        ref={forwardedRef}
      >
        {children}
        <ChevronDownIcon className="AccordionChevron" aria-hidden />
      </Accordion.Trigger>
    </Accordion.Header>
  )
)

const AccordionContent = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Content
      className={classNames("AccordionContent", className)}
      {...props}
      ref={forwardedRef}
    >
      <div className="AccordionContentText">{children}</div>
    </Accordion.Content>
  )
)

const setActiveSort = (e) => {
  if (e.currentTarget) {
    // Toggle the "active" class on the clicked button
    e.currentTarget.classList.toggle("active")
  }
}
const ShopKilometers = ({ onFilterChange }) => {
  const [selectedKilometer, setSelectedKilometer] = useState(null)
  const handleFilterChange = (event, selectedValue) => {
    if (event && event.target && event.target.value !== undefined) {
      const newSelectedKilometer = event.target.value

      // Check if the clicked button is already selected
      const selectedKilometerValue =
        newSelectedKilometer === selectedValue ? null : newSelectedKilometer

      setSelectedKilometer(selectedKilometerValue)
      onFilterChange("kilometerto", selectedKilometerValue)
    }
  }

  return (
    <div className="sidebar-widget mt-40">
      <Accordion.Root
        className="AccordionRoot"
        type="single"
        defaultValue="item-1"
        collapsible
      >
        <Accordion.Item className="AccordionItem" value="item-1">
          <AccordionTrigger className="pro-sidebar-title">
            Kilometers
          </AccordionTrigger>

          <AccordionContent className="sidebar-widget-list ">
            <ul class="accordion-list">
              <li>
                <div className="sidebar-widget-list-left">
                  <button
                    onClick={(e) => {
                      const selectedKilometerValue = "10000"
                      onFilterChange("kilometerto", selectedKilometerValue)
                      setSelectedKilometer(selectedKilometerValue)
                      // handleFilterChange(e, selectedKilometer)
                      setActiveSort(e)
                    }}
                    className={selectedKilometer === "10000" && "active"}
                  >
                    {" "}
                    <span className="checkmark" />
                    10,000 KM & Less
                  </button>
                </div>
              </li>
              <li>
                <div className="sidebar-widget-list-left">
                  <button
                    onClick={(e) => {
                      const selectedKilometerValue = "30000"

                      onFilterChange("kilometerto", selectedKilometerValue)
                      setSelectedKilometer(selectedKilometerValue)

                      // handleFilterChange(e, selectedKilometer)

                      setActiveSort(e)
                    }}
                    className={selectedKilometer === "30000" && "active"}
                  >
                    {" "}
                    <span className="checkmark" />
                    30,000 KM & Less
                  </button>
                </div>
              </li>
              <li>
                <div className="sidebar-widget-list-left">
                  <button
                    onClick={(e) => {
                      const selectedKilometerValue = "50000"

                      onFilterChange("kilometerto", selectedKilometerValue)
                      setSelectedKilometer(selectedKilometerValue)

                      // handleFilterChange(e, selectedKilometer)

                      setActiveSort(e)
                    }}
                    className={selectedKilometer === "50000" && "active"}
                  >
                    {" "}
                    <span className="checkmark" /> 50,000 KM & Less
                  </button>
                </div>
              </li>
              <li>
                <div className="sidebar-widget-list-left">
                  <button
                    onClick={(e) => {
                      const selectedKilometerValue = "75000"

                      onFilterChange("kilometerto", selectedKilometerValue)
                      setSelectedKilometer(selectedKilometerValue)

                      // handleFilterChange(e, selectedKilometer)

                      setActiveSort(e)
                    }}
                    className={selectedKilometer === "75000" && "active"}
                  >
                    {" "}
                    <span className="checkmark" />
                    75,000 KM & Less
                  </button>
                </div>
              </li>
              <li>
                <div className="sidebar-widget-list-left">
                  <button
                    onClick={(e) => {
                      const selectedKilometerValue = "100000"

                      onFilterChange("kilometerto", selectedKilometerValue)
                      setSelectedKilometer(selectedKilometerValue)

                      // handleFilterChange(e, selectedKilometer)

                      setActiveSort(e)
                    }}
                    className={selectedKilometer === "100000" && "active"}
                  >
                    {" "}
                    <span className="checkmark" /> 100,000 KM & Less
                  </button>
                </div>
              </li>
              <li>
                <div className="sidebar-widget-list-left">
                  <button
                    onClick={(e) => {
                      const selectedKilometerValue = "125000"

                      onFilterChange("kilometerto", selectedKilometerValue)
                      setSelectedKilometer(selectedKilometerValue)

                      // handleFilterChange(e, selectedKilometer)

                      setActiveSort(e)
                    }}
                    className={selectedKilometer === "125000" && "active"}
                  >
                    {" "}
                    <span className="checkmark" /> 125,000 KM & Less
                  </button>
                </div>
              </li>
              <li>
                <div className="sidebar-widget-list-left">
                  <button
                    onClick={(e) => {
                      const selectedKilometerValue = "150000"

                      onFilterChange("kilometerto", selectedKilometerValue)
                      setSelectedKilometer(selectedKilometerValue)

                      // handleFilterChange(e, selectedKilometer)

                      setActiveSort(e)
                    }}
                    className={selectedKilometer === "150000" && "active"}
                  >
                    {" "}
                    <span className="checkmark" />
                    150,000 KM & Less
                  </button>
                </div>
              </li>
              <li>
                <div className="sidebar-widget-list-left">
                  <button
                    onClick={(e) => {
                      const selectedKilometerValue = "175000"

                      onFilterChange("kilometerto", selectedKilometerValue)
                      setSelectedKilometer(selectedKilometerValue)

                      // handleFilterChange(e, selectedKilometer)

                      setActiveSort(e)
                    }}
                    className={selectedKilometer === "175000" && "active"}
                  >
                    {" "}
                    <span className="checkmark" />
                    175,000 KM & Less
                  </button>
                </div>
              </li>
              <li>
                <div className="sidebar-widget-list-left">
                  <button
                    onClick={(e) => {
                      const selectedKilometerValue = "200000"

                      onFilterChange("kilometerto", selectedKilometerValue)
                      setSelectedKilometer(selectedKilometerValue)

                      // handleFilterChange(e, selectedKilometer)

                      setActiveSort(e)
                    }}
                    className={selectedKilometer === "200000" && "active"}
                  >
                    {" "}
                    <span className="checkmark" />
                    200,000 KM & More
                  </button>
                </div>
              </li>
            </ul>
          </AccordionContent>
        </Accordion.Item>
      </Accordion.Root>
    </div>
  )
}

ShopKilometers.propTypes = {
  getSortParams: PropTypes.func,
  sizes: PropTypes.array,
}

export default ShopKilometers
