import { Fragment, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { getDiscountPrice } from "../../helpers/product"
import SEO from "../../components/seo"
import LayoutOne from "../../layouts/LayoutOne"
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb"
import { addToCart } from "../../store/slices/cart-slice"
import axios from "axios"
import { Col, Row, Form, Alert } from "react-bootstrap"
import CarAppointment from "./CarAppointment"
import ServiceAppointment from "./ServiceAppointment"
import swal from "sweetalert"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import dayjs from "dayjs"
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import Select from "react-select"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import { styled } from "@mui/material/styles"
import Switch from "@mui/material/Switch"
import { MDBDataTable } from "mdbreact"
const HostedURL = "https://autohouse.onrender.com/"
const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&::before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&::after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}))
// import BasicInfo from "./BasicInfo";
// import EducationalInfo from "./EducationalInfo";
// import PersonalInfo from "./PersonalInfo";
const CarDetails = ({ car, setCar, selectedCar }) => {
  const [error, setError] = useState("")
  const [selectedCarFeatures, setSelectedCarFeatures] = useState({
    value: "no", // Set default value to "no"
    ar_value: "no",
  })
  useEffect(() => {
    // Set default value of the switch to match the initial state
    setSelectedCarFeatures((prevState) => ({
      ...prevState,
      value: "no",
      ar_value: "no",
    }))
  }, [])
  console.log(selectedCarFeatures)
  const [carFeatures, setCarFeatures] = useState([])
  const [selectedFeatureOptions, setSelectedFeatureOptions] = useState([])
  const [feature, setFeature] = useState({})
  const [editCarFeaturesBoolean, setEditCarFeaturesBoolean] = useState(false)
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      "&:hover": {
        borderColor: "#dc3545",
      },

      borderColor: state.isFocused ? "#dc3545" : "#adb5bd",
      boxShadow: "#dc3545",
    }),
    option: (provided, state) => ({
      ...provided,
      "&:active": {
        backgroundColor: "#dc3545",
      },
      backgroundColor: state.isFocused ? "#dc3545" : "#fff", // Background color on hover
      color: state.isFocused ? "#fff" : null, // Text color on hover
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isActive ? "#dc3545" : null, // Background color on active state
    }),
  }
  const featuresColumns = [
    {
      label: "الاسم",
      field: selectedCar ? "ar_name" : "name",
      sort: "asc",
    },
    {
      label: "القيمة",
      field: "value",
    },
    { label: "", field: "actions" },
  ]

  const getSelectedSubCarFeature = (id) => {
    axios
      .get(HostedURL + `car/show_all_sub_car_Features/${id}`, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res)
        let t = []
        res.data.result.map((one) => {
          t.push({ ...one, values: one.value })
        })

        console.log("🚀 ~ .then ~  t:", t)

        setSelectedFeatureOptions([...t])
      })
      .catch((e) => {
        // console.log(e)
      })
  }
  useEffect(() => {
    axios
      .get(HostedURL + `car/show_all_car_Features`, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setCarFeatures(
          res.data.result.map((one) => {
            return {
              label: one.ar_name,
              value: one.id,
              type: one.type,
              values: one.value,
            }
          })
        )
      })
      .catch((e) => {})
  }, [])

  return (
    <>
      <Row className="mb-3">
        {error && <Alert color="danger">{error}</Alert>}
        <Col md={6} className="mb-4">
          <label>اختر الميزة</label>
          <Select
            required
            options={carFeatures}
            styles={customStyles}
            value={{
              label: feature?.name,
              value: feature?.id,
            }}
            onChange={(value) => {
              console.log(value)
              setFeature({
                id: value.value,
                name: value.label,
              })
              getSelectedSubCarFeature(value.value)
              setSelectedCarFeatures((prevFeatures) => ({
                ar_name: "",
                ar_value: prevFeatures.value || "no",
                id: "",
                name: "",
                type: "",
                value: prevFeatures.value || "no",
                values: [],
              }))
            }}
            className="form-control p-0"
          />
        </Col>
        {feature?.id && (
          <Col md={6} className="mb-4">
            <label>اختر الميزة الفرعية</label>
            <Select
              required
              styles={customStyles}
              id="type"
              value={{
                label: selectedCarFeatures?.name,
                value: selectedCarFeatures.id,
              }}
              onChange={(value) => {
                console.log(value)
                setSelectedCarFeatures({
                  ...selectedCarFeatures,
                  name: value.label,
                  id: value.value,
                  type: value.type,
                  values: value.values,
                })
              }}
              options={selectedFeatureOptions?.map((value) => {
                return {
                  label: value.ar_name,
                  value: value.id,
                  type: value.type,
                  values: value.values,
                }
              })}
              className="form-control p-0"
            />
          </Col>
        )}
        {selectedCarFeatures.type == "string" && (
          <Col md={6} className="mb-4">
            <label>القيمة</label>
            <Select
              required
              styles={customStyles}
              id="type"
              value={{
                label: selectedCarFeatures?.value,
                value: selectedCarFeatures.value,
              }}
              onChange={(value) => {
                setSelectedCarFeatures({
                  ...selectedCarFeatures,
                  value: value.value,
                })
              }}
              options={selectedCarFeatures.values?.map((value) => {
                return {
                  label: value.name,
                  value: value.name,
                }
              })}
              className="form-control p-0"
            />
          </Col>
        )}
        {selectedCarFeatures.type == "number" && (
          <Col xs={12} md={6}>
            <FormControl sx={{ m: 1, width: "97%" }} variant="outlined">
              <label>القيمة</label>
              <OutlinedInput
                required
                id="outlined-adornment-price"
                type="number"
                aria-describedby="outlined-price-helper-text"
                inputProps={{
                  "aria-label": "price",
                }}
                value={selectedCarFeatures.value}
                onChange={(e) => {
                  setSelectedCarFeatures({
                    ...selectedCarFeatures,
                    value: e.target.value,
                    ar_value: e.target.value,
                  })
                }}
              />
            </FormControl>
          </Col>
        )}
        {selectedCarFeatures.type == "boolean" && (
          <Col xs={12} md={6}>
            <FormControlLabel
              control={
                <Android12Switch
                  onChange={(e) => {
                    console.log(e.target.checked)
                    setSelectedCarFeatures({
                      ...selectedCarFeatures,
                      value: e.target.checked ? "yes" : "no",
                      ar_value: e.target.checked ? "yes" : "no",
                    })
                  }}
                  checked={selectedCarFeatures.value == "yes"}
                />
              }
              label={selectedCarFeatures.name}
            />
          </Col>
        )}
        <Col xs={12} md={6}>
          <button
            style={{
              marginTop: "60px",
              backgroundColor: "#dc3545",
              borderColor: "#dc3545",
              display: "flex",
              justifyContent: "center",
              width: "10%",
              float: "right",
              borderRadius: "2rem",
            }}
            className="btn btn-primary"
            onClick={(e) => {
              e.preventDefault()
              let featureBoolean = false
              let subBoolean = false
              let featureIndex = 0
              let subIndex = 0
              car.carFeatures.map((one, fIndex) => {
                if (one.id == feature.id) {
                  featureBoolean = true
                  featureIndex = fIndex
                  one.sub_car_features.map((subFeature, ind) => {
                    if (subFeature.id == selectedCarFeatures.id) {
                      subBoolean = true
                      featureIndex = fIndex
                      subIndex = ind
                    }
                  })
                }
              })
              if (!subBoolean && featureBoolean) {
                let t = car.carFeatures
                t[featureIndex].sub_car_features.push(selectedCarFeatures)
                setCar((prev) => ({
                  ...prev,
                  carFeatures: [...t],
                }))
                setEditCarFeaturesBoolean(false)
              } else if (editCarFeaturesBoolean && subBoolean) {
                let t = car.carFeatures
                t[featureIndex].sub_car_features[subIndex] = selectedCarFeatures

                setCar((prev) => ({
                  ...prev,
                  carFeatures: [...t],
                }))
              } else if (subBoolean) {
                setError("this sub feature has already been used")
                setSelectedCarFeatures({
                  ar_name: "",
                  ar_value: "",
                  id: "",
                  name: "",
                  type: "",
                  value: "",
                  values: [],
                })
              }
              if (!featureBoolean) {
                let t = car.carFeatures
                t.push({
                  id: feature.id,
                  name: feature.name,
                  sub_car_features: [selectedCarFeatures],
                })
                setCar((prev) => ({
                  ...prev,
                  carFeatures: [...t],
                }))
              }
              setSelectedCarFeatures({
                ar_name: "",
                ar_value: "",
                id: "",
                name: "",
                type: "",
                value: "",
                values: [],
              })
              setFeature({ id: "", name: "" })
              setEditCarFeaturesBoolean(false)
            }}
          >
            {editCarFeaturesBoolean ? (
              <i className="fas fa-pencil" />
            ) : (
              <i className="fas fa-plus"></i>
            )}
          </button>
        </Col>
      </Row>

      {car.carFeatures?.length > 0 && (
        <>
          {car.carFeatures?.map((one, index) => {
            return (
              <>
                <hr />
                <h2>
                  {index + 1} - {one.name} :
                </h2>

                <MDBDataTable
                  key={index}
                  hover
                  paging={false}
                  responsive
                  displayEntries={false}
                  searching={false}
                  noBottomColumns
                  data={{
                    columns: featuresColumns,
                    rows: one.sub_car_features.map((sub, ind) => {
                      return {
                        ...sub,
                        actions: (
                          <Row>
                            {/* Edit */}
                            <Col xs={4} md={4}>
                              <Link
                                to="#"
                                className="btn btn-primary btn-sm"
                                onClick={(e) => {
                                  e.preventDefault()
                                  setEditCarFeaturesBoolean(true)
                                  getSelectedSubCarFeature(one.id)
                                  setFeature({
                                    name: one.name,
                                    ar_name: one.ar_name,
                                    id: one.id,
                                    data: one.data,
                                  })
                                  setSelectedCarFeatures(sub)
                                  console.log(sub)
                                }}
                              >
                                <i className="fas fa-edit"></i>
                              </Link>
                            </Col>
                            {/* Del */}

                            <Col xs={4} md={4}>
                              <button
                                className="btn btn-danger btn-sm mr-2"
                                type="button"
                                onClick={() => {
                                  let temp = car.carFeatures
                                  temp[index].sub_car_features.splice(ind, 1)
                                  if (
                                    temp[index].sub_car_features.length == 0
                                  ) {
                                    temp.splice(index, 1)
                                  }
                                  setCar({
                                    ...car,
                                    carFeatures: [...temp],
                                  })
                                }}
                              >
                                <i className="fas fa-trash"></i>
                              </button>
                            </Col>
                          </Row>
                        ),
                      }
                    }),
                  }}
                />
              </>
            )
          })}
        </>
      )}
    </>
  )
}
export default CarDetails
