import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
// import classNames from 'classnames';

const DatepickerInput = forwardRef((props, ref) => {
    console.log(props)
    const onDateValueChange = () => {
        console.log('date value changed');
    };
    return (
        <input
            type="text"
            // className={classNames('form-control', props.inputClass)}
            onClick={props.onClick}
            value={props.value}
            onChange={onDateValueChange}
            ref={ref}
        />
    );
});

const DatepickerInputWithAddon = forwardRef((props, ref) => (
    <div className="input-group input-group-sm" ref={ref}>
        <input
            type="text"
            // className={classNames('form-control', props.inputClass)}
            onClick={props.onClick}
            value={props.value}
            readOnly
        />
        <span className="input-group-text bg-blue border-blue text-white">
            <i className="mdi mdi-calendar-range"></i>
        </span>
    </div>
));

const ShreyuDatepicker = (props) => {
    const input =
        (props.hideAddon || false) === true ? (
            <DatepickerInput inputClass={props.inputClass} />
        ) : (
            <DatepickerInputWithAddon inputClass={props.inputClass} />
        );

    return (
        <>
            <DatePicker
                calendarClassName={props.calendarClassName || 'shadow'}
                selectsRange={props.selectsRange}
                startDate={props.startDate}
                endDate={props.endDate}
                selected={props.value}
                onChange={(date) => props.onChange(date)}
                customInput={input}
                timeIntervals={props.tI}
                showTimeSelect={props.showTimeSelect}
                timeFormat={props.timeFormat || 'hh:mm a'}
                timeCaption={props.timeCaption}
                dateFormat={props.dateFormat || 'MM/dd/yyyy'}
                minDate={props.minDate}
                maxDate={props.maxDate}
                monthsShown={props.monthsShown}
                showTimeSelectOnly={props.showTimeSelectOnly}
                inline={props.inline}
                autoComplete="off"
            />
        </>
    );
};

export default ShreyuDatepicker;
