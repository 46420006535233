import { Fragment } from "react";
import { useLocation } from "react-router-dom"; 
import SEO from "../../components/seo";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import BlogPagination from "../../wrappers/blog/BlogPagination";
import BlogPostsNoSidebarAr from "../../wrappers/blog/BlogPostsNoSidebarAr";

const BlogNoSidebarAr = () => {
  let { pathname } = useLocation();
  
  return (
    <Fragment>
      <SEO
        titleTemplate="Blog"
        description="Blog of flone react minimalist eCommerce template."
      />
      {/* <LayoutOne headerTop="visible"> */}
        {/* breadcrumb */}
        <Breadcrumb 
          pages={[
            {label: "المدونات", path: process.env.PUBLIC_URL + pathname },

            {label: "الرئيسية", path: process.env.PUBLIC_URL + "/" }
          ]} 
        />
        <div className="blog-area  pb-100 blog-no-sidebar">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div >
                  <div className="row">
                    {/* blog posts */}
                    <BlogPostsNoSidebarAr />
                  </div>

                  {/* blog pagination */}
                  <BlogPagination />
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* </LayoutOne> */}
    </Fragment>
  );
};

export default BlogNoSidebarAr;
