import PropTypes from "prop-types"
import React, { useState } from "react"
// import { setActiveSort } from "../../helpers/product";
import * as Accordion from "@radix-ui/react-accordion"
import { ChevronDownIcon } from "@radix-ui/react-icons"
import classNames from "classnames"
// import Select from 'react-select';
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import "./style.css"
const AccordionTrigger = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Header className="AccordionHeader">
      <Accordion.Trigger
        className={classNames("AccordionTrigger", className)}
        {...props}
        ref={forwardedRef}
      >
        {children}
        <ChevronDownIcon className="AccordionChevron" aria-hidden />
      </Accordion.Trigger>
    </Accordion.Header>
  )
)

const AccordionContent = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Content
      className={classNames("AccordionContent", className)}
      {...props}
      ref={forwardedRef}
    >
      <div className="AccordionContentText">{children}</div>
    </Accordion.Content>
  )
)
const setActiveSort = (e) => {
  if (e.currentTarget) {
    const filterButtons = document.querySelectorAll(
      ".sidebar-widget-list-left button, .sidebar-widget-tag button, .product-filter button"
    )
    filterButtons.forEach((item) => {
      item.classList.remove("active")
    })
    e.currentTarget.classList.add("active")
  }
}
const ShopSize = ({ sizes, getSortParams, onFilterChange }) => {
  const currentYear = new Date().getFullYear()
  const [fromYear, setFromYear] = useState("")
  const [toYear, setToYear] = useState("")

  const years = Array.from({ length: currentYear - 1980 + 2 }, (_, index) =>
    (1980 + index).toString()
  )

  const handleFromYearChange = (event) => {
    const selectedFromYear = event.target.value
    setFromYear(selectedFromYear)
    onFilterChange("yearfrom", event.target.value)

    // Filter options for the To Year based on the selected From Year
    const filteredOptions = years.filter(
      (year) => parseInt(year) > parseInt(selectedFromYear - 1)
    )
    setToYear("") // Reset the To Year when changing the From Year
    setToYearOptions(filteredOptions)
  }

  const handleToYearChange = (event) => {
    setToYear(event.target.value)
    onFilterChange("yearto", event.target.value)
  }

  // State to store filtered options for the To Year
  const [toYearOptions, setToYearOptions] = useState(years)

  return (
    <div className="sidebar-widget mt-40">
      <Accordion.Root
        className="AccordionRoot"
        type="single"
        defaultValue="item-1"
        collapsible
      >
        <Accordion.Item className="AccordionItem" value="item-1">
          <AccordionTrigger className="pro-sidebar-title">
            Year
          </AccordionTrigger>

          <AccordionContent className="sidebar-widget-list ">
            <FormControl style={{ width: "100%", padding: "3%" }}>
              <InputLabel id="from-year-select-label">From </InputLabel>
              <Select
                labelId="from-year-select-label"
                id="from-year-select"
                value={fromYear}
                size="small"
                onChange={handleFromYearChange}
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl style={{ width: "100%", padding: "3%" }}>
              <InputLabel id="to-year-select-label">To </InputLabel>
              <Select
                labelId="to-year-select-label"
                id="to-year-select"
                value={toYear}
                size="small"
                disabled={!fromYear}
                onChange={handleToYearChange}
              >
                {toYearOptions.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </AccordionContent>
        </Accordion.Item>
      </Accordion.Root>
    </div>
  )
}

ShopSize.propTypes = {
  getSortParams: PropTypes.func,
  sizes: PropTypes.array,
}

export default ShopSize
