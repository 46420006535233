import PropTypes from "prop-types"
import { Fragment } from "react"
import HeaderOne from "../wrappers/header/HeaderOne"
import FooterOne from "../wrappers/footer/FooterOne"
import ScrollToTop from "../components/scroll-to-top"

const LayoutOne = ({
  setAr,
  children,
  headerContainerClass,
  headerTop,
  headerPaddingClass,
  headerPositionClass,
}) => {
  return (
    <Fragment>
      <HeaderOne
        layout={headerContainerClass}
        top={headerTop}
        headerPaddingClass={headerPaddingClass}
        headerPositionClass={headerPositionClass}
        setAr={setAr}
      />
      {children}
      <FooterOne
        backgroundColorClass="bg-red-4"
        spaceTopClass="pt-50"
        spaceBottomClass="pb-70"
      />
      <ScrollToTop />
    </Fragment>
  )
}

LayoutOne.propTypes = {
  children: PropTypes.node,
  headerContainerClass: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  headerTop: PropTypes.string,
}

export default LayoutOne
