import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import SEO from "../../components/seo";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import PrivacyPolicy from "../../components/policy";

const Policy = () => {
  let { pathname } = useLocation();

  return (
    <Fragment>
      <SEO
        titleTemplate="Privacy Policy"
        description="policy page of flone react minimalist eCommerce template."
      />
      {/* <LayoutOne headerTop="visible"> */}
      {/* breadcrumb */}
      <Breadcrumb
        pages={[
          { label: "Home", path: process.env.PUBLIC_URL + "/" },
          {
            label: "Privacy Policy",
            path: process.env.PUBLIC_URL + pathname,
          },
        ]}
      />

      <div className="contact-area " style={{ backgroundColor: "transparent" }}>
        <div className="container" style={{ maxWidth: "1140px" }}></div>
      </div>
      <PrivacyPolicy/>
    </Fragment>
  );
};

export default Policy;
