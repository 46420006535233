import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import clsx from "clsx"
import { Fragment, useState, useEffect } from "react"

import MenuCart from "./sub-components/MenuCart"
import axios from "axios"

const IconGroup = ({ iconWhiteClass }) => {
  const [wishlistItems, setFilteredData] = useState([])
  const TOKEN = sessionStorage.getItem("token")

  const handleClick = (e) => {
    e.currentTarget.nextSibling.classList.toggle("active")
  }

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector("#offcanvas-mobile-menu")
    offcanvasMobileMenu.classList.add("active")
  }
  const { compareItems } = useSelector((state) => state.compare)
  // const { wishlistItems } = useSelector((state) => state.wishlist);
  const { cartItems } = useSelector((state) => state.cart)
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://autohouse.onrender.com/car/show_user_favorites",
        {
          headers: {
            token: `${TOKEN}`,
            lang: "AR",
          },
        }
      )
      setFilteredData(response.data.result)
      console.log(response.data)
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    fetchData() // Fetch data when the component mounts
  }, [])
  return (
    <div className={clsx("header-right-wrap", iconWhiteClass)}>
      <div className="same-style header-search d-none d-lg-block">
        <div className="search-content"></div>
      </div>
      <div className="same-style account-setting d-lg-block">
        <button
          className="account-setting-active"
          onClick={(e) => handleClick(e)}
        >
          {sessionStorage.getItem("loggedIn") === "true" ? (
            <Link to={process.env.PUBLIC_URL + "/profile"}>
              <i className="pe-7s-user-female fs-2" />
            </Link>
          ) : (
            <Link to={process.env.PUBLIC_URL + "/login-register"}>
              <i className="pe-7s-user-female fs-2" />
            </Link>
          )}
        </button>
      </div>

      {sessionStorage.getItem("loggedIn") === "true" ? (
        <div className="same-style header-wishlist">
          <Link to={process.env.PUBLIC_URL + "/wishlist"}>
            <i className="pe-7s-like fs-2" />
            <span className="count-style">
              {wishlistItems && wishlistItems.length ? wishlistItems.length : 0}
            </span>
          </Link>
        </div>
      ) : (
        ""
      )}
      <div className="same-style cart-wrap d-none d-lg-block"></div>

      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu" />
        </button>
      </div>
    </div>
  )
}

IconGroup.propTypes = {
  iconWhiteClass: PropTypes.string,
}

export default IconGroup
