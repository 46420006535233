import PropTypes from "prop-types";

const TermsAndConditionsAr = () => {
  return (
    <>
      <div className="contact-area " style={{ backgroundColor: "transparent" }}  dir="rtl">
        <div className="container" style={{ maxWidth: "1120px" }}>
          <div className="row">
            <div className="col-md-12">
              <div className="container ">
                <h3
                  style={{
                    fontWeight: "600",
                    lineHeight: "1.2em",
                    fontFamily: "Playfair Display",
                    fontSize: "23px",
                    padding: "20px 0px 0px 0px",
                  }}
                >
                  استخدام الموقع
                </h3>
                <p>
                  لا يجوز لك استخدام الموقع إلا لأغراض قانونية ووفقا لهذه
                  الشروط. أنت توافق على عدم:
                </p>
                <ul>
                  <li
                    style={{
                      listStyleType: "disc",
                      lineHeight: "2em",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    استخدام الموقع بأي طريقة يمكن أن تلحق الضرر أو تعطل أو تثقل
                    كاهل أو تضعف الموقع أو أي خادم أو شبكة متصلة بالموقع.
                  </li>
                  <li
                    style={{
                      listStyleType: "disc",
                      lineHeight: "2em",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    محاولة الوصول غير المصرح به إلى الموقع أو أي جزء من الموقع
                    أو أي أنظمة أو شبكات أخرى متصلة بالموقع ، عن طريق القرصنة أو
                    التنقيب عن كلمة المرور أو أي وسيلة أخرى.
                  </li>
                  <li
                    style={{
                      listStyleType: "disc",
                      lineHeight: "2em",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    استخدام الموقع لجمع أو حصاد البيانات الشخصية عن المستخدمين
                    الآخرين.
                  </li>
                  <li
                    style={{
                      listStyleType: "disc",
                      lineHeight: "2em",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    نقل أي رسائل غير مرغوب فيها أو فيروسات أو رموز ضارة أخرى من
                    خلال الموقع.
                  </li>
                  <li
                    style={{
                      listStyleType: "disc",
                      lineHeight: "2em",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    التعدي على حقوق الملكية الفكرية لشركة Auto House أو أي طرف
                    ثالث.
                  </li>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    المحتوى والمعلومات{" "}
                  </h3>
                  <p>
                    المعلومات والمحتوى الموجود على الموقع الإلكتروني، بما في
                    ذلك، على سبيل المثال لا الحصر، النصوص والرسومات والصور
                    والشعارات والبيانات ("المحتوى")، هي ملك لشركة Auto House أو
                    مرخصيها وهي محمية بموجب حقوق النشر والعلامات التجارية
                    وقوانين الملكية الفكرية الأخرى. لا يجوز لك استخدام المحتوى
                    دون إذن كتابي صريح من Auto House.
                  </p>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    إخلاء المسؤولية عن الضمانات{" "}
                  </h3>
                  <p>
                    يتم توفير الموقع والمحتوى "كما هو" وبدون ضمانات من أي نوع ،
                    صريحة أو ضمنية. تخلي Auto House مسؤوليتها عن جميع الضمانات ،
                    بما في ذلك ، على سبيل المثال لا الحصر ، ضمانات القابلية
                    للتسويق والملاءمة لغرض معين والملكية وعدم الانتهاك. لا تضمن
                    Auto House أن الموقع الإلكتروني سيكون دون انقطاع أو خاليا من
                    الأخطاء، أو أنه سيتم تصحيح العيوب، أو أن الموقع الإلكتروني
                    أو الخادم الذي يوفره خال من الفيروسات أو المكونات الضارة
                    الأخرى.
                  </p>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    تحديد المسؤولية{" "}
                  </h3>
                  <p>
                    لن تكون أوتو هاوس مسؤولة عن أي أضرار تنشأ عن أو تتعلق
                    باستخدامك للموقع، بما في ذلك، على سبيل المثال لا الحصر،
                    الأضرار المباشرة أو غير المباشرة أو العرضية أو التبعية أو
                    العقابية أو الخاصة.
                  </p>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    التعويض
                  </h3>
                  <p>
                    أنت توافق على تعويض Auto House ومسؤوليها ومديريها وموظفيها
                    ووكلائها ومرخصيها وإبراء ذمتهم من وضد أي وجميع المطالبات
                    والخسائر والأضرار والمسؤوليات والتكاليف والنفقات (بما في ذلك
                    أتعاب المحاماة) الناشئة عن أو المتعلقة باستخدامك للموقع.
                  </p>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    القانون الحاكم والاختصاص القضائي{" "}
                  </h3>
                  <p>
                    تخضع هذه الشروط وتفسر وفقا لقوانين جمهورية مصر العربية، بغض
                    النظر عن تعارضها مع أحكام القانون. يجب رفع أي إجراء أو إجراء
                    قانوني ينشأ عن هذه الشروط أو يتعلق بها حصريا في محاكم دولة
                    جمهورية مصر العربية.
                  </p>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    الاتفاقية الكاملة
                  </h3>
                  <p>
                    تشكل هذه الشروط الاتفاقية الكاملة بينك وبين أوتو هاوس فيما
                    يتعلق باستخدامك للموقع.
                  </p>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    قابلية الفصل
                  </h3>
                  <p>
                    إذا تم اعتبار أي بند من هذه الشروط غير صالح أو غير قابل
                    للتنفيذ ، شطب هذا الحكم وتظل الأحكام المتبقية سارية المفعول
                    والتأثير الكامل.
                  </p>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    التنازل
                  </h3>
                  <p>
                    لا يعتبر أي تنازل عن أي حكم من هذه الشروط من قبل Auto House
                    تنازلا إضافيا أو مستمرا عن هذا الحكم أو أي حكم آخر.
                  </p>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    التعديلات على الشروط{" "}
                  </h3>
                  <p style={{ marginBottom: "revert" }}>
                    تحتفظ Auto House بالحق في تعديل هذه الشروط في أي وقت دون
                    إشعار مسبق. سنقوم بنشر أي تغييرات على هذه الصفحة. نشجعك على
                    مراجعة هذه الشروط بشكل دوري للبقاء على اطلاع بأي تحديثات.
                  </p>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

TermsAndConditionsAr.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
};

export default TermsAndConditionsAr;
