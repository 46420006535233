import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { setCurrency } from "../../../store/slices/currency-slice"

const LanguageCurrencyChanger = ({ setAr }) => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const changeLanguageTrigger = (e) => {
    const languageCode = e.target.value
    i18n.changeLanguage(languageCode)
  }

  const setCurrencyTrigger = (e) => {
    const currencyName = e.target.value
    dispatch(setCurrency(currencyName))
  }

  return (
    <div className="language-currency-wrap">
      <div className="same-language-currency language-style">
        <span>
          {localStorage.getItem("lang") === "eng" ? "English" : "Arabic"}
          <i className="fa fa-angle-down" />
        </span>
        <div className="lang-car-dropdown">
          <ul>
            <li>
              <button
                onClick={() => {
                  setAr("eng")
                  localStorage.setItem("lang", "eng")
                }}
              >
                English
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  setAr("egp")
                  localStorage.setItem("lang", "egp")
                }}
              >
                Arabic
              </button>
            </li>
          </ul>
        </div>
      </div>
      {/* <div className="same-language-currency use-style">
        <span>
          {currency.currencyName} <i className="fa fa-angle-down" />
        </span>
        <div className="lang-car-dropdown">
          <ul>
            <li>
              <button value="USD" onClick={e => setCurrencyTrigger(e)}>
                USD
              </button>
            </li>
            <li>
              <button value="EUR" onClick={e => setCurrencyTrigger(e)}>
                EUR
              </button>
            </li>
            <li>
              <button value="GBP" onClick={e => setCurrencyTrigger(e)}>
                GBP
              </button>
            </li>
          </ul>
        </div>
      </div> */}
      <div className="same-language-currency">
        <p>
          <a href="tel:01222185218">Call Us +20 122 2185218</a>{" "}
        </p>
        {/* <p>Call Us +20 122 2185218</p> */}
      </div>
    </div>
  )
}

LanguageCurrencyChanger.propTypes = {
  currency: PropTypes.shape({}),
}

export default LanguageCurrencyChanger
