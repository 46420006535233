import React, { useEffect, useState, Fragment } from "react"
import { Row, Col, Card, Container } from "react-bootstrap"
import "@fullcalendar/react"
import { DateClickArg } from "@fullcalendar/interaction"
import { EventClickArg, EventInput } from "@fullcalendar/core"
import axios from "axios"
import SEO from "../../components/seo"
import LayoutOne from "../../layouts/LayoutOne"
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb"
// import PageTitle from "../../../components/PageTitle";
import { useParams, useLocation } from "react-router-dom"

import CalendarComponent from "./CalendarComponent"
import AddEditBook from "./AddEditBook"

// import { APICore } from "../../../helpers/api/apiCore";

const CalendarApp = () => {
  let { pathname } = useLocation()

  /*
   * modal handling
   */
  const userDataString = sessionStorage.getItem("user")
  const userData = userDataString ? JSON.parse(userDataString) : null
  const userAccess = userData?.access?.events

  //   const canAddEvents = userAccess?.add;

  const [show, setShow] = useState(false)
  const onCloseModal = () => {
    setError(null)
    setShow(false)
    setEventData({})
    setDateInfo({})
    setIsEditable(false)
  }
  const onOpenModal = () => setShow(true)
  const [isEditable, setIsEditable] = useState(false)

  /*
   * event data
   */
  const [events, setEvents] = useState([])
  const [eventData, setEventData] = useState({})
  const [dateInfo, setDateInfo] = useState({})
  const [error, setError] = useState()
  const baseUrl = "/event"
  //   const api = new APICore();
  const onDateClick = (arg) => {
    console.log(arg)
    const clickedDate = new Date(arg.date)
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    if (clickedDate >= today) {
      setDateInfo(arg)
      onOpenModal(true)
      setIsEditable(false)
    } else {
      setError("Cannot add events to past dates.")
    }
  }

  //   useEffect(() => {
  //     axios
  //       .get(`https://autohouse.onrender.com`, null)
  //       .then((response) => {
  //         console.log(response);
  //         const responseData = response.data.response;
  //         var eventsY = [];
  //         for (let i = 0; i < responseData.length; i++) {
  //           const event = {
  //             id: responseData[i]._id,
  //             title: responseData[i].title,
  //             start: responseData[i].startDate,
  //             end: responseData[i].endDate,
  //             className: responseData[i].color ? responseData[i].color.value : "",
  //             color: responseData[i].color ? responseData[i].color.value : "",
  //             backgroundColor: responseData[i].color ? responseData[i].color.value : "",

  //             type: responseData[i].color ? responseData[i].color.value : "",
  //             description: responseData[i].description,
  //             participants: responseData[i].participants,
  //           };
  //           eventsY.push(event);
  //         }
  //         console.log(eventsY);
  //         setEvents(eventsY);
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //       });
  //   }, []);

  /*
   calendar events
   */
  // on date click
  // const onDateClick = (arg: DateClickArg) => {
  //     setDateInfo(arg)
  //     onOpenModal()
  //     setIsEditable(false)
  // }

  // on event click
  const onEventClick = (arg) => {
    // console.log(arg);
    // var event = {
    //   id: String(arg.event.id),
    //   title: arg.event.title,
    //   background: arg.event.classNames[0],
    //   color: arg.event.classNames[0],

    //   start: arg.event._instance?.range.start,
    //   end: arg.event._instance?.range.end,
    //   type: arg.event._def.extendedProps.type,
    //   description: arg.event._def.extendedProps.description,
    //   participants: arg.event._def.extendedProps.participants,
    // };
    // console.log(event);
    // setEventData(event);
    setIsEditable(true)
    onOpenModal(true)
  }

  /*
  on add event 
  */
  const onAddEvent = (data) => {
    const modifiedEvents = [...events]
    var startDate = data.startDate
    var endDate = data.endDate
    startDate.setDate(dateInfo.date.getDate())
    startDate.setMonth(dateInfo.date.getMonth())
    startDate.setFullYear(dateInfo.date.getFullYear())

    endDate.setDate(dateInfo.date.getDate())
    endDate.setMonth(dateInfo.date.getMonth())
    endDate.setFullYear(dateInfo.date.getFullYear())
    var event = {
      id: "",
      title: data.title,
      start: startDate,
      end: endDate,
      description: data.description,
      className: data.type,
      color: data.color,
      background: data.type,
      type: data.type,
      participants: data.participants,
      issuer: data.issuer,
    }
    const eventBackend = {
      title: data.title,
      startDate,
      endDate,
      description: data.description,
      color: data.color,
      className: data.type,

      background: data.type,
      type: data.type,
      participants: data.participants,
      issuer: data.issuer,
    }
    axios
      .post(`https://autohouse.onrender.com/book/add_book`, eventBackend)
      .then((response) => {
        event.id = response.data.id
        modifiedEvents.push(event)
        // setEvents(modifiedEvents);
        onCloseModal()
      })
      .catch((e) => {
        setError(e)
      })
  }

  /*
  on update event
  */
  const onUpdateEvent = (data) => {
    const modifiedEvents = [...events]
    var startDate = data.startDate
    var endDate = data.endDate
    // startDate.setDate(new Date(eventData.start?.toString()!).getDate());
    // startDate.setMonth(new Date(eventData.start?.toString()!).getMonth());
    // startDate.setFullYear(
    //   new Date(eventData.start?.toString()!).getFullYear()
    // );

    // endDate.setDate(new Date(eventData.end?.toString()!).getDate());
    // endDate.setMonth(new Date(eventData.end?.toString()!).getMonth());
    // endDate.setFullYear(new Date(eventData.end?.toString()!).getFullYear());

    const eventBackend = {
      id: data.id,
      title: data.title,
      startDate,
      endDate,
      description: data.description,
      color: data.color,
      background: data.color,
      className: data.type,

      type: data.type,
      participants: data.participants,
      issuer: data.issuer,
    }
    console.log(data.color)
    axios
      .patch(`${baseUrl}/updateEvent`, eventBackend)
      .then((response) => {
        const idx = modifiedEvents.findIndex((e) => e["id"] === eventData.id)
        modifiedEvents[idx]["title"] = data.title
        modifiedEvents[idx]["className"] = data.color
        modifiedEvents[idx]["color"] = "white"
        setEvents(modifiedEvents)
        onCloseModal()
        setIsEditable(false)
      })
      .catch((e) => {
        setError(e)
      })
  }

  /*
  on remove event
  */
  const onRemoveEvent = () => {
    var modifiedEvents = [...events]

    axios
      .delete(`${baseUrl}/deleteEvent/${eventData.id}`)
      .then((response) => {
        const idx = modifiedEvents.findIndex((e) => e["id"] === eventData.id)
        modifiedEvents.splice(idx, 1)
        setEvents(modifiedEvents)
        onCloseModal()
      })
      .catch((e) => {
        setError(e)
      })
  }

  return (
    <>
      {/* <PageTitle
        breadCrumbItems={[
          { label: "Apps", path: "/apps/calendar" },
          { label: "Calendar", path: "/apps/calendar", active: true },
        ]}
        title={"Calendar"}
      /> */}
      <Fragment>
        <SEO titleTemplate="Calendar" description="Calendar of Auto House." />

        <LayoutOne headerTop="visible">
          {/* breadcrumb */}
          <Breadcrumb
            pages={[
              { label: "Home", path: process.env.PUBLIC_URL + "/" },
              { label: "Calendar", path: process.env.PUBLIC_URL + pathname },
            ]}
          />
          <Container className="pt-5 pb-5">
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    {/* {console.log(events)} */}
                    {/* fullcalendar control */}
                    <CalendarComponent
                      onDateClick={onDateClick}
                      onEventClick={onEventClick}
                      events={events}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
          {show && (
            <AddEditBook
              error={error}
              isOpen={show}
              onClose={onCloseModal}
              isEditable={isEditable}
              eventData={eventData}
              onUpdateEvent={onUpdateEvent}
              onRemoveEvent={onRemoveEvent}
              onAddEvent={onAddEvent}
            />
          )}{" "}
        </LayoutOne>
      </Fragment>
    </>
  )
}

export default CalendarApp
