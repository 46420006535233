import PropTypes from "prop-types"
import { Fragment, useState } from "react"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import clsx from "clsx"
import { getDiscountPrice } from "../../helpers/product"
import ProductModal from "./ProductModal"
import axios from "axios"
import special from "../../../src/assets/special (1).png"
import usedByAdmin from "../../../src/assets/special (2).png"
import { addToCart } from "../../store/slices/cart-slice"
// import { addToWishlist } from "../../store/slices/wishlist-slice";
import { addToCompare } from "../../store/slices/compare-slice"
import { isMobile } from "react-device-detect"

const ProductGridSingleThree = ({
  product,
  currency,
  cartItem,
  wishlistItem,
  compareItem,
  spaceBottomClass,
  reload,
  setReload,
}) => {
  const [modalShow, setModalShow] = useState(false)
  const [loading, setLoading] = useState(false)

  const discountedPrice = getDiscountPrice(product.price, product.discount)
  const finalProductPrice = +(product.price * currency.currencyRate).toFixed(2)
  const finalDiscountedPrice = +(
    discountedPrice * currency.currencyRate
  ).toFixed(2)
  const dispatch = useDispatch()
  const TOKEN = sessionStorage.getItem("token")

  const addToWishlist = async (id) => {
    setLoading(true)

    try {
      const response = await axios
        .patch(
          "https://autohouse.onrender.com/customer/update_customer_wichlist",
          { wishlist: id },
          {
            headers: {
              token: `${TOKEN}`,
            },
          }
        )
        .then((response) => {
          setReload(!reload)
          setLoading(false)
        })
        .catch((e) => {
          setLoading(false)
          console.log("Error fetching data:", e)
        })
      // setFilteredData(response.data.result);
      // console.log(response.data)
    } catch (error) {
      setLoading(false)
      console.log("Error fetching data:", error)
    }
  }
  const deleteFromWishlist = (id) => {
    axios
      .patch(
        `https://autohouse.onrender.com/customer/update_customer_remove_wichlist`,
        {
          wishlist: id,
        },
        {
          headers: {
            token: TOKEN,
          },
        }
      )
      .then((res) => {
        console.log(res)

        // window.location.reload()
        setReload(!reload)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  return (
    <Fragment>
      <div
        className={clsx("product-wrap-2 pro-glaucous-color", spaceBottomClass)}
      >
        <div className="product-img">
          <Link to={process.env.PUBLIC_URL + "/product/" + product.id}>
            {product.images && product.images.length > 0 ? (
              <>
                <img
                  style={{ height: "20vh" }}
                  className="default-img"
                  src={process.env.PUBLIC_URL + product.images[0].image}
                  alt=""
                />
                {product.images.length > 1 ? (
                  <img
                    style={{ height: "20vh" }}
                    className="hover-img"
                    src={process.env.PUBLIC_URL + product.images[1].image}
                    alt=""
                  />
                ) : null}
              </>
            ) : (
              <p>No Image Available</p>
            )}
          </Link>
          {/* {product.discount || product.new ? ( */}
          <div className="product-img-badges">
            {product.specal ? (
              <img
                src={special}
                style={{ height: "6vh", width: isMobile ? "12vw" : "4vw" }}
              />
            ) : (
              ""
            )}

            {product.old_by_admin && product.specal ? (
              ""
            ) : product.old_by_admin ? (
              <img
                src={usedByAdmin}
                style={{ height: "6vh", width: isMobile ? "12vw" : "4vw" }}
              />
            ) : (
              ""
            )}
          </div>
          {/* // ) : (
          //   ""
          // )} */}

          <div className="product-action-2">
            <button onClick={() => setModalShow(true)} title="Quick View">
              <i className="fa fa-eye"></i>
            </button>
          </div>
        </div>
        <div className="product-content-2">
          <div className="title-price-wrap-2" style={{ width: "100%" }}>
            <h3 style={{ fontWeight: "600" }}>
              <Link to={process.env.PUBLIC_URL + "/product/" + product.id}>
                {product.make} {product.model}
              </Link>
            </h3>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="price-2">
                <Fragment>
                  <span style={{ color: "red", fontWeight: "600" }}>
                    {finalProductPrice === 0 ? (
                      "Negotiable"
                    ) : (
                      <>
                        {finalProductPrice.toLocaleString()}{" "}
                        <span style={{ fontSize: "10px", fontWeight: "400" }}>
                          EGP
                        </span>
                      </>
                    )}
                  </span>{" "}
                </Fragment>
              </div>
              <div className="price-2">
                <Fragment>
                  <span>{product.trim}</span>{" "}
                </Fragment>
              </div>
            </div>
            <div
              className="pro-details-rating-wrap"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div
                className="pro-details-rating"
                style={{ paddingRight: "10px" }}
              >
                <i
                  className="fa-solid fa-gauge-high"
                  style={{ color: "red" }}
                ></i>{" "}
                {product.kilometers.toLocaleString()} KM
              </div>
              <div
                className="pro-details-rating"
                style={{ paddingRight: "10px" }}
              >
                <i
                  className="fa-regular fa-calendar"
                  style={{ color: "red" }}
                ></i>{" "}
                {product.year}
              </div>
              <div
                className="pro-details-rating"
                style={{ paddingRight: "10px" }}
              >
                <i className="fa-solid fa-gears" style={{ color: "red" }}></i>{" "}
                {product.transmission}
              </div>
              {/* <div className="pro-details-rating" style={{    paddingRight: "10px"}}>
          <i className="fa-solid fa-gears" style={{color:'red'}}></i>  {product.trim}
          </div> */}
            </div>
          </div>
          {sessionStorage.getItem("loggedIn") === "true" ? (
            <div className="pro-wishlist-2">
              <button
                className={wishlistItem !== undefined ? "active" : ""}
                // disabled={wishlistItem !== undefined}
                title={
                  wishlistItem !== undefined
                    ? "Added to wishlist"
                    : "Add to wishlist"
                }
                onClick={() => {
                  console.log(wishlistItem)
                  if (wishlistItem !== undefined) deleteFromWishlist(product.id)
                  else addToWishlist(product.id)
                }}
              >
                {loading ? (
                  <i className="fas fa-spinner fa-spin"></i>
                ) : (
                  <i className="fa fa-heart-o" />
                )}
              </button>
            </div>
          ) : (
            <div className="pro-wishlist-2">
              <button
                className={wishlistItem !== undefined ? "active" : ""}
                // disabled={wishlistItem !== undefined}
              >
                <Link to={process.env.PUBLIC_URL + "/login-register"}>
                  {" "}
                  <i className="fa fa-heart-o" />
                </Link>
              </button>
            </div>
          )}
        </div>
      </div>
      {/* product modal */}
      <ProductModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        product={product}
        currency={currency}
        discountedPrice={discountedPrice}
        finalProductPrice={finalProductPrice}
        finalDiscountedPrice={finalDiscountedPrice}
        wishlistItem={wishlistItem}
        compareItem={compareItem}
      />
    </Fragment>
  )
}

ProductGridSingleThree.propTypes = {
  cartItem: PropTypes.shape({}),
  compareItem: PropTypes.shape({}),
  currency: PropTypes.shape({}),
  product: PropTypes.shape({}),
  spaceBottomClass: PropTypes.string,
  wishlistItem: PropTypes.shape({}),
  setReload: PropTypes.bool,
  reload: PropTypes.bool,
}

export default ProductGridSingleThree
