import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
// import { setActiveSort } from "../../helpers/product";
import * as Accordion from "@radix-ui/react-accordion"
import { ChevronDownIcon } from "@radix-ui/react-icons"
import classNames from "classnames"
import Select from "react-select"
import axios from "axios"
import Slider from "@mui/material/Slider"
import Typography from "@mui/material/Typography"
import "./style.css"
const AccordionTrigger = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Header className="AccordionHeader">
      <Accordion.Trigger
        className={classNames("AccordionTrigger", className)}
        {...props}
        ref={forwardedRef}
      >
        {children}
        <ChevronDownIcon className="AccordionChevron" aria-hidden />
      </Accordion.Trigger>
    </Accordion.Header>
  )
)

const AccordionContent = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Content
      className={classNames("AccordionContent", className)}
      {...props}
      ref={forwardedRef}
    >
      <div className="AccordionContentText">{children}</div>
    </Accordion.Content>
  )
)
const setActiveSort = (e) => {
  if (e.currentTarget) {
    const filterButtons = document.querySelectorAll(
      ".sidebar-widget-list-left button, .sidebar-widget-tag button, .product-filter button"
    )
    filterButtons.forEach((item) => {
      item.classList.remove("active")
    })
    e.currentTarget.classList.add("active")
  }
}
const ShopPrice = ({ onFilterChange }) => {
  const [filteredData, setFilteredData] = useState({})

  const [value, setValue] = useState([50, 200]) // Set initial values for the price range
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://autohouse.onrender.com/car/show_car_prices"
      )
      setFilteredData(response.data)
      console.log(response.data)
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    fetchData() // Fetch data when the component mounts
  }, [])
  useEffect(() => {
    if (filteredData && filteredData.Highest && filteredData.Lowest) {
      setValue([filteredData.Lowest, filteredData.Highest])
    }
  }, [filteredData])
  const handleChange = (event, newValue) => {
    setValue(newValue)
    onFilterChange("pricefrom", newValue[0])
    onFilterChange("priceto", newValue[1])
  }

  const valuetext = (value) => {
    return `$${Math.floor(value)}`
  }

  return (
    <div className="sidebar-widget mt-40">
      <Accordion.Root
        className="AccordionRoot"
        type="single"
        defaultValue="item-1"
        collapsible
      >
        <Accordion.Item className="AccordionItem" value="item-1">
          <AccordionTrigger className="pro-sidebar-title">
            Price Range
          </AccordionTrigger>

          <AccordionContent className="sidebar-widget-list ">
            <Typography gutterBottom>Price Range</Typography>
            <Slider
              getAriaLabel={() => "Price range"}
              value={value}
              onChange={handleChange}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
              min={filteredData.Lowest || 0}
              max={filteredData.Highest || 500} // Set your desired maximum price
            />
          </AccordionContent>
        </Accordion.Item>
      </Accordion.Root>
    </div>
  )
}

ShopPrice.propTypes = {
  getSortParams: PropTypes.func,
  sizes: PropTypes.array,
}

export default ShopPrice
