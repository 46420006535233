import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import FooterCopyright from "../../components/footer/FooterCopyright";
import FooterNewsletter from "../../components/footer/FooterNewsletter";
import playStore from "../../play.png";
import appStore from "../../appStore.png";
import { isMobile } from "react-device-detect";
const FooterOne = ({
  backgroundColorClass,
  spaceTopClass,
  spaceBottomClass,
  spaceLeftClass,
  spaceRightClass,
  containerClass,
  extraFooterClass,
  sideMenu,
}) => {
  return (
    <footer
      className={clsx(
        "footer-area",
        backgroundColorClass,
        spaceTopClass,
        extraFooterClass,
        spaceLeftClass,
        spaceRightClass
      )}
      // style={{zIndex:'-1'}}
    >
      <div className={`${containerClass ? containerClass : "container"}`}>
        <div className="row">
          {isMobile && (
            <div
              style={{ display: "flex", justifyContent: "center" }}
              // className={`${
              //   sideMenu ? "col-xl-3 col-sm-8" : "col-lg-3 col-sm-6"
              // }`}
            >
              <div
                className={`${
                  sideMenu
                    ? "footer-widget mb-10 ml-145"
                    : "footer-widget mb-10 ml-75"
                }`}
              >
                <div className="footer-title" style={{ textAlign: "center" }}>
                  <h3>DOWNLOAD ON</h3>
                </div>

                <ul
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <li>
                    {" "}
                    <img src={playStore} style={{ width: "80%" }} />{" "}
                  </li>
                  <li>
                    {" "}
                    <img
                      src={appStore}
                      style={{ width: "80%", marginTop: "2%" }}
                    />{" "}
                  </li>
                </ul>

                {/* <FooterNewsletter
              spaceBottomClass="mb-30"
              spaceLeftClass="ml-70"
              sideMenu={sideMenu}
            /> */}
              </div>
            </div>
          )}

          {/* <div
            className={`${
              sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-4"
            }`}
          >
            <div className="footer-widget mb-30 ml-30">
              <div className="footer-title">
                <h3>ABOUT US</h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/about"}>About us</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>
                      Store location
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/contact"}>
                      Contact
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>
                      Orders tracking
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          {!isMobile && (
            <div
              className={`${
                sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-4"
              }`}
            >
              {/* footer copyright */}
              <FooterCopyright
                footerLogo="/assets/img/logo/logo.png"
                spaceBottomClass="mb-30"
              />
            </div>
          )}
          <div
            className={`${
              sideMenu ? "col-xl-5 col-sm-4" : "col-lg-5 col-sm-4"
            }`}
            style={{ textAlign: isMobile && "center" }}
          >
            <div
              className={`${
                sideMenu
                  ? "footer-widget mb-30 ml-95"
                  : `footer-widget mb-${isMobile ? 10 : 30} ml-50 mr-${
                      isMobile && 30
                    }`
              }`}
            >
              <div className="footer-title">
                <h3>USEFUL LINKS</h3>
              </div>
              <div className="footer-list row">
                <div className="col">
                  {" "}
                  <ul>
                    <li>
                      <Link
                        to={process.env.PUBLIC_URL + "/shop-grid-full-width"}
                      >
                        Cars
                      </Link>
                    </li>

                    <li>
                      <Link to={process.env.PUBLIC_URL + "/blog-no-sidebar"}>
                        Blogs
                      </Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/about"}>
                        About us
                      </Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/dealer"}>
                        Dealers
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col">
                  <ul>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/contact"}>
                        Contact us
                      </Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/policy"}>
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/terms"}>
                        Terms and Conditions
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${
              sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-6"
            }`}
            style={{ textAlign: isMobile && "center" }}
          >
            <div
              className={`${
                sideMenu
                  ? "footer-widget mb-30 ml-145"
                  : `footer-widget mb-${isMobile ? 10 : 30} `
              }`}
            >
              <div className="footer-title">
                <h3>FOLLOW US</h3>
              </div>
              <div className="footer-list">
                <ul style={{ display: "flex", marginTop: "4%" }}>
                  <li style={{ marginRight: "9%" }}>
                    <a
                      href="https://www.facebook.com/nabilassem1966?mibextid=ibOpuV"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-facebook fs-5" />
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a
                      onClick={() => {
                        window.open(`https://wa.me/+201222185218`);
                      }}
                      target="_blank"
                    >
                      <i className="fa fa-whatsapp fs-5" />
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="//www.twitter.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a
                      href="//www.instagram.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a
                      href="//www.youtube.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Youtube
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          {isMobile && (
            <div
              className={`${
                sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-4"
              }`}
              style={{ textAlign: "center" }}
            >
              {/* footer copyright */}
              <FooterCopyright
                footerLogo="/assets/img/logo/logo.png"
                spaceBottomClass="mb-30"
              />
            </div>
          )}
          {!isMobile && (
            <div
              style={{ display: "flex", justifyContent: "center" }}
              className={`${
                sideMenu ? "col-xl-3 col-sm-8" : "col-lg-3 col-sm-6"
              }`}
            >
              <div
                className={`${
                  sideMenu
                    ? "footer-widget mb-30 ml-145"
                    : "footer-widget mb-30 "
                }`}
              >
                <div
                  className="footer-title"
                  style={{ textAlign: isMobile && "center" }}
                >
                  <h3>DOWNLOAD ON</h3>
                </div>

                <ul
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <li>
                    {" "}
                    <img src={playStore} style={{ width: "70%" }} />{" "}
                  </li>
                  <li>
                    {" "}
                    <img
                      src={appStore}
                      style={{ width: "70%", marginTop: "2%" }}
                    />{" "}
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </footer>
  );
};

FooterOne.propTypes = {
  backgroundColorClass: PropTypes.string,
  containerClass: PropTypes.string,
  extraFooterClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string,
};

export default FooterOne;
