import PropTypes from "prop-types"
import clsx from "clsx"
import {
  getIndividualCategories,
  getIndividualTags,
  getIndividualColors,
  getProductsIndividualSizes,
} from "../../helpers/product"
import ShopSearch from "../../components/product/ShopSearch"
import ShopCategories from "../../components/product/ShopCategories"
import ShopColor from "../../components/product/ShopColor"
import ShopSize from "../../components/product/ShopSize"
import ShopTag from "../../components/product/ShopTag"
import ShopKilometers from "../../components/product/ShopKilometers"
import ShopBodyStyle from "../../components/product/ShopBodyStyle"
import ShopTransmission from "../../components/product/ShopTransmission"
import ShopFuelType from "../../components/product/ShopFuelType"
import ShopCC from "../../components/product/ShopCC"
import ShopPrice from "../../components/product/ShopPrice"
import { useState, useEffect } from "react"
import axios from "axios"
const ShopSidebar = ({
  brandID,
  bodyStyle,
  brand,
  model,
  sideSpaceClass,
  onFilterChange,
  total_pages,
  setTotalPages,
  selectedFilters,
  setSelectedFilters,
}) => {
  // console.log(onFilterChange)
  const [FilteredProducts, setFilteredProducts] = useState([])
  const [activeBrand, setActiveBrand] = useState(null)
  const [activeModel, setActiveModel] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  // console.log(bodyStyle)
  // const [total_pages, setTotalPages] = useState(1);
  const displayPages = 5
  const halfDisplay = Math.floor(displayPages / 2)
  let startPage = Math.max(1, currentPage - halfDisplay)
  let endPage = Math.min(total_pages, startPage + displayPages - 1)

  if (endPage - startPage + 1 < displayPages) {
    startPage = Math.max(1, endPage - displayPages + 1)
  }

  const handleBrandChange = (selectedBrand) => {
    const brandValue = selectedBrand || "" // Use an empty string if selectedBrand is null
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      brand: brandValue,
    }))
    setActiveBrand(selectedBrand)
  }

  const handleModelChange = (selectedModel) => {
    const modelValue = selectedModel || "" // Use an empty string if selectedModel is null
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      model: modelValue,
    }))
    console.log(selectedFilters.model)
    setActiveModel(selectedModel)
  }

  const handleFilterChange = (filterType, selectedValues) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      ...selectedFilters,
      [filterType]: selectedValues,
    }))
    if (filterType === "brand") {
      handleBrandChange(selectedValues)
    } else if (filterType === "model") {
      handleModelChange(selectedValues)
    }
  }

  const handleSearchChange = (updatedFilters) => {
    setSelectedFilters(updatedFilters)
  }

  const handleEndpointCall = async () => {
    try {
      // Construct the URL parameters
      const urlParams = Object.entries(selectedFilters)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&&")

      // Make the endpoint call with Axios
      const response = await axios.get(
        `https://autohouse.onrender.com/car/show_all_cars?${urlParams}&&page=${currentPage}`
      )

      // Process the response data as needed
      console.log("Response:", response.data)
      const filteredProducts = response.data.result
      setTotalPages(response.data.total_pages)

      // Pass the selected filters to the parent component
      onFilterChange(filteredProducts)
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  const handleResetFilters = async () => {
    window.location.reload()
  }

  return (
    <div className={clsx("sidebar-style", sideSpaceClass)}>
      <div className="shop-top-bar mb-15">
        <div className="select-shoing-wrap">
          <div className="shop-select">
            <select
              onChange={(e) => handleFilterChange("sort", e.target.value)}
              value={selectedFilters.sort}
            >
              <option value="Most Recent">Most Recent</option>
              <option value="Highest Price">Highest Price</option>
              <option value="Lowest Price">Lowest Price</option>
              <option value="Highest Year">Highest Year</option>
              <option value="Lowest Year">Lowest Year</option>
              <option value="Highest Kilometers">Highest Kilometers</option>
              <option value="Lowest Kilometers">Lowest Kilometers</option>
            </select>
          </div>
        </div>{" "}
      </div>{" "}
      {/* shop search */}
      <ShopSearch onSearchChange={handleSearchChange} />
      {/* filter by status */}
      <ShopCategories
        onFilterChange={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        categories={["special", "new", "old_by_admin", "old"]}
      />
      {/* filter by brands and model */}
      <ShopColor
        onFilterChange={(filterType, selectedValues) =>
          handleFilterChange(filterType, selectedValues)
        }
        brandID={brandID}
        brand={brand}
        model={model}
      />
      {/* filter by Year */}
      <ShopSize
        onFilterChange={(filterType, selectedValues) =>
          handleFilterChange(filterType, selectedValues)
        }
      />
      {/* filter by Kilometers */}
      <ShopKilometers
        onFilterChange={(filterType, selectedValue) =>
          handleFilterChange(filterType, selectedValue)
        }
      />
      {/* filter by Body Style */}
      <ShopBodyStyle
        onFilterChange={handleFilterChange}
        bodyStyle={bodyStyle}
      />
      {/* filter by Transmission */}
      <ShopTransmission onFilterChange={handleFilterChange} />
      {/* filter by Fuel Type */}
      {/* <ShopFuelType onFilterChange={handleFilterChange} /> */}
      {/* filter by CC */}
      <ShopCC onFilterChange={handleFilterChange} />
      <ShopPrice onFilterChange={handleFilterChange} />
      <div
        style={{
          position: "sticky",
          bottom: "0",
          backgroundColor: "#fff",
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          onClick={handleResetFilters}
          style={{ color: "red", textAlign: "center", marginLeft: "10px" }}
        >
          <i class="fa-solid fa-rotate-left"></i> Reset
        </button>

        <div
          className="sticky-button"
          style={{
            backgroundColor: "red",
            padding: "10px",
            paddingLeft: "7%",
            paddingRight: "7%",
            textAlign: "center",
            borderRadius: "20px",
          }}
          onClick={handleEndpointCall}
        >
          <button style={{ color: "#fff", textAlign: "center" }}>
            Apply Filters
          </button>
        </div>
      </div>
    </div>
  )
}

ShopSidebar.propTypes = {
  getSortParams: PropTypes.func,
  products: PropTypes.array,
  sideSpaceClass: PropTypes.string,
  brandID: PropTypes.any,
}

export default ShopSidebar
