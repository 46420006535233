import PropTypes from "prop-types"
import clsx from "clsx"
import React, { useState, useEffect } from "react"

import Swiper, { SwiperSlide } from "../../components/swiper"
import categoryData from "../../data/category/category-one.json"
import CategoryOneSingle from "../../components/category/CategoryOneSingle.js"
import axios from "axios"
import CategoryOneSingleAr from "../../components/category/CategoryOneSingleAr.jsx"

// swiper slider settings
const settings = {
  loop: false,
  spaceBetween: 30,
  breakpoints: {
    320: {
      slidesPerView: 1,
    },
    576: {
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 3,
    },
  },
}

const CategoryOneSliderAr = ({ spaceBottomClass }) => {
  const [filteredData, setFilteredData] = useState([])

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://autohouse.onrender.com/service/show_all_services"
      )
      setFilteredData(response.data.result)
      console.log(response.data.result)
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    fetchData() // Fetch data when the component mounts
  }, [])
  return (
    <div className={clsx("collections-area", spaceBottomClass)}>
      <div className="container">
        <div className="collection-wrap-2">
          <div className="collection-active-2">
            {filteredData && (
              <Swiper options={settings}>
                {filteredData.map((single, key) => (
                  <SwiperSlide key={key} style={{ paddingBottom: "2%" }}>
                    <CategoryOneSingleAr data={single} />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

CategoryOneSliderAr.propTypes = {
  spaceBottomClass: PropTypes.string,
}

export default CategoryOneSliderAr
