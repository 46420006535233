import { Fragment } from "react"
import { useSelector } from "react-redux"
import { useParams, useLocation } from "react-router-dom"
import SEO from "../../components/seo"
import LayoutOne from "../../layouts/LayoutOne"
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb"
import RelatedProductSlider from "../../wrappers/product/RelatedProductSlider"
import ProductDescriptionTab from "../../wrappers/product/ProductDescriptionTab"
import ProductImageDescriptionSlider from "../../wrappers/product/ProductImageDescriptionSlider"
import axios from "axios"
import React, { useEffect, useState } from "react"
import ProductImageDescriptionSliderAr from "../../wrappers/product/ProductImageDescriptionSliderAr"
const ProductSliderAr = () => {
  let { pathname } = useLocation()
  let { id } = useParams()
  const { products } = useSelector((state) => state.product)
  const product = products.find((product) => product.id === id)
  const [service, setService] = useState([])

  useEffect(() => {
    // Make an Axios request to fetch subjects from the API
    axios
      .get(`https://autohouse.onrender.com/service/show_one_service/${id}`, {
        headers: {
          lang: `AR`,
        },
      })
      .then((response) => {
        setService(response.data)
      })
      .catch((error) => {
        console.error("Error fetching subjects:", error)
      })
  }, [id])
  return (
    <Fragment>
      <SEO
        titleTemplate="Service Page"
        description="Service page of Auto House."
      />

      {/* <LayoutOne headerTop="visible"> */}
      {/* breadcrumb */}
      <Breadcrumb
        pages={[
          { label: "خدماتنا", path: process.env.PUBLIC_URL + pathname },
          { label: "الرئيسية", path: process.env.PUBLIC_URL + "/" },
        ]}
      />

      {/* product description with image */}
      <ProductImageDescriptionSliderAr
        spaceTopClass="pt-100"
        spaceBottomClass="pb-100"
        product={service}
      />

      {/* </LayoutOne> */}
    </Fragment>
  )
}

export default ProductSliderAr
