import PropTypes from "prop-types";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="contact-area " style={{ backgroundColor: "transparent" }}>
        <div className="container" style={{ maxWidth: "1120px" }}>
          <div className="row">
            <div className="col-md-12">
              <div className="container ">
                <h3
                  style={{
                    fontWeight: "600",
                    lineHeight: "1.2em",
                    fontFamily: "Playfair Display",
                    fontSize: "23px",
                    padding: "20px 0px 0px 0px",
                  }}
                >
                  Information We Collect
                </h3>
                <p>
                  We collect several types of information from and about users
                  of our website:
                </p>
                <ul>
                  <li
                    style={{
                      listStyleType: "disc",
                      lineHeight: "2em",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    Personal Information:
                    <p>
                      This includes information that can be used to identify
                      you, such as your name, email address, phone number, and
                      mailing address. We collect this information when you fill
                      out a contact form, schedule a test drive, apply for
                      financing, or create an account on our website.
                    </p>
                  </li>
                  <li
                    style={{
                      listStyleType: "disc",
                      lineHeight: "2em",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    Non-Personal Information:
                    <p>
                      This includes information that cannot be used to identify
                      you, such as your browsing history, IP address, device
                      type, and operating system. We collect this information
                      automatically when you visit our website.
                    </p>
                  </li>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    How We Use Your Information
                  </h3>
                  <p>
                    We use the information we collect for the following
                    purposes:
                  </p>
                  <li
                    style={{
                      listStyleType: "disc",
                      lineHeight: "2em",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    To process your requests and inquiries, such as scheduling a
                    test drive or providing a quote.
                  </li>
                  <li
                    style={{
                      listStyleType: "disc",
                      lineHeight: "2em",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    To process your requests and inquiries, such as scheduling a
                    test drive or providing a quote.
                  </li>
                  <li
                    style={{
                      listStyleType: "disc",
                      lineHeight: "2em",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    To send you marketing and promotional materials, such as
                    information about new vehicles or special offers (you can
                    opt-out of these communications at any time).
                  </li>
                  <li
                    style={{
                      listStyleType: "disc",
                      lineHeight: "2em",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    To improve our website and services.
                  </li>
                  <li
                    style={{
                      listStyleType: "disc",
                      lineHeight: "2em",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    To comply with legal and regulatory requirements.
                  </li>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    Sharing Your Information
                  </h3>
                  <p>
                    We may share your information with third-party service
                    providers who help us operate our website and provide our
                    services. These service providers are contractually
                    obligated to keep your information confidential and secure.
                    We will not share your information with any other third
                    party without your consent, except as required by law.
                  </p>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    Your Choices
                  </h3>
                  <p>
                    You can opt-out of receiving marketing and promotional
                    emails from us by following the unsubscribe instructions in
                    the emails you receive. You can also access, update, or
                    delete your personal information by contacting us at
                    [autoHouse@gmail.com].
                  </p>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    Data Security
                  </h3>
                  <p>
                    We take reasonable steps to protect your information from
                    unauthorized access, disclosure, alteration, or destruction.
                    However, no website or internet transmission is completely
                    secure. We cannot guarantee the security of your
                    information.
                  </p>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    Children
                  </h3>
                  <p>
                    Our website is not directed to children under the age of 13.
                    We do not knowingly collect personal information from
                    children under 13. If you are a parent or guardian and you
                    believe your child has provided us with personal
                    information, please contact us. We will delete any personal
                    information we have collected from children under 13.
                  </p>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    Changes to This Privacy Policy
                  </h3>
                  <p style={{marginBottom:"revert"}}>
                    We may update this Privacy Policy from time to time. We will
                    post any changes on this page. You are encouraged to review
                    this Privacy Policy periodically to stay informed of any
                    updates.
                  </p>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

PrivacyPolicy.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
};

export default PrivacyPolicy;
