import PropTypes from "prop-types"
import React, { Fragment, useState, useEffect } from "react"
import { useSelector } from "react-redux"
import ProductGridListSingle from "../../components/product/ProductGridListSingle"
import axios from "axios"

const ProductGridList = ({
  products,
  spaceBottomClass,
  reload,
  setReload,
  setProducts,
}) => {
  const currency = useSelector((state) => state.currency)
  const { cartItems } = useSelector((state) => state.cart)
  // const { wishlistItems } = useSelector((state) => state.wishlist);
  const { compareItems } = useSelector((state) => state.compare)
  const [wishlistItems, setFilteredData] = useState([])
  const TOKEN = sessionStorage.getItem("token")

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://autohouse.onrender.com/car/show_user_favorites",
        {
          headers: {
            token: `${TOKEN}`,
          },
        }
      )
      setFilteredData(response.data.result)
      console.log(response.data)
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    fetchData() // Fetch data when the component mounts
  }, [])
  return (
    <Fragment>
      {products?.map((product, index) => {
        return (
          <div className="col-xl-4 col-sm-6" key={product.id}>
            <ProductGridListSingle
              reload={reload}
              setReload={setReload}
              spaceBottomClass={spaceBottomClass}
              product={product}
              currentIndex={index}
              setProducts={setProducts}
              products={products}
              currency={currency}
              cartItem={cartItems.find(
                (cartItem) => cartItem.id === product.id
              )}
              wishlistItem={wishlistItems.find(
                (wishlistItem) => wishlistItem.id === product.id
              )}
              compareItem={compareItems.find(
                (compareItem) => compareItem.id === product.id
              )}
            />
          </div>
        )
      })}
    </Fragment>
  )
}

ProductGridList.propTypes = {
  products: PropTypes.array,
  setReload: PropTypes.bool,
  reload: PropTypes.bool,
  setProducts: PropTypes.array,
  spaceBottomClass: PropTypes.string,
}

export default ProductGridList
