import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const CategoryOneSingleAr = ({ data }) => {
  console.log(data)
  return (
    <div className="collection-product-2 carSlide"  style={{margin:'auto'}}>
      <Link  to={process.env.PUBLIC_URL + "/product-slider/" + data.id}>
        <img src={data.image[0].image} alt="" style={{height:'25vh'}} />
      </Link>
      <div className="collection-content-2 text-center " style={{margin:'auto'}}>
        <span  style={{color:'red',background:'#fff' ,padding:'5px',borderRadius:'5px'}}>{data.ar_name} </span>
        {/* <h4>
          <Link  to={process.env.PUBLIC_URL + "/product-slider/" + data.id}>{data.description}</Link>
        </h4> */}
        {/* <span  style={{color:'red'}}>{data.price} EGP</span> */}

      </div>
    </div>
  );
};

CategoryOneSingleAr.propTypes = {
  data: PropTypes.shape({})
};

export default CategoryOneSingleAr;
