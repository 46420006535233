import PropTypes from "prop-types"
import React, { Fragment, useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { getProducts } from "../../helpers/product"
import ProductGridSingleThree from "../../components/product/ProductGridSingleThree"
import axios from "axios"
import ProductGridSingleThreeAr from "../../components/product/ProductGridSingleThreeAr"

const ProductGridThreeAr = ({ spaceBottomClass, category, type, limit }) => {
  const categoryData = Array.isArray(category) ? category : []
  const [wishlistItems, setFilteredData] = useState([])
  const TOKEN = sessionStorage.getItem("token")

  const { products } = useSelector((state) => state.product)
  const currency = useSelector((state) => state.currency)
  const { cartItems } = useSelector((state) => state.cart)
  // const { wishlistItems } = useSelector((state) => state.wishlist);
  const { compareItems } = useSelector((state) => state.compare)
  // const prods = getProducts(products, category, type, limit);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://autohouse.onrender.com/car/show_user_favorites",
        {
          headers: {
            lang: "AR",
            token: `${TOKEN}`,
          },
        }
      )
      setFilteredData(response.data.result)
      console.log(response.data)
      
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }
  const [reload, setReload] = useState(false)

  useEffect(() => {
    fetchData() // Fetch data when the component mounts
  }, [reload])
  return (
    // <>
    // {console.log(category)}
    // </>
    <Fragment>
      {categoryData.map((product) => {
        return (
          <div className="custom2-col-5" key={product.id}>
            <ProductGridSingleThreeAr
              spaceBottomClass={spaceBottomClass}
              product={product}
              currency={currency}
              setReload={setReload}
              reload={reload}
              cartItem={cartItems.find(
                (cartItem) => cartItem.id === product.id
              )}
              wishlistItem={wishlistItems.find(
                (wishlistItem) => wishlistItem.id === product.id
              )}
              compareItem={compareItems.find(
                (compareItem) => compareItem.id === product.id
              )}
            />
          </div>
        )
      })}
    </Fragment>
  )
}

ProductGridThreeAr.propTypes = {
  spaceBottomClass: PropTypes.string,
  category: PropTypes.array, // Change the prop type to object
  type: PropTypes.string,
  limit: PropTypes.number,
}

export default ProductGridThreeAr
