import PropTypes from "prop-types";

const PrivacyPolicyAr = () => {
  return (
    <>
      <div className="contact-area " style={{ backgroundColor: "transparent" }}  dir="rtl">
        <div className="container" style={{ maxWidth: "1120px" }}>
          <div className="row">
            <div className="col-md-12">
              <div className="container ">
                <h3  
                  style={{
                    fontWeight: "600",
                    lineHeight: "1.2em",
                    fontFamily: "Playfair Display",
                    fontSize: "23px",
                    padding: "20px 0px 0px 0px",
                  }}
                >
                  المعلومات التي نجمعها{" "}
                </h3>
                <p>نقوم بجمع عدة أنواع من المعلومات من وعن مستخدمي موقعنا:</p>
                <ul>
                  <li
                    style={{
                      listStyleType: "disc",
                      lineHeight: "2em",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    المعلومات الشخصية:
                    <p>
                      يتضمن ذلك المعلومات التي يمكن استخدامها لتحديد هويتك ، مثل
                      اسمك وعنوان بريدك الإلكتروني ورقم هاتفك وعنوانك البريدي.
                      نقوم بجمع هذه المعلومات عند ملء نموذج اتصال أو جدولة
                      اختبار قيادة أو التقدم بطلب للحصول على تمويل أو إنشاء حساب
                      على موقعنا.
                    </p>
                  </li>
                  <li
                    style={{
                      listStyleType: "disc",
                      lineHeight: "2em",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    المعلومات غير الشخصية:{" "}
                    <p>
                      يتضمن ذلك المعلومات التي لا يمكن استخدامها لتحديد هويتك ،
                      مثل سجل التصفح وعنوان IP ونوع الجهاز ونظام التشغيل. نقوم
                      بجمع هذه المعلومات تلقائيا عند زيارتك لموقعنا.
                    </p>
                  </li>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    كيف نستخدم معلوماتك{" "}
                  </h3>
                  <p>نستخدم المعلومات التي نجمعها للأغراض التالية:</p>
                  <li
                    style={{
                      listStyleType: "disc",
                      lineHeight: "2em",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    لمعالجة طلباتك واستفساراتك ، مثل جدولة اختبار القيادة أو
                    تقديم عرض أسعار.
                  </li>
                  <li
                    style={{
                      listStyleType: "disc",
                      lineHeight: "2em",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    لتخصيص تجربتك على موقعنا ، مثل تذكر تفضيلات البحث الخاصة بك.
                  </li>
                  <li
                    style={{
                      listStyleType: "disc",
                      lineHeight: "2em",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    لإرسال مواد تسويقية وترويجية إليك ، مثل معلومات حول المركبات
                    الجديدة أو العروض الخاصة (يمكنك إلغاء الاشتراك في هذه
                    الاتصالات في أي وقت).
                  </li>
                  <li
                    style={{
                      listStyleType: "disc",
                      lineHeight: "2em",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    لتحسين موقعنا وخدماتنا.{" "}
                  </li>
                  <li
                    style={{
                      listStyleType: "disc",
                      lineHeight: "2em",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    للامتثال للمتطلبات القانونية والتنظيمية.{" "}
                  </li>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    مشاركة معلوماتك{" "}
                  </h3>
                  <p>
                    قد نشارك معلوماتك مع موفري خدمات تابعين لجهات خارجية
                    يساعدوننا في تشغيل موقعنا الإلكتروني وتقديم خدماتنا. يلتزم
                    مقدمو الخدمات هؤلاء تعاقديا بالحفاظ على سرية معلوماتك
                    وأمانها. لن نشارك معلوماتك مع أي طرف ثالث دون موافقتك ،
                    باستثناء ما يقتضيه القانون.
                  </p>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    اختياراتك
                  </h3>
                  <p>
                    يمكنك إلغاء الاشتراك في تلقي رسائل البريد الإلكتروني
                    التسويقية والترويجية منا باتباع تعليمات إلغاء الاشتراك في
                    رسائل البريد الإلكتروني التي تتلقاها. يمكنك أيضا الوصول إلى
                    معلوماتك الشخصية أو تحديثها أو حذفها عن طريق الاتصال بنا على
                    [autoHouse@gmail.com].
                  </p>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    أمن البيانات{" "}
                  </h3>
                  <p>
                    نتخذ خطوات معقولة لحماية معلوماتك من الوصول غير المصرح به أو
                    الكشف أو التغيير أو التدمير. ومع ذلك ، لا يوجد موقع ويب أو
                    نقل عبر الإنترنت آمن تماما. لا يمكننا ضمان أمن معلوماتك.
                  </p>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    أطفال
                  </h3>
                  <p>
                    موقعنا غير موجه للأطفال دون سن 18 عاما. نحن لا نجمع معلومات
                    شخصية عن قصد من الأطفال دون سن 18 عاما. إذا كنت أحد الوالدين
                    أو الوصي وتعتقد أن طفلك قد زودنا بمعلومات شخصية ، فيرجى
                    الاتصال بنا. سنحذف أي معلومات شخصية جمعناها من الأطفال دون
                    سن 18 عاما.
                  </p>
                  <h3
                    style={{
                      fontWeight: "600",
                      lineHeight: "1.2em",
                      fontFamily: "Playfair Display",
                      fontSize: "23px",
                      padding: "20px 0px 0px 0px",
                    }}
                  >
                    التغييرات التي تطرأ على سياسة الخصوصية هذه{" "}
                  </h3>
                  <p style={{ marginBottom: "revert" }}>
                    قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر. سنقوم بنشر أي
                    تغييرات على هذه الصفحة. نشجعك على مراجعة سياسة الخصوصية هذه
                    بشكل دوري للبقاء على اطلاع بأي تحديثات.
                  </p>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

PrivacyPolicyAr.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
};

export default PrivacyPolicyAr;
