import React, { useState, useEffect } from "react"

import PropTypes from "prop-types"

import clsx from "clsx"
import Tab from "react-bootstrap/Tab"
import Nav from "react-bootstrap/Nav"
import ProductGridThree from "./ProductGridThree"
import axios from "axios"
const TabProductThree = ({ spaceBottomClass, category }) => {
  const [isFilterVisible, setFilterVisible] = useState(true)
  const [filteredData, setFilteredData] = useState({})
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://autohouse.onrender.com/car/show_all_cars_user_home"
      )
      setFilteredData(response.data)
      console.log(response.data)
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    fetchData() // Fetch data when the component mounts
  }, [])
  console.log(filteredData)

  const handleNavClick = async (category) => {
    // Filter data based on the selected category
    const response = await axios.get(
      "https://autohouse.onrender.com/car/show_all_cars_user_home"
    )
    const filteredCategory = response.data ? response.data[category] || [] : []

    // Update filteredData directly based on the category
    setFilteredData((prevData) => ({
      ...prevData,
      [category]: filteredCategory,
    }))
  }

  const toggleFilter = () => {
    setFilterVisible(!isFilterVisible)
  }
  return (
    <div className={clsx("product-area hm9-section-padding", spaceBottomClass)}>
      <div className="container-fluid">
        <Tab.Container defaultActiveKey="newArrival">
          <h2
            className="title-center"
            style={{ textAlign: "center", padding: "1%" }}
          >
            Ready to hit the road in your dream car ?
          </h2>
          <Nav variant="pills" className="product-tab-list pb-55 text-center">
            <Nav.Item>
              <Nav.Link
                eventKey="newArrival"
                onClick={() => handleNavClick("new")}
              >
                <h4>New Arrivals</h4>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="bestSeller"
                onClick={() => handleNavClick("special")}
              >
                <h4>Special Edition</h4>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="saleItems"
                onClick={() => handleNavClick("used")}
              >
                <h4>Used Cars</h4>
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="newArrival">
              <p
                className="text-center"
                style={{
                  fontSize: "16px",
                  color: "#4b4b4b",
                  lineHeight: "30px",
                }}
              >
                the latest additions to our new car inventory, featuring the
                hottest models and cutting-edge technology
              </p>
              <div
                className="custom-row-4"
                style={{ justifyContent: "center" }}
              >
                <ProductGridThree
                  category={filteredData ? filteredData["new"] : []}
                  
                  type="newArrival"
                  limit={10}
                  spaceBottomClass="mb-35"
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="bestSeller">
              <p
                className="text-center"
                style={{
                  fontSize: "16px",
                  color: "#4b4b4b",
                  lineHeight: "30px",
                }}
              >
                Uncover some of our most impressive pre-owned vehicles,
                meticulously inspected and guaranteed for quality
              </p>
              <div
                className="custom-row-4"
                style={{ justifyContent: "center" }}
              >
                <ProductGridThree
                  category={filteredData ? filteredData["special"] : []}
                  type="bestSeller"
                  limit={10}
                  spaceBottomClass="mb-35"
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="saleItems">
              <p className="text-center">
                <p
                  className="text-center"
                  style={{
                    fontSize: "16px",
                    color: "#4b4b4b",
                    lineHeight: "30px",
                  }}
                >
                  Uncover some of our most impressive pre-owned vehicles,
                  meticulously inspected and guaranteed for quality
                </p>
              </p>

              <div
                className="custom-row-4"
                style={{ justifyContent: "center" }}
              >
                <ProductGridThree
                  category={filteredData ? filteredData["used"] : []}
                  type="saleItems"
                  limit={10}
                  spaceBottomClass="mb-35"
                />
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  )
}

TabProductThree.propTypes = {
  category: PropTypes.string,
  spaceBottomClass: PropTypes.string,
}

export default TabProductThree
