import React, { useState ,useEffect} from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

const InspectionModal = ({ open=false, onClose, image, subInspection }) => {

console.log(subInspection)
  return (
    <Dialog open={open} onClose={onClose} >
      <DialogTitle>{subInspection.name}</DialogTitle>
      <DialogContent style={{padding:'0',position:'relative'}}>
        <img src={image} alt="Car Inspection" style={{ maxWidth: '100%' }} />
       
          <div
         
            style={{
              position: 'absolute',
              top: `${subInspection.y }%`,
              left: `${subInspection.x }%`,
              transform: 'translate(-50%, -50%)',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: 'red'
            }}
          />
       {/* <p></p> */}
      </DialogContent>
    </Dialog>
  );
};

export default InspectionModal;
