import { Suspense, lazy, useEffect, useState } from "react"
import ScrollToTop from "./helpers/scroll-top"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Forget from "./pages/other/Forget"
import { Calendar } from "@fullcalendar/core"
import CalendarApp from "./pages/other/Calendar"
import CalendarAppService from "./pages/other/CalendarService"
import "@fortawesome/fontawesome-free/css/all.min.css"
import PersonalInfo from "./pages/profile/PersonalInfo"
import PersonalInfoAr from "./pages/profile/PersonalInfoAr"
import Dealer from "./pages/blog/Dealer"
import DealerDetailsStandard from "./pages/blog/DealerDetailsStandard"
import ContactAr from "./pages/other/ContactAr"
import LayoutOne from "./layouts/LayoutOne"
import BlogNoSidebarAr from "./pages/blog/BlogNoSidebarAr"
import BlogDetailsStandardAr from "./pages/blog/BlogDetailsStandardAr"
import HomeElectronicsAr from "./pages/home/HomeElectronicsAr"
import ProductSliderAr from "./pages/shop-product/ProductSliderAr"
import LayoutOneAr from "./layouts/LayoutOneAr"
import LoginRegisterAr from "./pages/other/LoginRegisterAr"
import WishlistAr from "./pages/other/WishlistAr"
import DealerAr from "./pages/blog/DealerAr"
import DealerDetailsStandardAr from "./pages/blog/DealerDetailsStandardAr"
import Terms from "./pages/other/Terms"
import Policy from "./pages/other/Policy"
import ShopGridFullWidthId from "./pages/shop/ShopGridFullWidthId"
import ShopGridFullId from "./pages/shop/ShopGridFullId"
import ShopGridFullIdModel from "./pages/shop/ShopGridFullIdModel"
import PrivacyPolicyAr from "./components/policy/indexAr";
import TermsAndConditionsAr from './components/terms/indexAr';

const HomeElectronics = lazy(() => import("./pages/home/HomeElectronics"))

// shop pages
const ShopGridFullWidth = lazy(() => import("./pages/shop/ShopGridFullWidth"))

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"))
const ProductSlider = lazy(() => import("./pages/shop-product/ProductSlider"))

// blog pages
const BlogNoSidebar = lazy(() => import("./pages/blog/BlogNoSidebar"))
const BlogDetailsStandard = lazy(() =>
  import("./pages/blog/BlogDetailsStandard")
)

// other pages
const About = lazy(() => import("./pages/other/About"))
const AboutAr = lazy(() => import("./pages/other/AboutAr"))
const Contact = lazy(() => import("./pages/other/Contact"))
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"))
const Wishlist = lazy(() => import("./pages/other/Wishlist"))

const NotFound = lazy(() => import("./pages/other/NotFound"))

const App = () => {
  useEffect(() => {
    localStorage.setItem("lang", "eng")
  }, [])
  const [ar, setAr] = useState(
    localStorage.getItem("lang") ? localStorage.getItem("lang") : "eng"
  )
  return (
    <Router>
      <ScrollToTop>
        <Suspense
          fallback={
            <div className="flone-preloader-wrapper">
              <div className="flone-preloader">
                <span></span>
                <span></span>
              </div>
            </div>
          }
        >
          {ar === "eng" && (
            <LayoutOne headerTop="visible" setAr={setAr}>
              <Routes>
                <Route
                  path={process.env.PUBLIC_URL + "/terms"}
                  element={<Terms />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/policy"}
                  element={<Policy />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/"}
                  element={<HomeElectronics />}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/profile"}
                  element={<PersonalInfo />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/calendar/:id"}
                  element={<CalendarApp />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/calendarService/:id"}
                  element={<CalendarAppService />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-full-width"}
                  element={<ShopGridFullWidth />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-full-width/:id"}
                  element={<ShopGridFullWidthId />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-full/:id"}
                  element={<ShopGridFullId />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-full-model/:id"}
                  element={<ShopGridFullIdModel />}
                />
                {/* Shop product pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/product/:id"}
                  element={<Product />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/product-slider/:id"}
                  element={<ProductSlider />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-no-sidebar"}
                  element={<BlogNoSidebar />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/dealer"}
                  element={<Dealer />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-details-standard/:id"}
                  element={<BlogDetailsStandard />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/dealer-details-standard/:id"}
                  element={<DealerDetailsStandard />}
                />
                {/* Other pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/about"}
                  element={<About />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/contact"}
                  element={<Contact />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/login-register"}
                  element={<LoginRegister />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/forget"}
                  element={<Forget />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/wishlist"}
                  element={<Wishlist />}
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </LayoutOne>
          )}
          {ar === "egp" && (
            <LayoutOneAr headerTop="visible" setAr={setAr}>
              <Routes>
                <Route
                  path={process.env.PUBLIC_URL + "/"}
                  element={<HomeElectronicsAr />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/profile"}
                  element={<PersonalInfoAr />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/calendar/:id"}
                  element={<CalendarApp />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/calendarService/:id"}
                  element={<CalendarAppService />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-full-width"}
                  element={<ShopGridFullWidth />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-full-width/:id"}
                  element={<ShopGridFullWidthId />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-full/:id"}
                  element={<ShopGridFullId />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-full-model/:id"}
                  element={<ShopGridFullIdModel />}
                />

                {/* Shop product pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/product/:id"}
                  element={<Product />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/product-slider/:id"}
                  element={<ProductSliderAr />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-no-sidebar"}
                  element={<BlogNoSidebarAr />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/dealer"}
                  element={<DealerAr />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-details-standard/:id"}
                  element={<BlogDetailsStandardAr />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/dealer-details-standard/:id"}
                  element={<DealerDetailsStandardAr />}
                />
                {/* Other pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/about"}
                  element={<AboutAr />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/contact"}
                  element={<ContactAr />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/policyAr"}
                  element={<PrivacyPolicyAr />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/termsAr"}
                  element={<TermsAndConditionsAr />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/login-register"}
                  element={<LoginRegisterAr />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/forget"}
                  element={<Forget />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/wishlist"}
                  element={<WishlistAr />}
                />
                <Route path="*" element={<NotFound />} />
              </Routes>{" "}
            </LayoutOneAr>
          )}
        </Suspense>
      </ScrollToTop>
    </Router>
  )
}

export default App
