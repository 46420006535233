import { Fragment, useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { getDiscountPrice } from "../../helpers/product"
import SEO from "../../components/seo"
import LayoutOne from "../../layouts/LayoutOne"
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb"
import { addToCart } from "../../store/slices/cart-slice"
import axios from "axios"
import { Col, Row, Form, Alert } from "react-bootstrap"
import CarAppointment from "./CarAppointment"
import ServiceAppointment from "./ServiceAppointment"
import swal from "sweetalert"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import dayjs from "dayjs"
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import Select from "react-select"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import FormControl from "@mui/material/FormControl"
import { MDBDataTable } from "mdbreact"
import carInspectionImage from "../../assets/carInspectionbg.png"
const HostedURL = "https://autohouse.onrender.com/"

// import BasicInfo from "./BasicInfo";
// import EducationalInfo from "./EducationalInfo";
// import PersonalInfo from "./PersonalInfo";
const CarDetails = ({ car, setCar, selectedCar }) => {
  const [error, setError] = useState("")
  const divRef = useRef(null)
  const [mousePos, setMousePos] = useState({})
  const [carInspections, setCarInspections] = useState([])
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      "&:hover": {
        borderColor: "#dc3545",
      },

      borderColor: state.isFocused ? "#dc3545" : "#adb5bd",
      boxShadow: "#dc3545",
    }),
    option: (provided, state) => ({
      ...provided,
      "&:active": {
        backgroundColor: "#dc3545",
      },
      backgroundColor: state.isFocused ? "#dc3545" : "#fff", // Background color on hover
      color: state.isFocused ? "#fff" : null, // Text color on hover
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isActive ? "#dc3545" : null, // Background color on active state
    }),
  }
  useEffect(() => {
    axios
      .get(HostedURL + `car/show_all_inspections`, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setCarInspections(
          res.data.result.map((one) => {
            return {
              label: one.ar_name,
              value: one.id,
              type: one.type,
              values: one.value,
            }
          })
        )
      })
      .catch((e) => {})
  }, [])
  const [status, setStatus] = useState([
    {
      label: "جيد",
      value: "good",
    },
    {
      label: "ثابت",
      value: "fixed",
    },
    {
      label: "خَطَأ",
      value: "faulty",
    },
  ])
  useEffect(() => {
    const image = document.getElementById("car-image")
    const handleMouseMove = (event) => {
      const rect = divRef.current.getBoundingClientRect()

      let temp = {
        x: ((event.clientX - rect.left) / rect.width) * 100,
        y: ((event.clientY - rect.top) / rect.height) * 100,
      }
      setMousePos(temp)
    }

    image.addEventListener("mouseup", handleMouseMove)

    return () => {
      image.removeEventListener("mouseup", handleMouseMove)
    }
  }, [])
  const columns = [
    {
      label: "Name",
      field: selectedCar ? "ar_name" : "name",
      sort: "asc",
    },
    {
      label: "Comments",
      field: "Comments",
    },
    {
      label: "Status",
      field: "Status",
    },
    {
      label: "Actions",
      field: "actions",
    },
  ]

  const [inspectionBoolean, setInpectionBoolean] = useState(false)
  const [subCarInspections, setSubCarInspections] = useState([])
  const [subComments, setComments] = useState([])
  const getSubCarInspection = (id) => {
    setSubCarInspections([])

    axios
      .get(HostedURL + `car/show_all_sub_inspections/${id}`, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res)
        let t = []
        res.data.result.map((one) => {
          t.push({
            value: one.id,
            label: one.ar_name,
            inspecId: id,
          })
        })

        setSubCarInspections([...t])
      })
      .catch((e) => {
        // console.log(e)
      })
  }

  const getComments = (id) => {
    setComments([])
    axios
      .get(HostedURL + `car/show_all_comments/${id}`, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res)
        let t = []
        res.data.result.map((one) => {
          t.push({
            value: one.id,
            label: one.ar_title,
            inspecId: id,
          })
        })
        console.log(t)
        setComments([...t])
      })
      .catch((e) => {
        // console.log(e)
      })
  }
  const inspectionInitial = {
    id: "",
    inspectionID: { id: "", name: "" },
    name: "",
    x: "",
    y: "",
    comments: [],
    status: "",
  }
  const [editSubInspection, setEditSubInspection] = useState(false)
  const [editInspectionIndex, setEditInspectionIndex] = useState(0)
  const editSelectedCarInspection = (subInsPection, index) => {
    console.log(subInsPection)
    car.carInspections.map((inspection, insIndex) => {
      if (inspection.id == subInsPection.inspectionID.id) {
        let temp = car.carInspections
        temp[insIndex].sub_car_inspections[index] = subInsPection
        setCar((prev) => ({
          ...prev,
          carInspections: [...temp],
        }))
        setEditSubInspection(false)
        setInpectionBoolean(false)
        setSelectedInspection(inspectionInitial)
        return
      }
    })
  }
  const deleteSelectedCarInspection = (subInsPection, index) => {
    console.log(car.carInspections)
    car.carInspections.map((inspection, insIndex) => {
      if (inspection.id == subInsPection.inspectionID.id) {
        let temp = car.carInspections
        temp[insIndex].sub_car_inspections.splice(index, 1)
        if (temp[insIndex].sub_car_inspections.length == 0) {
          temp.splice(insIndex, 1)
        }
        setCar((prev) => ({
          ...prev,
          carInspections: [...temp],
        }))
        setEditSubInspection(false)
        setInpectionBoolean(false)
        setSelectedInspection(inspectionInitial)
        return
      }
    })
  }
  const [selectedInspection, setSelectedInspection] =
    useState(inspectionInitial)
  return (
    <>
      <Row className="mb-3">
        {error && <Alert color="danger">{error}</Alert>}
        <Col md={7} xs={12}>
          <h6>انقر على الصورة لإضافة التفتيش </h6>
          {/* {carInspectionImage?()} */}
          <div
            style={{ position: "relative", borderRadius: "1px solid" }}
            id="car-image"
            ref={divRef}
          >
            <img
              src={carInspectionImage}
              onClick={() => {
                if (!inspectionBoolean) {
                  setSelectedInspection((prev) => ({
                    ...prev,
                    x: mousePos.x,
                    y: mousePos.y,
                  }))
                  setComments([])
                  setSubCarInspections([])
                  setInpectionBoolean(true)
                }
                console.log(mousePos)
              }}
              alt=""
              style={{ width: "100%" }}
            />
            {car.carInspections.map((carIns, ind) => {
              return carIns.sub_car_inspections.map((inspect, index) => {
                if (
                  editSubInspection &&
                  selectedInspection.id == inspect.id &&
                  selectedInspection.inspectionID.id == carIns.id
                ) {
                  console.log("awel if")
                  return (
                    <span
                      key={index}
                      style={{
                        position: "absolute",
                        left: `${inspect.x - 0.8}%`,
                        top: `${inspect.y - 3}%`,
                        fontSize: "16px",
                        color: "red",
                        display: "block",
                        cursor: "no-drop",
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "red",
                      }}
                    ></span>
                  )
                } else if (!editSubInspection) {
                  return (
                    <span
                      key={index}
                      style={{
                        position: "absolute",
                        left: `${inspect.x - 0.8}%`,
                        top: `${inspect.y - 3}%`,
                        fontSize: "16px",
                        color: "red",
                        display: "block",
                        cursor: "no-drop",
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "red",
                      }}
                    ></span>
                  )
                }
              })
            })}
          </div>
          {inspectionBoolean && (
            <>
              <button
                style={{
                  marginRight: "30px",
                  backgroundColor: "#dc3545",
                  borderColor: "#dc3545",
                  display: "flex",
                  justifyContent: "center",
                  width: "10%",
                  float: "right",
                  borderRadius: "2rem",
                }}
                className="btn btn-primary"
                onClick={() => {
                  // console.log(selectedInspection)
                  if (editSubInspection) {
                    editSelectedCarInspection(
                      selectedInspection,
                      editInspectionIndex
                    )
                  } else {
                    let b = false
                    let bSub = false
                    let inspectionIndex = 0
                    car.carInspections.map((carIns, index) => {
                      if (carIns.id === selectedInspection.inspectionID.id) {
                        b = true
                        inspectionIndex = index
                        carIns.sub_car_inspections.map((subIns) => {
                          if (subIns.id === selectedInspection.id) {
                            bSub = true
                            inspectionIndex = index
                            console.log("here")
                          }
                        })
                      }
                    })
                    if (b) {
                      console.log(inspectionIndex)
                      let t = car.carInspections
                      t[inspectionIndex].sub_car_inspections.push(
                        selectedInspection
                      )
                      setCar((prev) => ({
                        ...prev,
                        carInspections: [...t],
                      }))
                      setInpectionBoolean(false)
                    }
                    if (!b) {
                      let t = car.carInspections
                      t.push({
                        id: selectedInspection.inspectionID.id,
                        name: selectedInspection.inspectionID.name,
                        sub_car_inspections: [selectedInspection],
                      })
                      setCar((prev) => ({
                        ...prev,
                        carInspections: [...t],
                      }))
                    }
                    console.log(car.carInspections)
                    setInpectionBoolean(false)
                    setSelectedInspection(inspectionInitial)
                  }
                }}
              >
                {editSubInspection ? (
                  <i className="fas fa-pencil" />
                ) : (
                  <i className="fas fa-plus"></i>
                )}
              </button>
            </>
          )}
        </Col>
        <Col>
          {inspectionBoolean && (
            <>
              {editSubInspection && (
                <Link
                  to="#"
                  style={{
                    backgroundColor: "#dc3545",
                    borderColor: "#dc3545",
                    display: "flex",
                    justifyContent: "center",
                    width: "10%",
                    float: "right",
                  }}
                  className="btn btn-primary btn-sm"
                  onClick={(e) => {
                    e.preventDefault()
                    setEditSubInspection(false)
                    setSelectedInspection(inspectionInitial)
                    setInpectionBoolean(false)
                  }}
                >
                  <i className="fas fa-close"></i>
                </Link>
              )}
              <label>اختر عنوان فحص السيارة</label>
              <Select
                styles={customStyles}
                value={{
                  label: selectedInspection.inspectionID.name,
                  value: selectedInspection.inspectionID.id,
                }}
                onChange={(value) => {
                  getSubCarInspection(value.value)
                  getComments(value.value)
                  setSelectedInspection((prev) => ({
                    ...prev,
                    inspectionID: {
                      name: value.label,
                      id: value.value,
                    },
                    name: "",
                    id: "",
                  }))
                }}
                options={carInspections}
                className="form-control p-0"
              />
              <label>اختر فحص السيارة الفرعي</label>
              <Select
                styles={customStyles}
                value={{
                  label: selectedInspection.name,
                  value: selectedInspection.id,
                }}
                onChange={(value) => {
                  setSelectedInspection((prev) => ({
                    ...prev,
                    id: value.value,
                    name: value.label,
                  }))
                }}
                options={subCarInspections}
                className="form-control p-0"
              />
              <label>اختر التعليقات</label>
              <Select
                styles={customStyles}
                isMulti
                value={selectedInspection.comments.map((comment) => {
                  return {
                    label: comment.title,
                    value: comment.id,
                  }
                })}
                onChange={(values) => {
                  setSelectedInspection((prev) => ({
                    ...prev,
                    comments: values.map((comment) => ({
                      title: comment.label,
                      id: comment.value,
                    })),
                  }))
                }}
                options={subComments}
                className="form-control p-0"
              />
              <label>اختر حالة فحص السيارة الفرعي</label>
              <Select
                styles={customStyles}
                value={{
                  label: selectedInspection.status,
                  value: selectedInspection.status,
                }}
                onChange={(value) => {
                  setSelectedInspection((prev) => ({
                    ...prev,
                    status: value.label,
                  }))
                }}
                options={status}
                className="form-control p-0"
              />
            </>
          )}
        </Col>
      </Row>
      <Row>
        {car.carInspections.map((one, index) => {
          return (
            <>
              <hr />
              <h2>
                {index + 1} - {one.name} :
              </h2>
              <MDBDataTable
                key={index}
                hover
                paging={false}
                responsive
                displayEntries={false}
                searching={false}
                noBottomColumns
                data={{
                  columns: columns,
                  rows: one.sub_car_inspections.map((one, ind) => {
                    return {
                      ...one,
                      Comments: (
                        <Row>
                          {one.comments.map((one, index) => {
                            return <Col key={index}>{one.ar_title}</Col>
                          })}
                        </Row>
                      ),
                      Status: (
                        <>
                          {one.status === "good"
                            ? "جيد"
                            : one.status === "fixed"
                            ? "ثابت"
                            : "خَطَأ"}
                        </>
                      ),
                      actions: (
                        <Row>
                          {/* Edit */}
                          <Col xs={4} md={6}>
                            <Link
                              to="#"
                              className="btn btn-primary btn-sm"
                              onClick={(e) => {
                                e.preventDefault()

                                getSubCarInspection(one.inspectionID.id)
                                getComments(one.inspectionID.id)
                                setEditInspectionIndex(ind)
                                setInpectionBoolean(true)
                                setEditSubInspection(true)
                                setSelectedInspection(one)
                              }}
                            >
                              <i className="fas fa-edit"></i>
                            </Link>
                          </Col>
                          {/* Del */}
                          <Col xs={4} md={6}>
                            <button
                              className="btn btn-danger btn-sm mr-2"
                              type="button"
                              onClick={() => {
                                console.log(ind)
                                deleteSelectedCarInspection(one, ind)
                              }}
                            >
                              <i className="fas fa-trash"></i>
                            </button>
                          </Col>
                        </Row>
                      ),
                    }
                  }),
                }}
              />
            </>
          )
        })}
      </Row>
    </>
  )
}
export default CarDetails
